import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import styled, { css } from 'styled-components'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockClosedIcon from '@material-ui/icons/Lock'
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import IdleTimer from 'react-idle-timer'
import { signOutSalesPerson } from '../redux/actions'
import { isSalesUrl } from '../utils/functions'
import Api from '../redux/api'
import { isSalesPersonLogged, isSalesVersion, getProject } from '../redux/selectors'

const SALES_IDLE_LOGOUT_SECONDS = 30
const S = {
  SalesSignIn: styled.div`
    z-index: 1;
    padding: 10px;
    position: fixed;

    color: #1f3349;

    right: 0.75rem;
    bottom: 40px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0);

    background-color: rgba(255, 255, 255, 0.5);

    ${({ loggedIn }) =>
      loggedIn &&
      css`
        background-color: #e5ffde;
      `}
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        bottom: initial;
        top: 0.75rem;
      `}      

    &:hover {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  `,
  Modal: styled(Modal)`
    align-items: center;
    justify-content: center;
    font-family: Karla, sans-serif;
    background-color: white;
  `,
  Form: styled.form`
    flex-direction: column;
    display: flex;
    input {
      font-size: 0.75rem;
      font-weight: bold;
      margin-top: 25px;
    }
    #standard-password-input {
      flex: 1;
    }
    #component-error-text {
      color: red;
    }
  `,
  ButtonContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 300px;
  `,
  LockClosedIcon: styled(LockClosedIcon)`
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  `,
  LockOpenIcon: styled(LockOpenIcon)`
    color: #57a043;
    &:hover {
      color: #57a043;
    }
  `,
  Card: styled(Card)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;

    min-width: 700;
    max-height: 100%;
    overflow-y: auto;
    max-width: 100%;
  `,
  KeyboardContainer: styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100vw;
    div.inner {
      width: 30vw;
      border-radius: 30px;
      background-color: white;
    }
  `,
}

const SalesSignIn = () => {
  const isLogged = useSelector(isSalesPersonLogged)
  const isSalesVersionLogged = useSelector(isSalesVersion)
  const project = useSelector(getProject)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [error, setError] = useState()
  const [password, setPassword] = useState('')
  const [logoutOfSalesVersion, setLogoutOfSalesVersion] = useState(false)
  const keyboard = useRef()
  const pinInput = useRef()
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible)
  }

  useEffect(() => {
    console.log("**** isSalesUrl", project?.frontendSalesDomains)
    if (isSalesUrl(project)) {
      setIsVisible(true)
      setIsModalVisible(!isSalesVersionLogged)
    }
  }, [isSalesVersionLogged, isLogged, project?.frontendSalesDomains])

  const handleInputValueChange = (e) => {
    const { value, name } = e.target
    setPassword(value)
    keyboard.current.setInput(value)
  }

  const handleSubmit = async (e) => {
    e && e.preventDefault()
    if (!password) return setError('Please enter your PIN')

    const { data, error: err } = await dispatch(Api.postSalesSignIn({ pin: password }))
    if (err) {
      setError(err)
      setPassword('')
      keyboard.current.clearInput()
    } else {
      setPassword()
      setError()
      toggleModal()
      window.location.reload()
    }
  }
  const toggleLogoutOfSalesVersion = () => setLogoutOfSalesVersion(!logoutOfSalesVersion)
  const handleLogout = () => {
    dispatch(signOutSalesPerson(logoutOfSalesVersion))
    toggleModal()
  }
  const onKeyPress = (button) => {
    if (button === '{enter}') handleSubmit()
  }
  if (!isVisible) return null

  const onChange = (value) => {
    setPassword(value)
    if (pinInput.current) {
      const pos = keyboard.current.caretPosition || value.length
      if (pinInput.current.setSelectionRange) {
        try {
          pinInput.current.setSelectionRange(pos, pos)
        } catch (e) {}
      }
    }
  }

  return (
    <>
      <IdleTimer
        onIdle={() => {
          dispatch(signOutSalesPerson())
        }}
        timeout={1000 * SALES_IDLE_LOGOUT_SECONDS}
      />
      <S.SalesSignIn onClick={toggleModal} loggedIn={isLogged}>
        {isLogged ? <S.LockOpenIcon fontSize="small" /> : <S.LockClosedIcon fontSize="small" />}
      </S.SalesSignIn>
      <S.Modal onClose={toggleModal} open={isModalVisible}>
        <>
          <S.Card>
            {isLogged ? (
              <>
                <CardHeader title="Logout of Sales?" />
                <Divider />
                <CardContent>
                  <S.Form>
                    <S.ButtonContainer>
                      <Button variant="contained" color="primary" onClick={handleLogout}>
                        Logout
                      </Button>
                      <Button variant="contained" onClick={toggleModal}>
                        Cancel
                      </Button>
                    </S.ButtonContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={logoutOfSalesVersion}
                          onChange={toggleLogoutOfSalesVersion}
                          name="logoutOfSalesVersion"
                          color="primary"
                        />
                      }
                      label="Also logout of sales version"
                    />
                  </S.Form>
                </CardContent>
              </>
            ) : (
              <>
                <CardHeader title="Please enter your Sales PIN" />
                <Divider />
                <CardContent>
                  <S.Form onSubmit={handleSubmit}>
                    <TextField
                      id="standard-password-input"
                      label="PIN Number"
                      type="password"
                      autoFocus={true}
                      onChange={handleInputValueChange}
                      value={password}
                      error={!!error}
                      ref={pinInput}
                    />
                    <FormHelperText id="component-error-text">{error}</FormHelperText>

                    <S.ButtonContainer>
                      <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                      <Button variant="contained" onClick={toggleModal}>
                        Cancel
                      </Button>
                    </S.ButtonContainer>
                  </S.Form>
                </CardContent>
              </>
            )}
          </S.Card>
          {isModalVisible && !isLogged && (
            <S.KeyboardContainer>
              <div class="inner">
                <Keyboard
                  baseClass="pinKeyboard"
                  keyboardRef={(r) => (keyboard.current = r)}
                  layout={{
                    default: ['1 2 3', '4 5 6', '7 8 9', '* 0 #', '{bksp} {enter}'],
                  }}
                  preventMouseDownDefault={true}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  inputName="salesPin"
                />
              </div>
            </S.KeyboardContainer>
          )}
        </>
      </S.Modal>
    </>
  )
}

export default SalesSignIn
