import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getMap, getMapPadding, getProject } from '../redux/selectors'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import mapboxgl from 'mapbox-gl'

const S = {
  BackToInternalPois: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 9px 50px -28px #000;
    box-shadow: 0 12px 33px -10px #2b2b2b;
    font-size: 0.9rem;
    font-family: alverata, sans-serif;
    width: 11.2rem;
    border-radius: 30px;
    border: 1px solid #bce1f5;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: none;
    }
  `,
}

const BackToInternalPois = () => {
  const map = useSelector(getMap)
  const project = useSelector(getProject)
  const padding = useSelector(getMapPadding)
  const { lngLatBounds, zoomLevel: maxZoom } = useSelector(getProject) || {}

  const onClickHandler = () => {
    map.fitBounds(lngLatBounds, { padding, maxZoom })
  }

return <S.BackToInternalPois onClick={onClickHandler}>Back to {project.frontendName}</S.BackToInternalPois>
}

export default BackToInternalPois
