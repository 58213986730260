import { createSelector } from 'reselect'
import { getCoords, isMobile } from '../utils/functions'

const formatStageName = stage => (stage?.name ? `${stage?.number} (${stage?.name})` : `${stage?.number}`)

export const getMap = (state) => state.map.map
export const getDirections = (state) => state.directions
export const getMapPadding = (state) => {
  const { top, bottom, map } = state.mapPaddingRefs || {}
  if (!isSalesVersion(state) && !isMobile()) return {}
  const padding = {}
  if (bottom?.current) {
    const rect = bottom.current.getBoundingClientRect()
    if (rect) padding.bottom = isMobile() ? Math.max(window.innerHeight - rect.top, 0) : padding.bottom = Math.max(rect.height - 60, 0)
  }
  return padding
}
export const getProject = (state) => state.project || {}
export const getFavouriteLots = (state) => state.favourites.lots || []
export const getFavouritePackages = (state) => state.favourites.packages || []
export const getCachedPackages = (state) => state.cachedPackages
export const getMapPopup = (state) => state.mapPopup
export const getToast = (state) => state.toast
export const emailError = (state) => state.emailError
export const getBuilders = (state) => state.builders.data
export const getBuildersSelectData = (state) => {
  const unsoldHouses = getUnsoldHouses(state)
  return state.builders.data
    .filter(({ id }) => unsoldHouses?.find((house) => house.houseType?.builder?.id === id))
    .map((builder) => ({
      label: builder.name,
      value: builder.id,
    }))
}
export const getPdfUrl = (state) => state.pdfUrl
export const isSalesVersion = (state) => !!state?.auth?.token
export const getSalesVersionToken = (state) => state?.auth?.token
export const isSalesPersonLogged = (state) => !!state?.auth?.isSalesPersonLogged
export const getSelectedPoi = (state) => state.points.selectedPoi
export const getStages = (stage) => (stage.stages.data ? stage.stages.data : []) // .filter((stage) => stage.enabled)
export const getLotsFilters = (state) => state.lots.filters
export const getLotsSortBy = (state) => state.lots.sortBy
export const getLots = (state) =>
  state.lots.data
    .map((lot) => ({
      ...lot,
      sqm: lot.sqm ? lot.sqm : 0,
      price: lot.price ? lot.price : 0,
      dimensions: {
        ...lot?.dimensions,
        width: lot.dimensions?.width || 0,
      },
    }))
    .filter(
      (lot) =>
        (state.lots.filters.area.max === undefined || lot.sqm <= state.lots.filters.area.max) &&
        (state.lots.filters.area.min === undefined || lot.sqm >= state.lots.filters.area.min) &&
        (state.lots.filters.frontage.max === undefined ||
          lot.dimensions.width <= state.lots.filters.frontage.max) &&
        (state.lots.filters.frontage.min === undefined ||
          lot.dimensions.width >= state.lots.filters.frontage.min) &&
        (state.lots.filters.price.max === undefined || lot.price <= state.lots.filters.price.max) &&
        (state.lots.filters.price.min === undefined || lot.price >= state.lots.filters.price.min),
    )

export const getAllHouses = (state) => state.houses.data

export const getLotsGroupedByStages = createSelector(
  getStages,
  getLots,
  getLotsSortBy,
  getAllHouses,
  (stages, lots, sortBy, allHouses) => {
    if (!stages.length || !lots.length) {
      return []
    }
    const dwellingPackages = (allHouses || []).filter(p => p.dwellingContract)
    const mappedStages = {}
    stages.forEach((stage) => {
      mappedStages[stage.id] = {
        ...stage,
        lotsData: [],
      }
    })

    lots
      .filter(
        (lot) => lot.status !== 'Sold' && lot.status !== 'Settled' && lot.status !== 'Deposited' 
        && (lot.status !== 'Unreleased' || mappedStages[lot.stage?.id]?.releaseSatus === 'COMING_SOON')
        && !dwellingPackages.some(p => p.lot?.id === lot.id),
      )
      .sort((a, b) => {
        let comparator
        let direction = 'desc'

        switch (sortBy) {
          case 'price-asc':
            comparator = 'price'
            direction = 'asc'
            break
          case 'price-desc':
            comparator = 'price'
            break
          case 'area-asc':
            comparator = 'sqm'
            direction = 'asc'
            break
          case 'area-desc':
            comparator = 'sqm'
            break

          default:
            comparator = null
        }

        if (!comparator) return

        if (a[comparator] > b[comparator]) return direction === 'desc' ? -1 : 1
        if (b[comparator] > a[comparator]) return direction === 'desc' ? 1 : -1

        return 0
      })
      .forEach((lot) => {
        if (lot.stage && mappedStages[lot.stage.id].lots.includes(lot.id)) {
          mappedStages[lot.stage.id].lotsData.push(lot)
        }
      })

    const result = []

    Object.keys(mappedStages).forEach((key) => {
      if (mappedStages[key]?.lots?.length) result.push(mappedStages[key])
    })

    result.sort((a, b) => {
      if (isNaN(a.displayPosition) || isNaN(b.displayPosition)) {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        const aDisplay = formatStageName(a)
        const bDisplay = formatStageName(b)
        return collator.compare(aDisplay, bDisplay)
      }
      return a.displayPosition - b.displayPosition
    })
    return result
  },
)
export const getAvailableLotsCoordinates = createSelector(
  getLotsGroupedByStages,
  (lotsGroupedByStages) => {
    const availableLotsCoordinates = []
    const availableStages =
      lotsGroupedByStages && lotsGroupedByStages.filter((stage) => stage.enabled && stage.lotsData)
    if (availableStages) {
      const a = availableStages.forEach((stage) => {
        stage.lotsData.forEach((lot) => {
          const lotCoords = getCoords(lot.geo?.geometry?.coordinates)
          if (lotCoords) {
            lotCoords.forEach((polygon) =>
              polygon.forEach((coordinate) => availableLotsCoordinates.push(coordinate)),
            )
          }
        })
      })
    }
    return availableLotsCoordinates
  },
)

export const getAllLots = (state) => state.lots.data

export const getUnsoldHouses = (state) =>
  state.houses.data.filter(
    (house) =>
      house.lot?.status !== 'Unreleased' &&
      house.lot?.status !== 'Settled' &&
      house.lot?.status !== 'Sold',
  )

export const getUnsoldLots = (state) =>
  state.lots.data.filter(
    (lot) => lot.status !== 'Unreleased' && lot.status !== 'Settled' && lot.status !== 'Sold',
  )
export const getHousesSortBy = (state) => state.houses.sortBy
export const getHousesFilters = (state) => state.houses.filters
export const getHouses = (state) =>
  state.houses.data
    .filter(
      (house) =>
        house.lot?.status !== 'Unreleased' &&
        house.lot?.status !== 'Settled' &&
        house.lot?.status !== 'Sold' &&
        (state.houses.filters.area.max === undefined ||
          house.houseType.area <= state.houses.filters.area.max) &&
        (state.houses.filters.area.min === undefined ||
          house.houseType.area >= state.houses.filters.area.min) &&
        (state.houses.filters.price.max === undefined ||
          house.price <= state.houses.filters.price.max) &&
        (state.houses.filters.price.min === undefined ||
          house.price >= state.houses.filters.price.min),
    )
    .filter((house) => {
      let combinedFilter = true

      if (state.houses.filters.bedrooms?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.bedrooms.indexOf(house.houseType.bed) >= 0
      }

      if (state.houses.filters.bathrooms?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.bathrooms.indexOf(house.houseType.bath) >= 0
      }

      if (state.houses.filters.builders?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.builders.indexOf(house.houseType.builder.id) >= 0
      }

      return combinedFilter
    })
    .sort((a, b) => {
      let comparator
      let direction = 'desc'

      switch (state.houses.sortBy) {
        case 'bathrooms':
          comparator = 'bath'
          break
        case 'bedrooms':
          comparator = 'bed'
          break
        case 'cars':
          comparator = 'car'
          break
        case 'price-asc':
          comparator = 'price'
          direction = 'asc'
          break
        case 'price-desc':
          comparator = 'price'
          break
        case 'area-asc':
          comparator = 'area'
          direction = 'asc'
          break
        case 'area-desc':
          comparator = 'area'
          break
        case 'lot-area-asc':
          comparator = 'lot-area'
          direction = 'asc'
          break
        case 'lot-area-desc':
          comparator = 'lot-area'
          break
        default:
          comparator = null
      }

      if (!comparator) return
      if (comparator === 'lot-area') {
        if (a.lot?.sqm > b.lot?.sqm) return direction === 'desc' ? -1 : 1
        if (b.lot?.sqm > a.lot?.sqm) return direction === 'desc' ? 1 : -1
      } else if (['price-asc', 'price-desc'].includes(state.houses.sortBy)) {
        if (a[comparator] > b[comparator]) return direction === 'desc' ? -1 : 1
        if (b[comparator] > a[comparator]) return direction === 'desc' ? 1 : -1
      } else {
        if (a.houseType[comparator] > b.houseType[comparator]) return direction === 'desc' ? -1 : 1
        if (b.houseType[comparator] > a.houseType[comparator]) return direction === 'desc' ? 1 : -1
      }

      return 0
    })

export const getGeoPoints = (state) =>
  state.points.data
    .filter((point) => point.geo && point.geo.geometry && point.geo.geometry.type === 'Point')
    .map((point) => ({
      type: 'Feature',
      geometry: point.geo.geometry,
      boundries: point.boundries,
      properties: {
        category: point.category,
        name: point.name,
        description: point.description,
        type: point.type,
        facade: point.facade?.url,
        mapIcon: point.mapIcon?.url,
        documents: point.documents,
      },
    }))

export const getPointsCategory = (state) => state.pointsCategory.data

const convertPointToNearbyFeature = (point) => ({
  type: 'Feature',
  geometry: point.geo.geometry,
  boundries: point.boundries,
  id: point.id,
  properties: {
    id: point.id,
    category: point.category,
    address: point.address,
    name: point.name,
    description: point.description,
    type: point.type,
    facade: point.facade?.url,
    mapIcon: point.mapIcon?.url,
    documents: point.documents,
    pdf: point.pdf?.url,
  },
})

export const getAllNearbyPoints = (state) => {
  const categories = state.pointsCategory.data.map((category) => category.id)
  return state.points.data
    .filter(
      (point) =>
        point.geo &&
        (point.geo.geometry || point.geo.boundries) &&
        point.type === 'NEARBY' &&
        categories.indexOf(point.category) >= 0,
    )
    .map(convertPointToNearbyFeature)
}
export const getCategorizedNearbyPoints = (state) => {
  const result = {}
  state.points.data
    .filter(
      (point) =>
        point.geo && (point.geo.geometry || point.geo.boundries) && point.type === 'NEARBY',
    )
    .forEach((point) => {
      if (!result[point.category]) {
        result[point.category] = {
          items: [],
        }
      }

      result[point.category].items = [
        ...result[point.category].items,
        convertPointToNearbyFeature(point),
      ]
    })

  return state.pointsCategory.data
    .filter((category) => result[category.id])
    .map((category) => ({ ...category, items: result[category.id].items }))
}

export const getAllOnsitePoints = (state) => {
  const categories = state.pointsCategory.data.map((category) => category.id)
  return state.points.data
    .filter(
      (point) =>
        point.geo &&
        (point.geo.geometry || point.geo.boundries) &&
        point.type === 'INTERNAL' &&
        categories.indexOf(point.category) >= 0,
    )
    .map(convertPointToNearbyFeature)
}

export const getCategorizedOnsitePoints = (state) => {
  const result = {}
  state.points.data
    .filter(
      (point) =>
        point.geo && (point.geo.geometry || point.geo.boundries) && point.type === 'INTERNAL',
    )
    .forEach((point) => {
      if (!result[point.category]) {
        result[point.category] = {
          items: [],
        }
      }

      result[point.category].items = [
        ...result[point.category].items,
        {
          type: 'Feature',
          geometry: point.geo.geometry,
          boundries: point.boundries,
          id: point.id,
          properties: {
            id: point.id,
            category: point.category,
            name: point.name,
            description: point.description,
            type: point.type,
            facade: point.facade?.url,
            mapIcon: point.mapIcon?.url,
            documents: point.documents,
          },
        },
      ]
    })

  return state.pointsCategory.data
    .filter((category) => result[category.id])
    .map((category) => ({ ...category, items: result[category.id].items }))
}
