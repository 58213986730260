import React from 'react'
import TextField from '@material-ui/core/TextField'
import styled, { css } from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import mapboxgl from 'mapbox-gl'
import { displayRouteToPoint, hideRouteToPoint } from '../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { getMap, getProject, getMapPadding } from '../redux/selectors'
import { isMobile } from '../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../config'

function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const S = {
  Grid: styled(Grid)`
    span.aux {
      color: #a2a2a2;
      display: block;
    }
    span {
      font-size: 0.85rem !important;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 230px;
      padding: 0 !important;
      margin: 0 !important;
      span.aux {
        font-family: Karla, sans-serif !important;
        font-size: 0.75rem !important;
        color: #a2a2a2;
        display: block;
      }
      span {
        font-family: Karla, sans-serif !important;
        font-size: 0.75rem !important;
        padding: 0;
        margin: 0;
      }      
      Grid {
        margin: 0;
      }
    }
  `,
  Container: styled.div`
    font-family: Karla, sans-serif !important;
    font-weight: 400;
    width: 270px;
    height: 40px;
    background-color: white;
    border-radius: 4px;

    .MuiInputBase-root {
      font-family: Karla, sans-serif;
      font-weight: 400;
    }
    .MuiInputBase-input {
      padding: 10px !important;
      font-size: 0.85rem !important;
      color: #333;
    }
    .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .MuiInput-underline.Mui-focused:after {
      transform: initial;
    }
    .MuiInput-underline:after {
      transform: initial;
      border-bottom: none !important;
    }
    &.MuiInput-underline:before {
      border-bottom: none !important;
    }
    .input {
      font-size: 0.85rem !important;
      color: #333;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: 32px;
      width: 160px;
      .MuiInputBase-input {
        padding: 8px !important;
        width: 160px;
        font-size: 0.75rem !important;
      }
      .input {
        font-size: 0.75rem !important;
        color: #333;
      }      
    }
  `,
}

export default function SearchLocationInput() {
  const classes = useStyles()
  const [value, setValue] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const padding = useSelector(getMapPadding)
  const loaded = React.useRef(false)
  const map = useSelector(getMap)
  const project = useSelector(getProject)
  const activeTab = useSelector((state) => state.activeTab)
  const { zoomLevel: maxZoom } = project || {}
  const dispatch = useDispatch()

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY.replace(/"/g, '')}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  }
  const onDismiss = () => {
    const lngLatBounds = new mapboxgl.LngLatBounds()
    const coords = project?.boundries?.geometry?.coordinates
    if (coords) coords[0].forEach((b) => lngLatBounds.extend(b))
    map.fitBounds(lngLatBounds, { padding, maxZoom })
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          {
            ...request,
            componentRestrictions: {
              country: 'au',
            },
          },
          callback,
        )
      }, 200),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])
  if (isMobile() && activeTab !== 'nearby' && activeTab !== 'internalPois') return null
  return (
    <S.Container>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          if (!newValue) {
            dispatch(hideRouteToPoint())
            onDismiss()
            return
          }

          const geocoder = new window.google.maps.Geocoder()
          geocoder.geocode({ placeId: newValue.place_id }, async (results, status) => {
            if (status === 'OK') {
              if (!results[0]) {
                return
              }
              const ac = results[0].address_components

              const aType = (type) => ac.find((c) => c.types.indexOf(type) >= 0)?.short_name || ''

              const address =
                aType('street_number') +
                ' ' +
                aType('route') +
                ', ' +
                aType('administrative_area_level_2') +
                ' ' +
                aType('administrative_area_level_1')

              const destination = [
                results[0].geometry.location.lng(),
                results[0].geometry.location.lat(),
              ]
              dispatch(displayRouteToPoint(destination, onDismiss))
            }
          })

          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search" size="small" fullWidth />
        )}
        renderOption={(option) => {
          const matches = option.structured_formatting.main_text_matched_substrings
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          )

          return (
            <S.Grid container alignItems="center">
              {!isMobile() && (
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
              )}

              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <span className="aux">{option.structured_formatting.secondary_text}</span>
              </Grid>
            </S.Grid>
          )
        }}
      />
    </S.Container>
  )
}
