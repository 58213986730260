import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

import mapboxgl from 'mapbox-gl';

import {isMobile} from './MapBoxModelLayerFunctions';

import Stats from 'stats-js';

export default class MapBoxModelLayer {

    constructor(props) {

        this.id = '3d-model';
        this.type = 'custom';
        this.renderingMode = '3d';

        this.props = props || {};

        // this.mapLowResScene = new THREE.Scene();
        this.mapHighResScene = new THREE.Scene();

        this.visible = true;
        this.firstRender = true;

        setTimeout(() => {
            this.firstRender = false;
            this.map.triggerRepaint();
        }, 3000);

        if (props.addStats) {
            this.stats = new Stats();
            this.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
            document.body.appendChild( this.stats.dom );
        }
    }

    toggleVisiblity(visible) {

        this.visible = visible;

        if (this.map) {
            this.map.triggerRepaint();
        }
    }

    getMatrixFromMapboxOptions(mapboxOptions) {
        // parameters to ensure the model is georeferenced correctly on the map
        const modelOrigin = mapboxOptions.geodata;
        const modelAltitude = mapboxOptions.altitude;
        const modelRotate = mapboxOptions.rotation;
        const modelScale = mapboxOptions.scale;
        
        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );
        
        // transformation parameters to position, rotate and scale the 3D model onto the map
        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            // Since our 3D model is in real world meters, a scale transform needs to be
            // applied since the CustomLayerInterface expects units in MercatorCoordinates.
            //
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
        };

        const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
        );
        const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
        );
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
        );

        const _modelScale = [1,1,1];

        if (Array.isArray(modelScale)) {
            _modelScale[0] = modelScale[0];
            _modelScale[1] = modelScale[1];
            _modelScale[2] = modelScale[2];
        }
        else {
            _modelScale[0] = modelScale || 1.0;
            _modelScale[1] = modelScale || 1.0;
            _modelScale[2] = modelScale || 1.0;
        }

        const l = new THREE.Matrix4()
        .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
        )
        .scale(
            new THREE.Vector3(
                modelTransform.scale * _modelScale[0],
                -modelTransform.scale * _modelScale[1],
                modelTransform.scale * _modelScale[2]
            )
        )
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);

        l.premultiply(this.inverseCameraTransform);

        return l;
    }

    triggerRepaint(){
        this.map.triggerRepaint();
        /*if (this.triggerRepaint_timeout) {
            clearTimeout(this.triggerRepaint_timeout);
        }
        this.triggerRepaint_timeout = setTimeout( () => {
            this.triggerRepaint_timeout = 0;
            this.map.triggerRepaint();
        }, 50);*/
    }

    addInstancedModel(mapboxOptionsArray, url, scene, procTimeout){

        if (!this.scene) {
            throw "init component first";
        }

        const self = this;

        procTimeout = procTimeout || 0;

        if (!scene) {
            throw "::addInstancedModel scene is not defined"
        }

        const promise = getInstanedModel(mapboxOptionsArray.length, url);

        promise.then( gltf => {

            scene.add(gltf.scene);

            mapboxOptionsArray.forEach( (mapboxOptions, i) => {

                setTimeout( () => {

                    gltf.scene.traverse( (node) => {

                        if (node.isMesh && node.instanceMatrix){
                            
                            const matrix = self.getMatrixFromMapboxOptions(mapboxOptions);

                            node.setMatrixAt( i, matrix );

                            node.instanceMatrix.needsUpdate = true;
                        }
                    });

                    self.triggerRepaint();

                }, procTimeout )
            });
        });

        return promise;
    }

    addModel(mapboxOptions, url, scene, procTimeout, f_onAdd, bBypassFlatten){

        if (!this.scene) {
            throw "init component first";
        }

        const self = this;

        procTimeout = procTimeout || 0;

        if (!scene) {
            throw "::addModel scene is not defined"
        }

        const promise = getModel(url, bBypassFlatten);

        promise.then( gltf => {

            setTimeout( () => {

                const model = gltf.scene.clone();

                const materials = {};

                function getMaterialByName(name) {
                    for (let id in materials) {
                        if (materials[id].name === name) {
                            return materials[id]
                        }
                    }
                }

                model.traverse( node => {
                    if (node.material) {
                        materials[node.material.uuid] = node.material;
                    }
                });

                if (mapboxOptions.modelData) {

                    const modelData = mapboxOptions.modelData;

                    if (modelData.materialCorrection) {

                        for (let material_name in modelData.materialCorrection) {

                            const material = getMaterialByName(material_name);

                            if (!material) {
                                console.log("materialCorrection; '" + material_name + "' not found");
                                continue;
                            }

                            const corretion_params = modelData.materialCorrection[material_name];

                            if (corretion_params.color) {

                                if (!material._color) {
                                    material._color = material.color.clone();
                                }

                                material.color.copy(material._color).multiplyScalar(corretion_params.color);
                            }
                        }
                    }
                }

                model.updateMapboxOptions = (mapboxOptions) => {

                    model.mapboxOptions = mapboxOptions;

                    const matrix = self.getMatrixFromMapboxOptions(mapboxOptions);

                    const p = new THREE.Vector3();
                    const q = new THREE.Quaternion();
                    const s = new THREE.Vector3();
                    matrix.decompose ( p, q, s ); 

                    model.position.set(p.x, p.y, p.z);
                    model.quaternion.set(q.x, q.y, q.z, q.w);
                    model.scale.set(s.x, s.y, s.z );
                };

                model.updateMapboxOptions(mapboxOptions);

                scene.add(model);

                self.triggerRepaint();

                if (f_onAdd) {

                    f_onAdd(mapboxOptions, model)
                }

            }, procTimeout )
        });

        return promise;
    }

    onAdd = (map, gl) => {

        const { mapBoxModelLayerConfig } = this.props;

        this.camera = new THREE.Camera();
        this.scene = new THREE.Scene();

        // this.scene.add(this.mapLowResScene);
        this.scene.add(this.mapHighResScene);

        this.map = map;
        
        // use the Mapbox GL JS map canvas for three.js
        const renderer = this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: false // draw into render buffer - no antialias is possible in webgl1
        });

        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.autoClear = false;
        renderer.shadowMap.enabled = false;

        const centerLngLat = map.getCenter();
        this.center = mapboxgl.MercatorCoordinate.fromLngLat(centerLngLat, 0);
        const {x, y, z} = this.center;
        this.cameraTransform = new THREE.Matrix4()
            .makeTranslation(x, y, z)
            .scale(new THREE.Vector3(1, -1, 1));
        this.inverseCameraTransform = new THREE.Matrix4().getInverse(this.cameraTransform);

        // Ambient light
        if (mapBoxModelLayerConfig.ambientLight) {

            const color = new THREE.Color(mapBoxModelLayerConfig.ambientLight[0]);
            const intensity = mapBoxModelLayerConfig.ambientLight[1];

            const ambient = new THREE.AmbientLight( color.getHex(), intensity );
            this.scene.add( ambient );
        }
        else {
            const ambient = new THREE.AmbientLight( 0xffffff, 1.5 );
            this.scene.add( ambient );
        }

        if (this.props.onAdd) {
            this.props.onAdd(this);
        }
    }

    render = (gl, viewmatrix) => {

        if (this.stats) {
            this.stats.begin();
        }

        if (!this.visible ||
            !this.firstRender && this.map.transform.tileZoom <= this.props.hideWhenZoomLessThan
            ) {

            if (this.stats) {
                this.stats.end();
            }

            return;
        }

        this.camera.projectionMatrix = new THREE.Matrix4()
            .fromArray(viewmatrix)
            .multiply(this.cameraTransform);

        this.renderer.state.reset();

        this.mapHighResScene.visible = true;
        // this.mapLowResScene.visible = false;

        this.renderer.render(this.scene, this.camera);

        if (this.stats) {
            this.stats.end();
        }
    }

    onBeforeRender(self) {}
}

function applyMatrixWorldToGeometry(node){
    for (let i = 0; i < node.children.length; i++) {
        applyMatrixWorldToGeometry(node.children[i]);
    }
    if (node.geometry) {
        node.geometry.applyMatrix4(node.matrixWorld);
    }
    node.position.set(0, 0, 0);
    node.quaternion.set(0, 0, 0, 1);
    node.scale.set(1, 1, 1);
}

// Configure and create Draco decoder.
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( process.env.PUBLIC_URL + '/js/libs/draco/' );
if (isMobile()) {
    dracoLoader.setDecoderConfig( { type: 'js' } );
}

const getModel = (function(){
    const promises = {};
    return function(url, bBypassFlatten){
        const key = url;
        if (!promises[key]) {
            promises[key] = new Promise( (resolve, reject) => {
                const loader = new GLTFLoader();
                loader.setDRACOLoader( dracoLoader );
                loader.load(
                    url,
                    gltf => {

                        console.log(url);

                        gltf.scene.updateMatrixWorld(true);

                        const flattenSceneArray = [];

                        function traverse(node) {
                            if (node.isMesh){

                                // if (node.material) {
                                //     node.material.side = THREE.FrontSide;
                                // }

                                node.castShadow = false;
                                node.receiveShadow = false;

                                flattenSceneArray.push(node);
                            }
                            for (let i = 0; i < node.children.length; i++) {
                                const child = node.children[i];
                                traverse(child);
                            }
                        }

                        traverse(gltf.scene);

                        if (!bBypassFlatten) {

                            applyMatrixWorldToGeometry(gltf.scene);

                            gltf.scene = new THREE.Group();

                            for (let i = 0; i < flattenSceneArray.length; i++) {
                                const mesh = flattenSceneArray[i];
                                gltf.scene.add(mesh);
                            }
                        }

                        resolve(gltf);
                    }
                );
            });
        }
        return promises[key];
    }
})();

const getInstanedModel = (function(){
    const promises = {};
    return function(count, url){
        const key = count + "+" + url;
        if (!promises[key]) {
            promises[key] = new Promise( (resolve, reject) => {
                const loader = new GLTFLoader();
                loader.setDRACOLoader( dracoLoader );
                loader.load(
                    url,
                    function(gltf) {

                        console.log("instancing (" + count + "): " + url);

                        gltf.scene.updateMatrixWorld(true);

                        const flattenSceneArray = [];

                        function traverse(node) {
                            if (node.isMesh){

                                // if (node.material) {
                                //     node.material.side = THREE.FrontSide;
                                // }
                                node.castShadow = false;
                                node.receiveShadow = false;

                                const instanced_node = new THREE.InstancedMesh( 
                                    node.geometry, 
                                    node.material, 
                                    count 
                                );

                                instanced_node.name = node.name;

                                flattenSceneArray.push(instanced_node);
                            }

                            for (let i = 0; i < node.children.length; i++) {
                                const child = node.children[i];
                                traverse(child);
                            }
                        }

                        traverse(gltf.scene);

                        applyMatrixWorldToGeometry(gltf.scene);

                        gltf.scene = new THREE.Group();

                        for (let i = 0; i < flattenSceneArray.length; i++) {
                            const iMesh = flattenSceneArray[i];
                            gltf.scene.add(iMesh);
                        } 

                        resolve(gltf);
                    }
                );
            });
        }
        return promises[key];
    }
})();

