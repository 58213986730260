import React, { useRef, useState, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

const S = {
  Container: styled.div`
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.49);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    div.inner {
      width: 21vw;
      min-width: 840px;
      padding-bottom: 1vh;
      .hg-row {
        .hg-button {
          box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
          height: 40px;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 5px;
          background: #424242;
          border-bottom: 1px solid #b5b5b5;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          color: white;
          flex-grow: 1;
        }
      }
      .hg-theme-default {
        font-family: karla;
        padding: 5px;
        border-radius: 5px;
        background-color: transparent;
      }
    }
  `,
}

export const VirtualKeyboardContext = React.createContext({
  activeInput: { ref: undefined, onChange: ({ target: { value, name } }) => { } },
  setActiveInput: () => { },
  setVisible: () => { },
  visible: false,
})
export const VirtualKeyboardProvider = ({ children }) => {
  const [activeInput, setActiveInput] = useState()
  const [visible, setVisible] = useState()
  return (
    <VirtualKeyboardContext.Provider
      value={{
        activeInput,
        setActiveInput,
        setVisible,
        visible,
      }}
    >
      {children}
    </VirtualKeyboardContext.Provider>
  )
}

export default function VirtualKeyboard() {
  const { activeInput, visible } = useContext(VirtualKeyboardContext)
  const [layoutName, setLayoutName] = useState('default')
  const keyboard = useRef()

  if (!visible) return null

  const onChange = (value) => {
    if (activeInput?.ref) {
      value = value.replace(/\t+/g, '')
      activeInput.onChange({ target: { value, name: activeInput.ref.name } })
      const pos = keyboard.current.caretPosition || value.length
      if (activeInput.ref.setSelectionRange) {
        try {
          activeInput.ref.setSelectionRange(pos, pos)
        } catch (e) { }
      }
    }
  }

  const handleShift = () => {
    const newLayoutName = layoutName === 'default' ? 'shift' : 'default'
    setLayoutName(newLayoutName)
  }

  const onKeyPress = (button) => {
    if (button === '{shift}' || button === '{lock}') handleShift()
    if (button === '{tab}') {
      const elements = activeInput?.ref?.form?.elements
      if (elements) {
        let idx = -1
        for (let i = 0; i < elements.length; i++) {
          const el = elements[i]
          if (activeInput?.ref?.name === el?.name) idx = i
        }
        if (idx >= 0 && elements[idx + 1]) {
          elements[idx + 1].focus()
        }
      }
    }
  }

  return (
    <S.Container>
      <div class="inner">
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layoutName}
          preventMouseDownDefault={true}
          inputName={activeInput?.ref.name}
          onChange={onChange}
          onKeyPress={onKeyPress}
          display={{
            '{tab}': 'next',
            '{shift}': 'shift',
            '{lock}': 'caps lock',
            '{bksp}': 'backspace',
            '{enter}': 'return',
            '{space}': 'space',
          }}
        />
      </div>
    </S.Container>
  )
}
