import axios from 'axios'

import {
  fetchLots,
  fetchHouses,
  emailError,
  fetchFavourites,
  showToast,
  fetchStages,
  fetchPoints,
  fetchBuilders,
  fetchPointsCategory,
  setCachedPackages,
  fetchProjectData,
  signInSalesPerson,
} from './actions'
import { getSalesVersionToken, isSalesPersonLogged } from './selectors'

const baseUrl = (state) =>
  `${process.env.REACT_APP_API_BASE_URL}api/projects/${
    process.env.REACT_APP_OVERRIDE_PROJECT_ID || state.project?.id
  }/`

const Api = {
  fetchPostCodeSuburbs: async (postCode) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/fetch-suburbs`,
      {
        params: {
          postCode
        }
      }
    )
    return data;
  },
  fetchProjectDetails: () => async (dispatch, getState) => {
    try {

      let host = window.location.host;

      const response = await axios.get(
        process.env.REACT_APP_OVERRIDE_PROJECT_ID
          ? baseUrl(getState())
          : `${process.env.REACT_APP_API_BASE_URL}api/projects/?hostname=${host}`,
      )
      dispatch(fetchProjectData(response.data))
    } catch (e) {
      dispatch(
        showToast({
          body: 'Error fetching masterplan for domain name',
          type: 'error',
        }),
      )
    }
  },
  fetchLots: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'lots-geo')
    dispatch(fetchLots(response.data.items))
  },

  fetchLotPackages: (lotId) => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + `lot/${lotId}/packages/enabled`)
    dispatch(setCachedPackages(lotId, response.data.items))
  },

  fetchHouses: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'packages-enabled')
    dispatch(fetchHouses(response.data.items))
  },

  fetchStages: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'map-stages')
    dispatch(fetchStages(response.data.items))
  },

  fetchPoints: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'points')
    return dispatch(fetchPoints(response.data))
  },

  fetchBuilders: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'builder')
    return dispatch(fetchBuilders(response.data))
  },

  fetchPointsCategory: () => async (dispatch, getState) => {
    const response = await axios.get(baseUrl(getState()) + 'point-category')
    return dispatch(fetchPointsCategory(response.data))
  },

  postEnquireForm: (data) => (dispatch, getState) => {
    const {
      email,
      firstName,
      mobilePhone,
      postCode,
      lastName,
      howDidYouHearAboutUs,
      packageId,
      lotId,
      captchaToken,
      suburb,
      state
    } = data
    axios
      .post(baseUrl(getState()) + 'enquire', {
        email,
        firstName,
        lastName,
        mobilePhone,
        postCode,
        howDidYouHearAboutUs,
        lotId,
        packageId,
        captchaToken,
        suburb,
        state
      })
      .catch((error) => {
        console.log("**** error", error?.response?.data?.message)
        dispatch(emailError(error?.response?.data?.message))
      })
  },

  postRegistrationForm: (data) => (dispatch, getState) => {
    const {
      email,
      firstName,
      mobilePhone,
      postCode,
      lastName,
      howDidYouHearAboutUs,
      skipRedirect,
      captchaToken,
      suburb,
      state
    } = data
    axios
      .post(baseUrl(getState()) + 'register', {
        email,
        firstName,
        lastName,
        mobilePhone,
        postCode,
        howDidYouHearAboutUs,
        skipRedirect,
        captchaToken,
        suburb,
        state
      })
      .catch((error) => {
        console.log("**** error", error?.response?.data?.message)
        dispatch(emailError(error?.response?.data?.message))
      })
  },  

  postSalesSignIn: ({ pin }) => async (dispatch, getState) => {
    try {
      const { data } = await axios.post(baseUrl(getState()) + 'sales-signin', {
        pin,
      })
      dispatch(signInSalesPerson(data?.token))
      return { data }
    } catch (e) {
      return { error: e?.response?.status === 401 ? 'Wrong PIN' : 'Please try again later' }
    }
  },

  fetchFavourites: (hash) => async (dispatch, getState) => {
    const data = await axios.get(baseUrl(getState()) + 'favourites/' + hash)
    if (data.data) {
      dispatch(fetchFavourites(data.data))
      showToast({
        body: 'Succes, favourites loaded successfully',
        type: 'success',
      })
    } else {
      dispatch(
        showToast({
          body: 'Error, something went wrong, try again later',
          type: 'error',
        }),
      )
    }
  },
  postFavourites: (data) => (dispatch, getState) => axios.post(baseUrl(getState()) + 'favourites', data) ,
  broadcastAction: (data) => (dispatch, getState) => {
    const isSalesPerson = isSalesPersonLogged(getState())
    const token = getSalesVersionToken(getState())
    if (!token || isSalesPerson) return
    const { actionType, entityType, entityId, mediaId, pageNumber } = data
    axios
      .post(
        baseUrl(getState()) + 'broadcast',
        {
          actionType,
          entityType,
          entityId,
          mediaId,
          pageNumber
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .catch((error) =>
        dispatch(
          dispatch(
            showToast({
              body: 'Could not broadcast action to Display App.',
              type: 'error',
            }),
          ),
        ),
      )
  },
}

export default Api
