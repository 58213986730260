import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { StatusCircle } from './Status'
import { getMap } from '../redux/selectors'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import { getLotMinZoom } from '../utils/functions'

const S = {
  LotLegend: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1.5rem 1.5rem 1rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 9px 50px -28px #000;
    box-shadow: 0 10px 29px -16px rgba(0, 0, 0, 0.75)
    font-size: 16px;
    width: 11.2rem;
    padding: 1rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: none;
    }
  `,
  Status: styled.div`
    color: #878787;
    font-size: Karla, sans-serif;    
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      padding-left: 5px;
    }
  `,
}

const LotLegend = () => {
  const map = useSelector(getMap)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (map) {
      const track = () => {
        const zoom = map.getZoom()
        const threshold = getLotMinZoom() + 0.5
        if (zoom <= threshold && visible) setVisible(false)
        else if (zoom > threshold && !visible) setVisible(true)
      }

      map.on('zoomend', track)
      return function cleanup() {
        map.off('zoomend', track)
      }
    }
  }, [map, visible])
  return !visible ? null : (
    <S.LotLegend>
      <div>
        <S.Status>
          <StatusCircle status="Available" />
          <span>Available</span>
        </S.Status>

        <S.Status>
          <StatusCircle status="Deposited" />
          <span>Under Deposit</span>
        </S.Status>

        <S.Status>
          <StatusCircle status="Sold" />
          <span>Sold</span>
        </S.Status>
      </div>
    </S.LotLegend>
  )
}

export default LotLegend
