import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { getDirectionsToPoint } from '../../mapLayers/points'
import Api from '../../redux/api'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'
import { isSalesVersion, getProject } from '../../redux/selectors'
import { displayRouteToPoint, hideRouteToPoint } from '../../redux/actions'
import { rsz } from '../../utils/functions'

const S = {
  Poi: styled.div`
    box-shadow: none;
    position: relative;
    z-index: 2;
    margin-top: 3rem;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 20px 55px -26px rgba(0, 0, 0, 0.55);
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: -1.5rem;
      margin-top: 0;
      box-shadow: initial;
      position: initial;
    }       
  `,
  SalesPoi: styled.div`
    display: flex;
    background-color: hsla(0, 0%, 100%, 0.28);
    overflow-x: auto;
    height: 20rem;
    //min-height: 280px;
    padding-top: 0rem;
    flex-direction: row;
    background-color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.75)),
      to(rgba(0, 0, 0, 0.49))
    );
    padding-left: 35vw;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
    div.content {
      display: flex;
      height: 100%;
      padding-top: 1rem;
      margin-bottom: 0rem;
      flex-direction: column;
      justify-content: center;
    }
    div.poiDescriptionWrapper {
      //column-count: 1;
      column-gap: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      //max-height: 8vh;
    }
    h1 {
      margin-top: 0px;
      margin-bottom: 0px;
      color: #fff;
      font-size: 2.5rem;
      text-align: left;
      white-space: nowrap;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: 700;
      color: #fff;
      font-size: 1.25rem;
      text-align: left;
    }
    p.poiDescription {
      width: 52ch;
      margin-right: 1rem;
      margin-bottom: 0px;
      color: #fff;
      font-size: 1rem;
      line-height: 1.5;
    }
  `,
  Gallery: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    max-height: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-right: 0rem;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    img {
      width: auto;
      height: 100%;
      max-width: none;
      //margin-right: 2rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
      -o-object-fit: contain;
      object-fit: contain;
    }
  `,
  ImgWrapper: styled.div`
    position: relative;
    overflow: hidden;
    height: 11.5rem;
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: cover;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    h1 {
      margin: 1rem;
      margin-left: 3rem;
      font-size: 1.5rem;
      line-height: 1;
      position: absolute;
      left: 0%;
      top: auto;
      right: auto;
      bottom: 0%;
      z-index: 1;
      font-family: alverata, sans-serif;
      color: #fff;
      font-weight: 700;
      text-shadow: 1px 1px 13px rgba(0, 0, 0, 0.91);
    }
  `,
  BackgroundGradient: styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(50%, rgba(189, 152, 152, 0)),
      to(rgba(0, 0, 0, 0.23))
    );
    background-image: linear-gradient(180deg, rgba(189, 152, 152, 0) 50%, rgba(0, 0, 0, 0.23));
  `,
  ContentWrapper: styled.div`
    padding: 0rem 3rem 3rem;
    box-shadow: 0 37px 57px -15px rgba(0, 0, 0, 0.22);
    .poiDescription {
      margin-top: 1rem;
      margin-bottom: 0px;
      color: #8b8a8a;
      font-size: 0.825rem;
      line-height: 1.5;
      font-weight: 400;
      font-family: Karla, sans-serif;
      ${({ theme }) =>
      theme.isSalesMode &&
      css`
          overflow-y: scroll;
        `}
    }
    .address {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 0px;
      padding-left: 0rem;
      font-family: Karla, sans-serif;
      color: #8b8a8a;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: 400;
      @media screen and (min-width: 1280px) {
      }
    }

    p {
      margin-top: 1rem;
      font-family: Karla, sans-serif;
      color: #3d3b3b;
      font-size: 0.8rem;
      font-weight: 300;
    }
  `,
  Buttons: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .time {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 1rem;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      line-height: 20;
      img {
        width: 1rem;
        height: 1rem;
        @media screen and (min-width: 1280px) {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      .time-text {
        margin-top: 0px;
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #71cd4c;
        font-size: 0.85rem;
        line-height: 2;
        font-weight: 400;
        @media screen and (min-width: 1280px) {
          margin-top: 0rem;
          padding-bottom: 0rem;
          padding-left: 0.25rem;
          color: #71cd4c;
          font-size: 0.8rem;
          ${({ theme }) =>
      theme.isSalesMode &&
      css`
              font-size: 1rem;
            `}
        }
      }
    }
  `,
  DirectionsBtn: styled.div`
    padding: 0.1rem 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: #bbe1f5;
    border-radius: 20px;
    box-shadow: none;
    font-family: Karla, sans-serif;
    color: #353535;
    font-size: 0.85rem;
    line-height: 1.6;
    font-weight: 300;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 0rem;
        padding: 0rem 1rem 0.1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #bbe1f5;
        border-radius: 20px;
        box-shadow: none;
        font-family: Karla, sans-serif;
        color: #353535;
        font-size: 0.85rem;
        line-height: 1.6;
        font-weight: 400;
        text-decoration: none;

        color: #fff;
        font-size: 1rem;
      `}
  `,

  BrochureWrapper: styled.div`
    font-family: 'Sofia pro', Arial, sans-serif;
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 30px;
    border-style: solid;
    border-width: 1px;
    border-color: #e6e6e6;
    background-color: #fff;
    font-weight: 300;
    cursor: pointer;

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: 'Sofia pro', Arial, sans-serif;
      color: #404040;
      font-weight: 300;
      text-decoration: none;
      margin-top: 2px;

      img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        opacity: 0.62;
      }

      div {
        -webkit-font-smoothing: antialiased;
        font-family: 'Sofia pro', Arial, sans-serif;
        color: #4c4949;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  `,
}

const Poi = ({ item }) => {
  const map = useSelector((state) => state.map.map)
  const project = useSelector(getProject)
  const isSalesMode = useSelector(isSalesVersion)
  const [tripTime, setTripTime] = useState('--')
  const [geojson, setGeojson] = useState()
  const [lng, lat] = item.geometry.coordinates
  const dispatch = useDispatch()
  useEffect(() => {
    return function cleanUp() {
      dispatch(hideRouteToPoint())
      dispatch(
        Api.broadcastAction({
          actionType: 'resetDisplay',
        }),
      )
    }
  }, [])
  useEffect(() => {
    dispatch(hideRouteToPoint())
  }, [item?.properties?.id])
  useEffect(() => {
    async function fetchRouteData() {
      const origin = project.drivingDirectionsOrigin?.geometry?.coordinates;
      if (!origin)
        return;
      const data = await getDirectionsToPoint({ lng: origin[0], lat: origin[1] }, { lng, lat })
      if (data) {
        const { route, geojson, trip } = data;
        setTripTime(trip.duration)
        setGeojson(geojson)
      }
    }
    item.properties.type === 'NEARBY' && fetchRouteData()
  }, [lng, lat, item.properties.type, project.drivingDirectionsOrigin])
  const displayRoute = useCallback(() => {
    dispatch(displayRouteToPoint(item.geometry.coordinates))
  }, [map, geojson])
  useEffect(() => {
    dispatch(
      Api.broadcastAction({
        actionType: 'viewImage',
        entityType: 'point',
        entityId: item?.properties?.id || item?.id,
      }),
    )
  }, [item])

  const buttons = (
    <S.Buttons>
      <S.DirectionsBtn onClick={displayRoute}>Directions</S.DirectionsBtn>
      <div className="time">
        <img src="/images-new/time.svg" alt="" />
        <div className="time-text">{tripTime} mins</div>
      </div>
    </S.Buttons>
  )
  const columnCount = Math.ceil(item.properties.description.length / 320)

  // Mapbox converts arrays in Features props to JSON strings, so we need to to convert it back
  let docs = []
  try {
    docs = Array.isArray(item.properties.documents)
      ? item.properties.documents
      : JSON.parse(item.properties.documents)
  } catch (e) {
    // in case item.properties.documents doesn't contain valid JSON, an empty docs array will be used
  }
  
  const galleryData = [
    docs.find((d) => d.type === 'facade'),
    ...docs.filter((d) => d.type !== 'facade' && d.typeMetaName === 'image'),
  ].filter(Boolean)

  const broadcastImage = (mediaId) => {
    dispatch(
      Api.broadcastAction({
        actionType: 'viewImage',
        entityType: 'point',
        entityId: item?.properties?.id || item?.id,
        mediaId,
      }),
    )
  }
  
  return isSalesMode ? (
    <S.SalesPoi>
      <div className="content">
        <h1>{item.properties.name}</h1>
        {item.properties.type === 'NEARBY' && <h2>{item.properties.address}</h2>}
        <div className="poiDescriptionWrapper" style={{ columnCount }}>
          <p className="poiDescription">{item.properties.description}</p>
        </div>
        {item.properties.type === 'NEARBY' && buttons}
      </div>
      <S.Gallery>
        {galleryData.map((doc) => (
          <img
            onClick={() => broadcastImage(doc.id)}
            key={doc.id}
            src={rsz(doc.url, {
              height: 600,
              crop: 'fill',
              quality: 'auto',
            })}
            name={doc.displayName}
            alt={doc.displayName}
          />
        ))}
      </S.Gallery>
    </S.SalesPoi>
  ) : (
    <S.Poi>
      <S.ImgWrapper>
        {item.properties.facade && (
          <img
            src={rsz(item.properties.facade, {
              width: 420,
              crop: 'fill',
              quality: 'auto',
            })}
            name={item.properties.name}
            alt={item.properties.name}
          />
        )}
        <h1>{item.properties.name}</h1>
        <S.BackgroundGradient />
      </S.ImgWrapper>

      <S.ContentWrapper>
        {item.properties.type === 'NEARBY' && (
          <>
            <div className="address">{item.properties.address}</div>
            {buttons}
          </>
        )}
        <p className="poiDescription">{item.properties.description}</p>
      </S.ContentWrapper>
    </S.Poi>
  )
}

export default Poi
