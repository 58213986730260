import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import Modal from '@material-ui/core/Modal'
import styled, { css } from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import { getPdfUrl, isSalesVersion } from '../redux/selectors'
import { setPdfUrl } from '../redux/actions'
import Api from '../redux/api'

const SCALE_MIN = 1
const SCALE_MAX = 2

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const S = {
  Wrapper: styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        justify-content: flex-end;
      `}
  `,
  Container: styled.div`
    overflow-y: auto;
    height: calc(96vh - 100px);
    margin-bottom: 2vh;
    margin-top: 2vh;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        max-height: 1500px;
        display: flex;
        align-items: flex-end;
      `}
  `,
  ButtonContainer: styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    div.centerSection {
      flex-direction: column;
      display: flex;
      div.zoomButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        a {
          padding: 0.3rem 0.4rem;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  `,
  Button: styled.a`
    position: relative;
    display: flex;
    width: auto;
    height: auto;
    margin: 0.9rem;
    padding: 0.3rem 0.9rem 0.3rem 0.9rem;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #53addb;
    color: #fff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.1;
        cursor: auto;
      `}
    span {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    img {
      width: 0.7rem;
      height: auto;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    img.next {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
  `,
  PageCount: styled.p`
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  `,
}

export default function PDFViewer() {
  const dispatch = useDispatch()
  const pdfUrl = useSelector(getPdfUrl)
  const isSalesMode = useSelector(isSalesVersion)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    console.log('onDocumentLoadSuccess', numPages)
    setNumPages(numPages)
    setPageNumber(1)
  }
  function onDocumentLoadError(e) {
    console.log('onDocumentLoadSuccess error')
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
    if (isSalesMode) {
      dispatch(
        Api.broadcastAction({
          actionType: 'changeBrochurePage',
          pageNumber: pageNumber + offset
        }),
      );
    }
  }

  function previousPage() {
    if (pageNumber <= 1) return
    changePage(-1)
  }

  function nextPage() {
    if (pageNumber >= numPages) return
    changePage(1)
  }
  function close() {
    dispatch(setPdfUrl())
    if (isSalesMode) {
      dispatch(
        Api.broadcastAction({
          actionType: 'resetDisplay',
        }),
      )
    }
  }

  function zoomIn() {
    setScale(Math.min(SCALE_MAX, scale + 0.3))
  }
  function zoomOut() {
    setScale(Math.max(SCALE_MIN, scale - 0.3))
  }

  return (
    <Modal open={!!pdfUrl} onClose={() => setPdfUrl()}>
      <TransformWrapper
        options={{
          minScale: SCALE_MIN,
          maxScale: SCALE_MAX,
        }}
        onZoomChange={({ scale }) => {
          setScale(Math.min(SCALE_MAX, Math.max(scale, SCALE_MIN)))
        }}
        scale={scale}
        wheel={{
          wheelEnabled: false,
        }}
        doubleClick={{
          disabled: true,
        }}
        scalePadding={{
          disabled: true,
        }}
        defaultScale={1}
      >
        {({ resetTransform }) => (
          <S.Wrapper>
            <S.Container>
              <TransformComponent>
                <Document
                  file={pdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onDocumentLoadError}
                  error={<S.PageCount>Failed to load PDF file</S.PageCount>}
                  height={Math.min(1500, window.innerHeight * 96 - 100)}
                >
                  <Page pageNumber={pageNumber} scale={scale} renderMode="svg" />
                </Document>
              </TransformComponent>
            </S.Container>
            <>
              <S.ButtonContainer>
                <S.Button disabled={pageNumber <= 1} onClick={previousPage}>
                  <img src="/images/back-arrow.svg" alt="" />
                </S.Button>

                <div className="centerSection">
                  <div className="zoomButtons">
                    <S.Button
                      disabled={scale >= SCALE_MAX}
                      onClick={() => {
                        resetTransform()
                        zoomIn()
                      }}
                    >
                      <ZoomIn color="white" />
                    </S.Button>
                    <S.Button
                      disabled={scale <= SCALE_MIN}
                      onClick={() => {
                        resetTransform()
                        zoomOut()
                      }}
                    >
                      <ZoomOut color="white" />
                    </S.Button>
                  </div>
                  <S.Button onClick={close}>
                    <span>Close</span>
                  </S.Button>
                </div>

                <S.Button disabled={pageNumber >= numPages} onClick={nextPage}>
                  <img src="/images/back-arrow.svg" className="next" alt="" />
                </S.Button>
              </S.ButtonContainer>
              {numPages > 1 && (
                <S.PageCount>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </S.PageCount>
              )}
            </>
          </S.Wrapper>
        )}
      </TransformWrapper>
    </Modal>
  )
}
