import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined'
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined'
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import { formatPrice, isMobile } from '../utils/functions'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import HeightIcon from '@material-ui/icons/Height'
import MailIcon from '@material-ui/icons/Mail'
import Status from './Status'
import { getFavouritePackages, isSalesVersion } from '../redux/selectors'
import { setPdfUrl } from '../redux/actions'
import Api from '../redux/api'

import {
  showToast,
  emailErrorReset,
  addLotToFavourites,
  addPackageToFavourites,
} from '../redux/actions'

const orientationDescription = {
  N: 'North',
  NE: 'North East',
  E: 'East',
  SE: 'South East',
  S: 'South',
  SW: 'South West',
  W: 'West',
  NW: 'North West',
}

const S = {
  Card: styled.div`
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 23px 49px -9px rgba(0, 0, 0, 0.16);
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: 11vw;
        height: 25vh;
        margin-left: 3rem;
        flex: 0 0 auto;
      `}
  `,
  CardContent: styled.div`
    position: relative;
    padding: 0px;
    ${({ theme }) => theme.isSalesMode && css``}
  `,
  DetailsHeader: styled.h2`
    font-weight: 500;
    font-size: 20px;
  `,
  ViewBtn: styled.input`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 30px;
    color: #c7c7c7;
    border-radius: 30px;
    border: 1px solid #e9e9e9;
    background: white;
    margin: 10px 0px;
    cursor: pointer;
    font-size: 12px;
  `,
  IconsContainer: styled.div`
    display: flex;

    div {
      display: flex;
      align-items: center;
      svg {
        height: 17px;
      }
    }
  `,
  Typography: styled(Typography)`
    display: flex;
    justify-content: space-between;

    sup {
      vertical-align: super;
      font-size: xx-small;
    }
  `,
  PdfFile: styled.div`
    min-height: 24px;
    max-height: 24px;
    a {
      display: flex;
      align-items: center;
      svg {
        font-size: 1rem;
        margin-right: 3px;
      }
      &:hover {
        font-weight: 800;
      }
    }
  `,
  CardMediaWrapper: styled.div`
    position: relative;
    overflow: hidden;
    height: 13.5rem;
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
  `,
  CardMedia: styled(CardMedia)`
    &&& {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
    }

    ${({ height }) => css`
      height: ${height};
    `};
  `,
  CloseButton: styled(CloseRoundedIcon)`
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    border-radius: 30px;
    background-color: white;
    && {
      width: 18px;
      height: 18px;
      margin-top: 8px;
      margin-right: 4px;
    }
  `,
  FavouriteBtn: styled.div`
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 1rem;
    margin-right: 1.25rem;
    padding-top: 1px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    cursor: pointer;
    img {
      width: 1rem;
      height: 1rem;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
  `,
  BuilderLogo: styled.img`
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    width: 6rem;
    margin-top: 1rem;
    margin-left: 1.25rem;
  `,
  DetailsSection: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #000;
    margin-bottom: 10px;
    ${({ reduceMargin }) =>
      reduceMargin &&
      css`
        margin-bottom: 0px;
      `}
  `,
  Title: styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    color: #000;
  `,
  Price: styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    color: #000;
  `,
  HeightIcon: styled(HeightIcon)`
    transform: rotate(45deg);
    margin-bottom: -5px;
    && {
      font-size: 1.2rem;
    }
  `,
  Status: styled(Status)`
    width: 8px;
    height: 8px;
  `,
  LotButton: styled.div`
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 6rem;
    height: 100%;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding: 0.4rem 0.75rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: #c9dce6;
    border-radius: 100px;
    text-align: right;
  `,
  Enquire: styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    width: auto;
    height: auto;
    margin: 1rem 2rem 1.5rem;
    padding: 0.3rem 1.1rem 0.3rem 0.7rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #53addb;
    color: #fff;
    text-align: center;
    text-decoration: none;
    -o-object-fit: fill;
    object-fit: fill;

    div {
      font-family: Karla, sans-serif;
      font-weight: 700;
      text-align: center;
    }
  `,

  CardMainContent: styled.div`
    height: auto;
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;

    > div {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .orientationContainer {
      margin-top: -0.4rem
    }
    .size {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 1rem;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: Karla, sans-serif;
      .img-orientation-wrapper {
        margin-right: 0rem;
        padding: 0.25rem 0.4rem;        
        img {
          width: 1.2rem;
          height: auto;
          margin-right: 0rem;
          opacity: 1;            
        }        
      }
      .img-wrapper {
        margin-right: 0.5rem;
        padding: 0.25rem 0.4rem;
        border-radius: 100px;
        background-color: rgba(233, 241, 252, 0.6);
        &.house {
          margin-right: 0.5rem;
          padding: 0.2rem 0.3rem;
          border-radius: 100px;
          background-color: rgba(233, 241, 252, 0.6);
        }
        
        img {
          &.houseIcon {
            width: 0.9rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
          }
          &.areaIcon {
            width: 0.6rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
          }
          &.frontageIcon {
            width: 0.7rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &.showcase {
          padding: 1px 4px;
          margin-left: 1px;
          img {
            width: 0.8rem !important;
            height: 0.7rem !important;
            margin-right: 0rem;
          }
        }
      }

      div {
        font-family: Karla, sans-serif;
        font-size: 0.85rem;
        font-weight: 300;
        span {
          color: #a5a2a2;
        }
      }
    }
  `,
  CardMainContentLeft: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;

    h2 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
      margin-right: 0.7rem;
      font-family: alverata, sans-serif;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
    }

    h3 {
      margin-top: 0rem;
      margin-bottom: 1.5rem;
      margin-right: 0.7rem;
      font-family: Karla, sans-serif;
      text-align: left;
      color: #a5a2a2;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
    }
  `,
  CardMainContentAmenities: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 8.6rem;
    margin-top: 0.3rem;
    margin-bottom: 1.2rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .amenities-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 2.2rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      div {
        font-family: 'Sofia pro', Arial, sans-serif;
        color: #4c4949;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  `,
  BrochureWrapper: styled.div`
    font-family: 'Sofia pro', Arial, sans-serif;
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 30px;
    background-color: #fff;
    font-weight: 300;
    cursor: pointer;

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: 'Sofia pro', Arial, sans-serif;
      color: #404040;
      font-weight: 300;
      text-decoration: none;
      margin-top: 2px;

      img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        opacity: 0.62;
      }

      div {
        -webkit-font-smoothing: antialiased;
        font-family: 'Sofia pro', Arial, sans-serif;
        color: #4c4949;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  `,
  CardMainContentRight: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    text-align: right;

    h3.price {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      font-family: Karla, sans-serif;
      color: #a5a2a2;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      span {
        margin-top: 0rem;
        margin-bottom: 1.6rem;
        font-family: Karla, sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        font-weight: 700;
        text-align: right;
        display: block;
        color: #333;
      }
    }
  `,
  LotText: styled.div`
    opacity: 1;
    font-family: Karla, sans-serif;
    color: #4c4949;
    font-size: 14px;
    line-height: 0.85rem;
    font-weight: 400;
  `,
  LotTextLink: styled.a`
    opacity: 1;
    font-family: Karla, sans-serif;
    color: #4c4949;
    font-size: 14px;
    line-height: 0.85rem;
    font-weight: 400;
    cursor: pointer;
  `,
  SalesCard: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-left: 1.5vw;
    padding: 1rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 15px;
    background-color: rgba(16, 19, 43, 0.26);
    box-shadow: 0 18px 100px -19px rgba(0, 0, 0, 0.49);
  `,
  FacadeWrapper: styled.a`
    max-width: 100%;
    display: inline-block;

    position: relative;
    overflow: hidden;
    width: 12.5vw;
    min-width: 480px;
    height: 100%;
    img.builder-logo {
      position: absolute;
      left: 0%;
      top: 0%;
      right: auto;
      bottom: auto;
      width: 20%;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }
    img.package-img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-height: 16rem;
      font-size: 15px;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
    }
  `,
  SalesDetailsWrapper: styled.div`
    min-width: 400px;
    max-width: 500px;
    margin-left: 1.5rem;
    align-self: center;

    a.lot-status-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      line-height: 1.5;
      span {
        padding-bottom: 0.1rem;
        padding-left: 0.5rem;
        color: #fff;
        font-size: 1rem;
      }
    }

    .package-card-header {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-bottom: 1.5rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.65);
    }
    .package-heading {
      margin-top: 0px;
      margin-bottom: 0.25rem;
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    .builder-txt {
      color: #fff;
      font-size: 1rem;
    }
    .lot-price {
      margin-top: 0rem;
      /*margin-bottom: 1.6rem;*/
      font-family: Karla, sans-serif;
      font-size: 1.25rem;
      line-height: 1.2;
      font-weight: 400;
      text-align: right;
      color: white;
    }
    .orientation-wrapper {
      color: #fff;
      font-size: 1rem;
      margin-top: 0.3rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      img {
        width: 1rem;
        margin-right: 0.3rem;
        filter: invert(100%) sepia(0%) saturate(7482%) hue-rotate(125deg) brightness(100%) contrast(107%);
      }
    }
  `,
  SalesMidRow: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.65);
    &.noBottomBorder {
      border-bottom: none;
      padding-bottom: 0.2rem;
    }

    .amenities-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 8.6rem;
      margin-top: 0.3rem;
      margin-bottom: 1.2rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      width: auto;
      margin-top: 0rem;
      margin-bottom: 0rem;
      color: #fff;
    }


  `,
  AmenitiesItem: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.2rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: auto;
    margin-right: 1rem;
    padding-left: 0rem;
    img {
      height: 1.5rem;
      width: 2rem;
      color: #fff;
    }
    div {
      font-family: 'Sofia pro', Arial, sans-serif;
      font-weight: 400;
      padding-left: 0.5rem;
      color: #fff;
      font-size: 1rem;
    }
  `,
  PackageMeasurements: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 0rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px none #fff;
  `,

  PackageMeasurement: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    img.unit-img {
      width: 1.1rem;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      margin-top: -0.25rem;
    }
    img.unit-img.land {
      width: 0.9rem;
    }
    img.unit-img.depth {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    div.measurement-inner-wrap {
      display: block;
      margin-left: 0.2rem;
      padding-left: 0rem;
      h3.measurement-title {
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Karla, sans-serif;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        font-weight: 700;
        display: inline-block;
      }
      h3.measurement-unit {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        font-weight: 400;
        display: inline-block;
      }
    }
    div.measurement-inner-wrap.lot-extension {
      display: flex;
      align-self: center;
    }
  `,
  SalesButton: styled.a`
    &.transparentButton {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 1rem;
      padding: 4px 1.5rem;
      height: 1.7rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      border-radius: 100px;
      background-color: transparent;
      color: #fff;
      font-size: 1rem;
      text-decoration: none;
      div {
        padding-left: 0.5rem;
      }
      img {
        width: 0.6rem;
        padding-top: 1px;
        font-size: 0.6rem;
      }
    }

    &.enquire {
      display: inline-block;
      padding: 9px 15px;
      background-color: #3898ec;
      color: white;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer;
      border-radius: 0;

      width: auto;
      padding: 5px 2rem;
      border-radius: 100px;
      font-size: 1rem;
      text-align: center;
    }
    img {
      width: 1rem;
      margin-right: 0.5rem;
    }
  `,
  SalesButtons: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 0rem;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px none #fff;
  `,
}

export default function PackageCard({
  image,
  title,
  className,
  subtitle,
  handleView,
  bed,
  bath,
  car,
  area,
  frontage = 0,
  price,
  pdf,
  house,
  height = '150px',
  hideView = false,
  closeButton = false,
  onCloseButtonClick = () => {},
  land = 0,
  lotNumber,
  status,
  builderName,
  builderLogo,
  showViewLotLink,
  showLotInfo,
  mediaType,
  mediaId,
  onViewLotLinkClickHandler = () => {},
}) {
  const dispatch = useDispatch()
  const isSalesMode = useSelector(isSalesVersion)
  const favouritePackages = useSelector(getFavouritePackages)

  useEffect(() => {
    return function cleanUp() {
      dispatch(
        Api.broadcastAction({
          actionType: 'resetDisplay',
        }),
      )
    }
  }, [])

  const closeButtonClickHandler = () => {
    onCloseButtonClick()
  }
  const brochureClickHandler = (e) => {
    if (!isSalesMode) 
      return
    e.preventDefault()
    dispatch(setPdfUrl(e.target.href))
    dispatch(
      Api.broadcastAction({
        actionType: 'viewBrochure',
        entityType: mediaType,
        entityId: mediaId,
      }),
    )
  }
  const imageClickHandler = () => {
    dispatch(
      Api.broadcastAction({
        actionType: 'viewImage',
        entityType: mediaType,
        entityId: mediaId,
      }),
    )
  }
  const displayToast = (body, type) => {
    dispatch(
      showToast({
        body,
        type,
      }),
    )
  }
  const isInFavourite = (packageItem) =>
    favouritePackages.findIndex((p) => p.id === packageItem.id) >= 0

  const addToFavourite = (packageItem) => {
    !isInFavourite(packageItem)
      ? displayToast(
          title + ' by ' + builderName + (lotNumber ? (' on Lot ' + lotNumber) : '') + ' was added to your Favourites',
          'success',
        )
      : displayToast(
          title +
            ' by ' +
            builderName +(
              lotNumber ? (
                ' on Lot ' +
                lotNumber
              ) : ''
            ) +
            ' was removed from your Favourites',
          'success',
          'success',
        )

    dispatch(addPackageToFavourites(packageItem.id))
  }

  return isSalesMode ? (
    <S.SalesCard>
      <S.FacadeWrapper onClick={imageClickHandler}>
        {builderLogo && <img src={builderLogo} className="builder-logo" />}
        <img src={image || '/images-new/h-l-img.jpg'} className="package-img" />
      </S.FacadeWrapper>
      <S.SalesDetailsWrapper>
        <div className="package-card-header">
          <div>
            <div>
              <h1 className="package-heading">{title}</h1>
            </div>
            <div className="builder-txt">{builderName}</div>
          </div>
          <div>
            <div className="lot-price">{formatPrice(price)}</div>          
            {showLotInfo && (
              <a className="lot-status-wrap" onClick={() => onViewLotLinkClickHandler(house.lot)}>
                <Status status={status} />
                {lotNumber ? <span>Lot {lotNumber}</span> : <span>Showcase</span>}
              </a>
            )}
          </div>
          
        </div>
        <S.SalesMidRow className={showLotInfo ? '' : 'noBottomBorder'}>
          <div className="amenities-wrap">
            <S.AmenitiesItem>
              <img src="/images/bed_1.svg" />
              <div>{bed}</div>
            </S.AmenitiesItem>
            <S.AmenitiesItem>
              <img src="/images/bath_1.svg" />
              <div>{bath}</div>
            </S.AmenitiesItem>
            <S.AmenitiesItem>
              <img src="/images/car_1.svg" />
              <div>{car}</div>
            </S.AmenitiesItem>
          </div>
          {
            showLotInfo ? (
              house?.lot?.orientation && (
                <div className="orientation-wrapper">
                  <img src="/images-new/orientation.svg" />
                  { orientationDescription[house?.lot?.orientation]}
                </div>
              )
            ) : (
            <S.PackageMeasurement>              
              <img src="/images-new/house-hollow-white.png" className="unit-img" />
              <div className="measurement-inner-wrap lot-extension">
                <h3 className="measurement-unit">{area}sq</h3>
              </div>
            </S.PackageMeasurement>
          )}
        </S.SalesMidRow>
        {showLotInfo && (
          <S.PackageMeasurements>
            <S.PackageMeasurement>
              <img src="/images-new/house-hollow-white.png" className="unit-img" />
              <div className="measurement-inner-wrap homes-tab">
                <h3 className="measurement-title">House</h3>
                <h3 className="measurement-unit">{area}sq</h3>
              </div>
            </S.PackageMeasurement>
            {!!frontage && (
              <S.PackageMeasurement>
                <img src="/images-new/size-white.svg" className="unit-img depth" />
                <div className="measurement-inner-wrap homes-tab">
                  <h3 className="measurement-title">Frontage</h3>
                  <h3 className="measurement-unit homes-tab">{frontage}m</h3>
                </div>
              </S.PackageMeasurement>
            )}
            {!!land && (
              <S.PackageMeasurement>
                <img src="/images-new/area-icon-white.png" className="unit-img land" />
                <div className="measurement-inner-wrap homes-tab">
                  <h3 className="measurement-title">Land</h3>
                  <h3 className="measurement-unit">{land}m</h3>
                </div>
              </S.PackageMeasurement>
            )}
          </S.PackageMeasurements>
        )}

        <S.SalesButtons>
          <S.SalesButton className="enquire" onClick={handleView}>
            Enquire
          </S.SalesButton>

          {pdf && (
            <S.SalesButton
              className="transparentButton"
              href={pdf}
              target="_blank"
              onClick={brochureClickHandler}
            >
              Brochure
            </S.SalesButton>
          )}
          <S.SalesButton className="transparentButton" onClick={() => addToFavourite(house)}>
            <img
              src={
                isInFavourite(house)
                  ? '/images-new/favourites.svg'
                  : '/images-new/favourites-outline.svg'
              }
              width="79.5"
            />
            <div>Favourites</div>
          </S.SalesButton>
        </S.SalesButtons>
      </S.SalesDetailsWrapper>
    </S.SalesCard>
  ) : (
    <S.Card className={className}>
      <S.CardContent>
        {closeButton && <S.CloseButton onClick={closeButtonClickHandler}>Close</S.CloseButton>}
        <S.CardMediaWrapper onClick={imageClickHandler}>
          <S.CardMedia component="img" alt="" image={image || '/images-new/h-l-img.jpg'} />
        </S.CardMediaWrapper>

        {!hideView && (
          <S.FavouriteBtn>
            {isInFavourite(house) ? (
              <img onClick={() => addToFavourite(house)} src="/images-new/favourites.svg" alt="" />
            ) : (
              <img
                onClick={() => addToFavourite(house)}
                src="/images-new/favourites-outline.svg"
                alt=""
              />
            )}
          </S.FavouriteBtn>
        )}
        {builderLogo && <S.BuilderLogo src={builderLogo} />}
      </S.CardContent>
      <S.CardMainContent>
        <div>
        <S.CardMainContentLeft>
          <h2>{title}</h2>
          <h3>{builderName}</h3>
        </S.CardMainContentLeft>
        <S.CardMainContentRight>
          <h3 className="price">
            From <span>{formatPrice(price)}</span>
          </h3>

        </S.CardMainContentRight>
        </div>
        <div>
          <S.CardMainContentLeft>
          <div className="size">
            <div className="img-wrapper house">
              <img src="/images-new/house-hollow.png" className="houseIcon" />
            </div>
            <div>
              House Size <span>{area}sq</span>
            </div>
          </div>
          {lotNumber ? (
            <>
              <div className="size">
                <div className="img-wrapper">
                  <img src="/images-new/area-icon.png" className="areaIcon" />
                </div>
                <div>
                  Land Size{' '}
                  <span>
                    {land}m<sup>2</sup>
                  </span>
                </div>
              </div>
              <div className="size">
                <div className="img-wrapper">
                  <img src="/images-new/size.svg" className="frontageIcon" />
                </div>
                <div>
                  Frontage <span>{frontage}m</span>
                </div>
              </div>
            </>
          ) : (
            <div className="size">
              <div className="img-wrapper showcase">
                <img src="/images-new/info-italic.svg" />
              </div>
              <div>Showcase</div>
            </div>
          )}            
          </S.CardMainContentLeft>
          <S.CardMainContentRight>
          <S.CardMainContentAmenities>
            <div className="amenities-item">
              <img src="/images-new/bed.svg" alt="" />
              <div>{bed}</div>
            </div>
            <div className="amenities-item">
              <img src="/images-new/bath.svg" alt="" />
              <div>{bath}</div>
            </div>
            <div className="amenities-item">
              <img src="/images-new/car.svg" alt="" />
              <div>{car}</div>
            </div>
          </S.CardMainContentAmenities>
          {house?.lot?.orientation && (
            <div className="size orientationContainer">
              <div className="img-orientation-wrapper">
                <img src="/images-new/orientation.svg" />
              </div>
              <div>
                {orientationDescription[house?.lot?.orientation]}
              </div>
            </div> 
          )}
         
          {house.lot && (
            <S.LotButton status={status}>
              <S.Status status={status} />
              {showViewLotLink ? (
                <S.LotTextLink onClick={() => onViewLotLinkClickHandler(house.lot)}>
                  Lot {lotNumber}
                </S.LotTextLink>
              ) : (
                <S.LotText>Lot {lotNumber}</S.LotText>
              )}
            </S.LotButton>
          )}          
          {pdf && (
            <S.LotButton>
              <S.LotTextLink href={pdf} target="_blank" onClick={brochureClickHandler}>
                Brochure
              </S.LotTextLink>
            </S.LotButton>
          )}            
          </S.CardMainContentRight>          
        </div>
      </S.CardMainContent>
      <S.Enquire onClick={handleView}>
        <div>Enquire</div>
      </S.Enquire>
    </S.Card>
  )
}
