/* 
  NEARBY POIs - layers, source, setting visibility
*/

import { showPointPopup, setSelectedPoi } from '../redux/actions'
import axios from 'axios'
import { getDirectionsURI } from '../config'
import { scaleToMapIcon } from '../utils/functions'
const DEFAULT_ICON = '/images-new/mapicon-placeholder.png'

export const attachNearbyPointsToTheMap = async (map, points, project, dispatch) => {
  if (!points.length) return
  function loadImage(value) {
    return new Promise((resolve, reject) => {
      map.loadImage(value, (err, img) => {
        if (err)
          return reject(err)
        return resolve(img)
      })
    });
  }
  const promises = points.map(async (cat) => {
      const pointCategory = { ...cat }
      const name = `nearby-${pointCategory.id}`
      const customIcons = []
      pointCategory.items = pointCategory.items.map((poi) => {
        let imageName = `${name}-image`
        if (poi.properties.mapIcon) {
          imageName = `poi-${poi.id}-icon`
          customIcons.push({imageName, iconURL: poi.properties.mapIcon})
        }
        return {
          ...poi,
          properties: {
            ...poi.properties,
            imageName
          }
        }
      })
      try {
        const r = await Promise.all(customIcons.map(async ({imageName, iconURL}) => {
          const image = await loadImage(scaleToMapIcon(iconURL))
          map.addImage(imageName, image)
        }))
      } catch (e) {
        console.log("*** error loading custom map icons", e)
      }
      
      const image = await loadImage(
        scaleToMapIcon(pointCategory.imageSelected?.url || DEFAULT_ICON)
      );
      const clusterImage = await loadImage(
        scaleToMapIcon(pointCategory.imageCluster?.url || pointCategory.clusterImageUrl || DEFAULT_ICON, null)
      )
      map.addImage(`cluster-${name}`, clusterImage)
      map.addImage(`${name}-image`, image)
      addLayers(
        map,
        pointCategory.items,
        name,
        pointCategory.clusterTextColor || "#ffffff",
        project,
        dispatch,
      )
  })

  await Promise.all(promises)
}
export const showNearbyAll = (map, allNearbyPoints) => {
  const categories = [...new Set(allNearbyPoints.map((point) => point.properties.category))]
  showNearby(map, categories)
}

export const showNearby = (map, categories) => {
  if (map.getLayer('radius') && map.getLayer('radius-shadow')) {
    map.setLayoutProperty(`radius`, 'visibility', 'visible')
    map.setLayoutProperty(`radius-shadow`, 'visibility', 'visible')
    map.setLayoutProperty(`radius-labels`, 'visibility', 'visible')
  }
  categories.forEach((name) => {
    if (map.getLayer(`nearby-${name}-count`)) {
      map.setLayoutProperty(`nearby-${name}-count`, 'visibility', 'visible')
    }

    if (map.getLayer(`nearby-${name}-clusters`)) {
      map.setLayoutProperty(`nearby-${name}-clusters`, 'visibility', 'visible')
    }

    if (map.getLayer(`nearby-${name}-single`)) {
      map.setLayoutProperty(`nearby-${name}-single`, 'visibility', 'visible')
    }

    if (map.getLayer(`nearby-${name}-polygons-glow`)) {
      map.setLayoutProperty(`nearby-${name}-polygons-glow`, 'visibility', 'visible')
    }

    if (map.getLayer(`nearby-${name}-polygons-outline`)) {
      map.setLayoutProperty(`nearby-${name}-polygons-outline`, 'visibility', 'visible')
    }
  })
}

export const hideNearby = (map, categories) => {
  categories.forEach((name) => {
    if (map.getLayer(`nearby-${name}-count`)) {
      map.setLayoutProperty(`nearby-${name}-count`, 'visibility', 'none')
    }

    if (map.getLayer(`nearby-${name}-clusters`)) {
      map.setLayoutProperty(`nearby-${name}-clusters`, 'visibility', 'none')
    }

    if (map.getLayer(`nearby-${name}-single`)) {
      map.setLayoutProperty(`nearby-${name}-single`, 'visibility', 'none')
    }

    if (map.getLayer(`nearby-${name}-polygons-glow`)) {
      map.setLayoutProperty(`nearby-${name}-polygons-glow`, 'visibility', 'none')
    }

    if (map.getLayer(`nearby-${name}-polygons-outline`)) {
      map.setLayoutProperty(`nearby-${name}-polygons-outline`, 'visibility', 'none')
    }
  })
}

export const hideNearbyAll = (map, points) => {
  if (map.getLayer('radius') && map.getLayer('radius-shadow')) {
    map.setLayoutProperty(`radius`, 'visibility', 'none')
    map.setLayoutProperty(`radius-shadow`, 'visibility', 'none')
    map.setLayoutProperty(`radius-labels`, 'visibility', 'none')
  }

  return points.forEach(({ id }) => hideNearby(map, [id]))
}

export const getDirectionsToPoint = async (o, { lng, lat }) => {
  const point = `${lng},${lat}`
  const origin = `${o.lng},${o.lat}`
  const response = await axios.get(getDirectionsURI(origin, point))
  if (!response.data?.routes?.length)
    return
  const data = response.data.routes[0]  
  const route = data.geometry.coordinates
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  }
  const trip = {
    duration: Math.floor(data.duration / 60),
    distance: Math.floor(data.distance / 1000),
    steps: data.legs[0].steps.map((step) => step.maneuver.instruction),
  }
  return { route, geojson, trip }
}


function addLayers(map, points = [], name, clusterTextColor, project, dispatch) {
  const mapStyle = project?.mapboxStyleUrl || undefined;
  map.addSource(`${name}-source`, {
    type: 'geojson',
    generateId: true,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
    data: {
      type: 'FeatureCollection',
      features: points.filter((point) => point.geometry.type === 'Point'),
    },
  })

  const polygons = points
    .filter((point) => point.boundries)
    .map((point) => ({
      ...point.boundries,
    }))

  map.addLayer({
    id: `${name}-polygons-outline`,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: polygons,
      },
    },
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 100%)',
      'line-width': 2,
    },
  })

  map.addLayer({
    id: `${name}-polygons-glow`,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: polygons,
      },
    },
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 100%)',
      'line-blur': 10,
      'line-opacity': 0.38,
      'line-width': 10,
    },
  })

  map.addLayer({
    id: `${name}-single`,
    type: 'symbol',
    source: `${name}-source`,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'text-field': ['get', 'name'],
      'text-variable-anchor': ['bottom', 'top', 'left', 'right'],
      'text-radial-offset': 1.5,
      'text-justify': 'auto',
      visibility: 'none',
      'icon-image': ['get', 'imageName'],
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 12, 0.6, 18, 1],
      'text-anchor': 'bottom',
      ...(mapStyle ? {'text-font': ['Sofia Pro Regular', 'Roboto Black', 'Arial Unicode MS Bold']} : undefined),
      'text-letter-spacing': 0.04,
      'text-line-height': 1.1,
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        ['literal', [0, -1.1]],
        18,
        ['literal', [0, -1.75]],
      ],
      'text-pitch-alignment': 'viewport',
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 14, 18, 16],
    },
    paint: {
      'text-color': 'hsl(0, 0%, 26%)',
      'text-halo-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
  })

  map.addLayer({
    id: `${name}-count`,
    type: 'symbol',
    source: `${name}-source`,
    filter: ['has', 'point_count'],
    layout: {
      visibility: 'none',
      'icon-image': `cluster-${name}`,
      'text-field': '{point_count_abbreviated}',
      ...(mapStyle ? {'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold']} : undefined),
      'icon-size': ['interpolate', ['linear'], ['zoom'], 12, 0.6, 18, 1],
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 18, 42],
      'text-offset': [0, 0.1],
    },
    paint: {
      'text-color': clusterTextColor,
      'text-halo-color': 'hsla(0, 0%, 0%, 0)',
    },
  })

  map.on('click', `${name}-single`, async function (e) {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [`${name}-single`],
    })
    dispatch(setSelectedPoi(features[0]))
    dispatch(showPointPopup(features[0].properties))
  })

  map.on('mouseover', `${name}-single`, function (e) {
    map.getCanvas().style.cursor = 'pointer'
  })

  map.on('mouseleave', `${name}-single`, function (e) {
    map.getCanvas().style.cursor = ''
  })

  map.on('click', `${name}-count`, function (e) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: [`${name}-count`],
    })
    var clusterId = features[0].properties.cluster_id
    map.getSource(`${name}-source`).getClusterExpansionZoom(clusterId, function (err, zoom) {
      if (err) return

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom,
      })
    })
  })

  map.on('mouseenter', `${name}-count`, function () {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', `${name}-count`, function () {
    map.getCanvas().style.cursor = ''
  })
}
