import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MapIcon from '@material-ui/icons/Map'
import PropertyModalv2 from './PropertyModalv2'
import { toggleContentVisibility, showToast } from '../redux/actions'
import Api from '../redux/api'
import { MOBILE_WIDTH_THRESHOLD } from '../config'

const S = {
  Wrapper: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      position: absolute;
      bottom: 88px;
      z-index: 101;
      width: 100%;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
  `,
  ExpandCollapseButton: styled.a`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      z-index: 101;
      margin: 0 auto 1rem auto;
      flex-direction: row;
      align-items: center;
      background-color: #69b2db;
      box-shadow: 0 12px 33px -10px #2b2b2b;
      color: #fff;
      border-radius: 21px;
      height: initial;
      padding: 0.5rem 0.8rem;
      border: 2px solid #fff;
      text-align: center;
      font-weight: bold;
      pointer-events: all;
      &:hover {
        cursor: pointer;
      }
      svg {
        margin-right: 6px;
      }
    }
  `,
  EnquireButton: styled.a`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      position: absolute;
      bottom: 88px;
      z-index: 101;
      right: 0.5rem;
      margin-bottom: 1rem;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-self: flex-start;
      background-color: #69b2db;
      box-shadow: 0 12px 33px -10px #2b2b2b;
      color: #fff;
      border-radius: 21px;
      min-height: 44px;
      padding: 0.5rem 0.8rem;
      border: 2px solid #fff;
      text-align: center;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
      svg {
        margin-right: 6px;
      }
    }
  `,
  ContactForm: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      background: rgba(0,0,0,0.5);
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      

      div.wrapper {
        bottom: 88px;        
        height: calc(100vh - 88px);
        height: calc(var(--vh, 1vh) * 100 - 88px);
        z-index: 102;
        position: absolute;
        overflow-y: scroll;
        left: 0;
      }
  `,
}

const MapBottomNavigation = () => {
  const expandCollapseVisible = useSelector((state) => state.contentToggleButtonVisible)
  const contentPaneVisible = useSelector((state) => state.contentPaneVisible)
  const [contactFormVisible, setContactFormVisible] = useState(false)
  const activeTab = useSelector((state) => state.activeTab)
  const dispatch = useDispatch()
  useEffect(() => {
    setContactFormVisible(false)
  }, [activeTab])
  const displayToast = (body, type) => {
    dispatch(
      showToast({
        body,
        type,
      }),
    )
  }
  const onSave = async (formData) => {
    const data = {
      ...formData,
      skipRedirect: true,
    }
    try {
      await dispatch(Api.postRegistrationForm(data))
      displayToast('Success, your registration was submitted', 'success')
      setContactFormVisible(false)
    } catch (e) {
      displayToast('Error, something went wrong try again later', 'error')
    }
  }
  return expandCollapseVisible ? (
    <>
      {activeTab !== 'favourites' ? (
        <S.Wrapper>
          <S.ExpandCollapseButton
            href="#"
            onClick={(e) => {
              dispatch(toggleContentVisibility())
              e.preventDefault()
            }}
            expanded={contentPaneVisible}
          >
            {contentPaneVisible ? (
              <>
                <MapIcon />
                Map
              </>
            ) : (
              <>
                <FormatListBulletedIcon />
                List
              </>
            )}
          </S.ExpandCollapseButton>
        </S.Wrapper>
      ) : null}

      {(!contentPaneVisible || activeTab === 'favourites') && (
        <>
          <S.EnquireButton onClick={() => setContactFormVisible(true)}>Enquire</S.EnquireButton>
          {contactFormVisible && (
            <S.ContactForm>
              <div className="wrapper">
                <PropertyModalv2
                  handleSave={onSave}
                  handleClose={() => setContactFormVisible(false)}
                />
              </div>
            </S.ContactForm>
          )}
        </>
      )}
    </>
  ) : null
}

export default MapBottomNavigation
