import {
  FETCH_LOTS,
  FILTER_LOTS,
  FILTER_LOTS_CLEAR,
  FETCH_HOUSES,
  FILTER_HOUSES,
  FILTER_HOUSES_CLEAR,
  SHOW_TOAST,
  HIDE_TOAST,
  MAP_SET,
  EMAIL_ERROR,
  EMAIL_ERROR_RESET,
  SORT_HOUSES,
  SORT_LOTS,
  HIDE_LOT_POPUP,
  SHOW_LOT_POPUP,
  FETCH_FAVOURITES,
  ADD_LOT_TO_FAVOURITES,
  ADD_PACKAGE_TO_FAVOURITES,
  FETCH_STAGES,
  FETCH_POINTS,
  FETCH_BUILDERS,
  FETCH_POINTS_CATEGORY,
  SHOW_POINT_POPUP,
  SET_POINTS_INITIALIZED,
  SET_ONSITE_POINTS_INITIALIZED,
  SET_CACHED_PACKAGES,
  FETCH_PROJECT_DATA,
  SET_MAP_POPUP,
  SET_MAP_PADDING_REFS,
  SIGNIN_SALES,
  SIGNOUT_SALES,
  SIGNIN_SALES_FAILED,
  SET_SELECTED_POI,
  SET_SELECTED_STAGE,
  SET_PDF_URL,
  TOGGLE_CONTENT_VISIBILITY,
  SET_CONTENT_VISIBILITY,
  SET_AGREEMENT_MODAL_TYPE,
  DISPLAY_ROUTE_TO_POINT,
  HIDE_ROUTE_TO_POINT,
  CHANGE_TAB,
  SET_DIRECTIONS,
  SET_TOGGLE_BUTTON_VISIBILITY,
  SET_MAP_SCALE_VISIBILITY
} from './constants'
import { isSalesUrl } from '../utils/functions'
import { getDirections, getProject } from './selectors'

export const displayRouteToPoint = (point, onDismiss) => (dispatch, getState) => {
  const directions = getDirections(getState())
  const project = getProject(getState())
  const origin = project.drivingDirectionsOrigin?.geometry?.coordinates
  if (!origin)
    return
  dispatch({
    type: DISPLAY_ROUTE_TO_POINT,
    onDismiss
  })
  setTimeout(() => {
    directions.setOrigin(origin)
    directions.setDestination(point)
  }, 100)
}

export const setDirections = (directions) => (dispatch) => {
  dispatch({
    type: SET_DIRECTIONS,
    directions,
  })
}

export const hideRouteToPoint = () => (dispatch, getState) => {
  const directions = getDirections(getState())
  const onDismiss = getState().routeOnDismiss
  if (onDismiss) onDismiss();
  directions && directions.removeRoutes()
  dispatch({
    type: HIDE_ROUTE_TO_POINT,
  })
}

export const changeTab = (activeTab) => (dispatch) => {
  dispatch({
    type: CHANGE_TAB,
    activeTab,
  })
}

export const setAgreementModalType = (agreementModalType) => (dispatch) => {
  dispatch({
    type: SET_AGREEMENT_MODAL_TYPE,
    agreementModalType,
  })
}

export const setContentVisibility = (contentPaneVisible) => (dispatch) => {
  dispatch({
    type: SET_CONTENT_VISIBILITY,
    contentPaneVisible
  })
}

export const toggleContentVisibility = () => (dispatch) => {
  dispatch({
    type: TOGGLE_CONTENT_VISIBILITY,
  })
}

export const setToggleButtonVisibility = (contentToggleButtonVisible) => (dispatch) => {
  dispatch({
    type: SET_TOGGLE_BUTTON_VISIBILITY,
    contentToggleButtonVisible
  })
}

export const setMapScaleVisibility = (mapScaleVisible) => (dispatch) => {
  dispatch({
    type: SET_MAP_SCALE_VISIBILITY,
    mapScaleVisible
  })
}

export const fetchProjectData = (project) => ({
  type: FETCH_PROJECT_DATA,
  project,
})

export const fetchLots = (lots) => ({
  type: FETCH_LOTS,
  lots,
})

export const setSelectedPoi = (selectedPoi) => ({
  type: SET_SELECTED_POI,
  selectedPoi,
})

export const fetchStages = (stages) => ({
  type: FETCH_STAGES,
  stages,
})

export const fetchPoints = (points) => ({
  type: FETCH_POINTS,
  points,
})

export const fetchBuilders = (builders) => ({
  type: FETCH_BUILDERS,
  builders,
})

export const fetchPointsCategory = (pointsCategory) => ({
  type: FETCH_POINTS_CATEGORY,
  pointsCategory,
})

export const fetchHouses = (houses) => ({
  type: FETCH_HOUSES,
  houses,
})

export const fetchFavourites = (favourites) => ({
  type: FETCH_FAVOURITES,
  favourites,
})

export const addLotToFavourites = (lotId) => ({
  type: ADD_LOT_TO_FAVOURITES,
  lotId,
})

export const addPackageToFavourites = (packageId) => ({
  type: ADD_PACKAGE_TO_FAVOURITES,
  packageId,
})

export const filterLots = (filterData, filterType) => ({
  type: FILTER_LOTS,
  filterData,
  filterType,
})

export const clearFilterLots = () => ({
  type: FILTER_LOTS_CLEAR,
})

export const sortHouses = (sortBy) => ({
  type: SORT_HOUSES,
  sortBy,
})

export const sortLots = (sortBy) => ({
  type: SORT_LOTS,
  sortBy,
})

export const filterHouses = (filterData, filterType) => ({
  type: FILTER_HOUSES,
  filterData,
  filterType,
})

export const clearFilterHouses = () => ({
  type: FILTER_HOUSES_CLEAR,
})

export const showToast = (payload) => ({
  type: SHOW_TOAST,
  payload,
})

export const hideToast = () => ({
  type: HIDE_TOAST,
})

export const emailError = (message) => ({
  type: EMAIL_ERROR,
  message
})
export const emailErrorReset = () => ({
  type: EMAIL_ERROR_RESET,
})

export const hideLotPopup = () => ({
  type: HIDE_LOT_POPUP,
})

export const showLotPopup = (lot) => ({
  type: SHOW_LOT_POPUP,
  lot,
})

export const showPointPopup = (data) => ({
  type: SHOW_POINT_POPUP,
  data,
})

export const setPointsInitialized = () => ({
  type: SET_POINTS_INITIALIZED,
})

export const setOnsitePointsInitialized = () => ({
  type: SET_ONSITE_POINTS_INITIALIZED,
})

export const setCachedPackages = (id, data) => ({
  type: SET_CACHED_PACKAGES,
  id,
  data,
})

export const setPdfUrl = (pdfUrl) => ({
  type: SET_PDF_URL,
  pdfUrl,
})

export const restoreSalesPersonSignIn = (project) => {
  const salesAuth = JSON.parse(localStorage.getItem('salesAuth'))
  console.log("*** restoreSalesPersonSignIn", salesAuth)
  if (salesAuth && isSalesUrl(project)) {
    return {
      type: SIGNIN_SALES,
      token: salesAuth.token,
      isSalesPersonLogged: salesAuth.isSalesPersonLogged,
    }
  }
  return {
    type: SIGNIN_SALES_FAILED,
  }
}

export const signInSalesPerson = (token) => {
  localStorage.setItem(
    'salesAuth',
    JSON.stringify({
      token,
      isSalesPersonLogged: true,
    }),
  )
  return {
    type: SIGNIN_SALES,
    token,
    isSalesPersonLogged: true,
  }
}
export const signOutSalesPerson = (signOutSalesVersion) => {
  if (signOutSalesVersion) {
    localStorage.removeItem('salesAuth')
  } else {
    const data = JSON.parse(localStorage.getItem('salesAuth'))
    if (data?.token) {
      const { token } = data
      localStorage.setItem(
        'salesAuth',
        JSON.stringify({
          token,
          isSalesPersonLogged: false,
        }),
      )
    }
  }
  return {
    type: SIGNOUT_SALES,
    signOutSalesVersion,
  }
}

// MAP ACTIONS
export const mapSet = (map) => ({
  type: MAP_SET,
  map,
})

export const setMapPopup = (mapPopup) => ({
  type: SET_MAP_POPUP,
  mapPopup,
})

export const setMapPaddingRefs = (mapPaddingRefs) => ({
  type: SET_MAP_PADDING_REFS,
  mapPaddingRefs,
})

export const setSelectedStage = (selectedStage) => ({
  type: SET_SELECTED_STAGE,
  selectedStage,
})
