import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import axios from 'axios'
import TagManager from 'react-gtm-module'

import styled, { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import Navigation from './components/Navigation'
import Content from './components/Content'
import PackageCard from './components/PackageCard'
import Api from './redux/api'
import Map from './components/Map'
import Toast from './components/Toast'
import AgreementModal from './reusable/AgreementModal'
import { changeTab, restoreSalesPersonSignIn, setMapPaddingRefs } from './redux/actions'
import BackToInternalPois from './components/BackToInternalPois'
import ClearDirections from './components/ClearDirections'
import LotLegend from './components/LotLegend'
import SalesSignIn from './components/SalesSignIn'
import VirtualKeyboard, { VirtualKeyboardProvider } from './components/VirtualKeyboard'
import MapBottomNavigation from './components/MapBottomNavigation'
import PDFViewer from './components/PDFViewer'
import { getMap, isSalesVersion, getProject, getHouses } from './redux/selectors'
import { isMobile } from './utils/functions'
import WebGL from './map/WebGL'
import SearchLocationInput from './reusable/SearchLocationInput'
import { MOBILE_WIDTH_THRESHOLD } from './config'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Sofia pro';
    src: url('./fonts/Sofia-Pro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia pro';
    src: url('./fonts/Sofia-Pro-Semi-Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia pro';
    src: url('./fonts/Sofia-Pro-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia pro';
    src: url('./fonts/Sofia-Pro-Extra-Light.otf') format('opentype'),
      url('./Sofia-Pro-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia pro';
    src: url('./fonts/Sofia-Pro-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  ${({ theme }) =>
    theme.isSalesMode &&
    css`
      :root {
        font-size: 110% !important;
      }
    `}

  body {
    margin: 0;
    font-family: Karla, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: 100%;
    }
    a,
    div,
    * {
      -webkit-font-smoothing: antialiased;      
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

const S = {
  App: styled.div`
    display: flex;
    font-family: Karla, sans-serif;
    font-weight: 400;
    touch-action: none;
  `,
  PackageWrapper: styled.div`
    width: 28rem;
    padding: 1rem;
    margin-top: -3rem;
  `,
  Aside: styled.div`
    width: 30rem;
    background-color: #f3f6fb;
    box-shadow: 0 0 19px -7px #000;
    height: 100vh;
    z-index: 1;
    position: relative;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 100vw;
      height: unset;
      bottom: 0;
      position: absolute;
      z-index: 100;
      background-color: transparent;
    }

    ${({ theme }) =>
      theme.isEmbeddedMode &&
      css`
        @media screen and (max-width: 991px) {
          width: 100vw;
        }
      `}
  `,
  NavWrapper: styled.div`
    position: relative;
    z-index: 100;
    width: 100%;
    background-color: #fff;
  `,
  BottomSection: styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: visible;
    touch-action: none;
    pointer-events: none;
    > * {
      pointer-events: all;
    }

    div.slide-bottom {
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    div.slide-top {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    /* ----------------------------------------------
    * Generated by Animista on 2020-9-8 3:13:12
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    @-webkit-keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(var(--content-pane-height));
        transform: translateY(var(--content-pane-height));
      }
    }
    @keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(var(--content-pane-height));
        transform: translateY(var(--content-pane-height));
      }
    }
    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(var(--content-pane-height));
        transform: translateY(var(--content-pane-height));
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(var(--content-pane-height));
        transform: translateY(var(--content-pane-height));
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
  `,
  MapContainer: styled.div`
    width: calc(100% - 30rem);
    div.mapboxgl-ctrl-scale {
      background-color: transparent;
      opacity: 0.7;
      border-width: 1px;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 100vw;
      height: calc(80vh - 80px);
      height: calc(var(--vh, 1vh) * 100 - 80px);
      position: absolute;
      top: 0;
      left: 0;
      button.mapboxgl-ctrl-zoom-in {
        display: none;
      }
      button.mapboxgl-ctrl-zoom-out {
        display: none;
      }
      button.mapboxgl-ctrl-compass {
        display: none;
      }
    }
    ${({ theme, hideScale }) =>
      (theme.isEmbeddedMode &&
        css`
          @media screen and (max-width: 991px) {
            display: none;
            width: 0;
          }
        `) ||
      (theme.isSalesMode &&
        css`
          width: 100%;
          height: calc(100% - 80px);
          a.mapboxgl-ctrl-logo {
            position: absolute;
            bottom: calc(100vh - 80px - 60px) !important;
          }
          div.mapboxgl-ctrl-scale {
            color: white;
            border-color: white;
            ${hideScale ? 'display: none !important;' : ''}
          }

          div.mapboxgl-ctrl-bottom-right {
            bottom: 10px !important;
          }
        `)}
  `,
  LegendContainer: styled.div`
    position: fixed;
    right: 2rem;
    bottom: 0;
    z-index: 1;
    //width: calc(100% - 30rem);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    ${({ theme, anchorToBottom }) => {
      if (theme.isSalesMode) {
        if (anchorToBottom) {
          return css`
            position: absolute;
            bottom: calc(30px + 5.5rem);
            right: 49px;
          `
        } else {
          return css`
            position: absolute;
            bottom: 100%;
          `
        }
      }
    }}
  `,
  SearchContainer: styled.div`
    position: fixed;
    right: 49px;
    top: 10px;
    z-index: 1;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      top: 18px;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        top: initial;
        bottom: calc(5px + 5.5rem);
        display: flex;
        align-items: end;
        #auxNavigation {
          margin-right: 20px;
        }
      `}
  `,
  Separator: styled.div`
    width: 84%;
    margin: 0 8%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  `,
  Overlay: styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.1;
    z-index: 100;
  `,
  CardContainer: styled.div`    
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: block;
      position: absolute;
      height: calc(var(--vh, 1vh) * 100 - 80px);
      width: 100vw;
      bottom: 88px;
      left: 0;
      pointer-events: none;
      > * {
        pointer-events: all;
      }
  `,
}

function App() {
  const map = useSelector(getMap)
  const stages = useSelector((state) => state.stages.data.length)
  const points = useSelector((state) => state.points)
  const project = useSelector(getProject)
  const isSales = useSelector(isSalesVersion)
  const dispatch = useDispatch()
  const [dataFetched, setDataFetched] = useState(false)
  const [isEmbeddedMode, setIsEmbeddedMode] = useState(false)
  const [packageData, setPackageData] = useState(null)
  const mapPaddingBottom = useRef()
  const searchContainer = useRef()
  const mapPaddingContainer = useRef()
  const contentPaneVisible = useSelector((state) => state.contentPaneVisible)
  const mapScaleVisible = useSelector((state) => state.mapScaleVisible)

  const showFavourites = async () => {
    const pattern = '?hash='
    const locSearch = window.location.search
    if (locSearch.includes(pattern)) {
      const hash = locSearch.replace(pattern, '')
      await dispatch(Api.fetchFavourites(hash))
      await dispatch(changeTab('favourites'))
    }
  }
  useEffect(() => {
    const initApp = async () => {
      var head = document.head
      var link = document.createElement('link')

      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = 'https://static.mywhiterock.com.au/custom.css'

      head.appendChild(link)
      if (isMobile()) {
        function setVh() {
          let vh = window.innerHeight * 0.01
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }
        setVh()
        window.addEventListener('resize', setVh)
      }

      dispatch(setMapPaddingRefs({ map: mapPaddingContainer, bottom: mapPaddingBottom }))
      const embedded = window.location.pathname.indexOf('/embedded') === 0
      setIsEmbeddedMode(embedded)
      if (embedded) {
        const pathComponents = window.location.pathname.split('/')
        if (pathComponents[2]) {
          dispatch(changeTab(pathComponents[2]))
        }
      }
      await dispatch(Api.fetchProjectDetails())

      await dispatch(Api.fetchLots())
      await dispatch(Api.fetchStages())
      await dispatch(Api.fetchPoints())
      await dispatch(Api.fetchBuilders())
      await dispatch(Api.fetchPointsCategory())
      await dispatch(Api.fetchHouses())
      setDataFetched(true)
      showFavourites()
    }
    const loadPackage = async (projectId, pkg) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/packages/${pkg}`
          )
        setPackageData(response.data.package)
      } catch (e) {
        console.log('*** loadPackage', e)
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const packagePreview = urlParams.get('packagePreview');
    const projectId = urlParams.get('projectId');
    console.log('**** packagePreview', projectId, packagePreview)
    if (packagePreview) {
      loadPackage(projectId, packagePreview)
    } else
      initApp()
  }, [])
  useEffect(() => {
    if (project?.frontendName) document.title = project?.frontendName + ' Masterplan'
    dispatch(restoreSalesPersonSignIn(project))
  }, [project])

  useEffect(() => {
    if (!project?.gTag)
      return;    
    try {
      console.log('*** init gTag', project?.gTag)
      TagManager.initialize({ gtmId: project?.gTag })
      console.log('*** finish init gTag')
    } catch (e) {
      console.log('*** error setting gTag', e)
    }
  }, [project?.gTag])  

  useEffect(() => {
    const listener = (e) => {
      const { ctrlKey } = e
      if (ctrlKey) {
        e.preventDefault()
        return false
      }
    };
    mapPaddingBottom.current &&
      mapPaddingBottom.current.addEventListener(
        'wheel',
        listener,
        { passive: false }
      )
    isSales && searchContainer.current &&
      searchContainer.current.addEventListener(
        'wheel',
        listener,
        { passive: false }
      )      
  }, [mapPaddingBottom.current, searchContainer.current, isSales])
  const showMap = () => !!(stages && points?.data?.length >= 0)

  const theme = {
    isSalesMode: isSales,
    isEmbeddedMode,
  }
  const urlParams = new URLSearchParams(window.location.search);
  const packagePreview = urlParams.get('packagePreview');
  if (packagePreview) {
    const house = packageData
    return house ? (
      <S.PackageWrapper>
        <PackageCard
          key={house.id}
          className="package-card"
          house={house}
          title={house.houseType.name}
          subtitle={house.houseType.builder}
          handleView={() => {}}
          builderName={house.houseType.builder ? house.houseType.builder.name : '-'}
          builderLogo={house.houseType?.builder?.thumbnailUrl}
          bed={house.houseType.bed}
          bath={house.houseType.bath}
          car={house.houseType.car}
          area={house.houseType.area}
          land={(house.lot || {}).sqm}
          frontage={(house.lot || {}).dimensions?.width}
          price={house.price}
          image={house?.facade?.url}
          pdf={house?.pdf?.url}
          status={(house.lot || {}).status}
          lotNumber={(house.lot || {}).humanId}
          showViewLotLink
          showLotInfo={true}
          onViewLotLinkClickHandler={() => {}}
          mediaType="package"
          mediaId={house.id}
        />  
      </S.PackageWrapper>
    ) : null
  }

  return (
    <ThemeProvider theme={theme}>
      <VirtualKeyboardProvider>
        <GlobalStyle />
        <S.App>
          {!dataFetched && <S.Overlay></S.Overlay>}
          {!isSales && (
            <S.Aside className="sidebar">
              <Navigation />
              <Content />
              <S.CardContainer id="cardContainer" />
              <MapBottomNavigation />
            </S.Aside>
          )}
          <S.MapContainer ref={mapPaddingContainer} hideScale={!mapScaleVisible}>
            {!isSales && (
              <S.LegendContainer>
                <ClearDirections />
                <div>
                  <LotLegend />
                  <BackToInternalPois />
                </div>
              </S.LegendContainer>
            )}
            <S.SearchContainer ref={searchContainer}>
              <div id="auxNavigation" visible={contentPaneVisible} />
              <SearchLocationInput />
            </S.SearchContainer>
            <SalesSignIn />
            {showMap() && <Map desktopVersion={isEmbeddedMode || isMobile()} />}
            {map && <WebGL />}
          </S.MapContainer>
          {isSales && (
            <S.BottomSection ref={mapPaddingBottom}>
              <div className={contentPaneVisible ? 'slide-top' : 'slide-bottom'}>
                <Content />
                <VirtualKeyboard />
              </div>

              <S.NavWrapper>
                <Navigation />
              </S.NavWrapper>
              <ClearDirections />
              <S.LegendContainer anchorToBottom={!contentPaneVisible} className>
                <LotLegend />
              </S.LegendContainer>
            </S.BottomSection>
          )}
          <Toast />
          <PDFViewer />
          <AgreementModal />
        </S.App>
      </VirtualKeyboardProvider>
    </ThemeProvider>
  )
}

export default App
