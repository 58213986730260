import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import mapboxgl from 'mapbox-gl'

import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions'
import {
  mapSet,
  showLotPopup,
  changeTab,
  hideLotPopup,
  setSelectedStage,
  clearFilterLots,
  clearFilterHouses,
  setDirections,
  setOnsitePointsInitialized
} from '../redux/actions'
import {
  attachOnsitePointsToTheMap,
  showOnsiteAll
} from '../mapLayers/pointsInternal'
import { masterplanBoundries } from '../data/coordinates'
import PitchToggle from './PitchToggle'
import Api from '../redux/api'
import {
  getCenter,
  easeToCoordinate,
  getURLQueryParams,
  vh,
  convertLotsToGeoJson,
  convertStagesToGeoJson,
  convertStagesToGeoJsonOutline,
  convertStagesOutlinesToGeoJsonCenteredPoints,
  getLotNumbers,
  isMobile,
  getLotMinZoom
} from '../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../config'

import circle from '@turf/circle'
import { point } from '@turf/helpers'
import destination from '@turf/destination'

import '../App.css'

import {
  getProject,
  getLots,
  getLotsFilters,
  getHouses,
  getHousesFilters,
  getMapPopup,
  getCachedPackages,
  getMap,
  getMapPadding,
  isSalesVersion,
  getCategorizedOnsitePoints
} from '../redux/selectors'
// import { attachPointsToTheMap } from '../mapLayers/points'

import * as THREE from 'three'
import MapBoxModelLayer from './MapBoxModelLayer'
import { getRandomArbitrary, getRandomInt, getGeometryCenter } from './MapBoxModelLayerFunctions'
export const mapColors = {
  default: {
    fill: '#9ecc9e',
    type: '#ffffff',
    typeHalo: 'hsl(120, 26%, 45%)',
    outline: 'hsla(0, 0%, 0%, 0.34)',
  },
  highlighted: {
    fill: '#b3d5b3',
    type: '#ffffff',
    typeHalo: 'hsla(0, 0%, 100%, 0)',
    outline: 'hsl(0, 0%, 100%)',
  },
  selected: {
    fill: 'hsl(110, 18%, 60%)',
    fillExtrusion: '#b3d5b3',
  },
}

const _isMobile = isMobile();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN.replace(/"/g, '');

const S = {
  MapContainer: styled.div`
    width: calc(100% - 30rem);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 100vw;
      height: calc(100vh - 80px);
      height: calc(var(--vh, 1vh) * 100 - 80px);
      position: absolute;
      top: 0;
      left: 0;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: 100%;
        height: calc(100% - 80px);
      `}
  `,
}

const Map = ({ desktopVersion }) => {
  const dispatch = useDispatch()
  const [measurements, setMeasurements] = useState()
  const project = useSelector(getProject)
  const { lngLatBounds, zoomLevel: maxZoom } = project || {}
  const popup = useSelector(getMapPopup)
  const lots = useSelector((state) => state.lots.data)
  const filteredLots = useSelector(getLots)
  const filteredHouses = useSelector(getHouses)
  const allHousesLength = useSelector(state => state.houses.data)?.length
  const stages = useSelector((state) => state.stages.data)
  const lotsFilters = useSelector(getLotsFilters)
  const houseFilters = useSelector(getHousesFilters)  
  const map = useSelector(getMap)
  const padding = useSelector(getMapPadding)
  const isSalesMode = useSelector(isSalesVersion)
  const mapContainer = useRef(null)
  const lotPopup = useSelector((state) => state.lotPopup)
  const selectedStage = useSelector((state) => state.selectedStage)
  const cachedPackages = useSelector(getCachedPackages)
  const LOT_MINZOOM = getLotMinZoom()
  const categorizedPoints = useSelector(getCategorizedOnsitePoints)
  const pointsOnsiteInitialized = useSelector((state) => state.pointsOnsiteInitialized)

  const lotsById = useMemo(() => {
    const lotMap = {}
    lots.forEach((lot) => (lotMap[lot.id] = lot))
    return lotMap
  }, [lots])
  const lotsOnMap = useMemo(() => {
    if (!stages || !lotsById)
      return []
    const result = [];
    stages.forEach(s => {
      if (s.releaseSatus !== 'COMING_SOON') {
        s.lots.forEach(lotId => {
          const l = lotsById[lotId]
          if (l)
            result.push(l)
        })
      }
    })
    return result
  }, [stages, lotsById])
  const [stagesPopups, setStagesPopups] = useState({})
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  useEffect(() => {
    if (!isMapLoaded) return
    if (lotPopup) {
      map.setFilter('lot-selected-3d', ['==', ['get', 'humanId'], lotPopup.humanId])
      map.setFilter('lot-selected-3d-shadow', ['==', ['get', 'humanId'], lotPopup.humanId])
      map.setFilter('length-line', ['==', ['get', 'lot'], lotPopup.humanId])
      map.setFilter('length-number', ['==', ['get', 'lot'], lotPopup.humanId])
      map.setPaintProperty('lots-dark', 'fill-opacity', 0.3)

      const lotCenter = getCenter(lotPopup.geo.geometry.coordinates)
      let color
      const status = lotPopup.status
      if (status === 'Available') {
        color = 'available'
      } else if (status === 'Sold' || status === 'Settled' || status === 'Unreleased') {
        color = 'sold'
      } else {
        color = 'deposited'
      }
      setTimeout(() => {
        easeToCoordinate(map, lotCenter, isMobile() ? { bottom: 435 } : padding)
      }, 50)

      // ANIMATE START

      const geojson = {
        ...measurements,
      }
      if (!measurements) return
      map.getSource('measurements-length-source').setData(geojson)
      setTimeout(() => {
        for (let i = 0; i < 12; i++) {
          setTimeout(
            () =>
              (function (index) {
                animateLine(index)
              })(i),
            30 * i,
          )
        }
      }, 500)

      function animateLine(i) {
        const geojson = {
          ...measurements,
        }
        map.getSource('measurements-length-source').setData(geojson)

        // Request the next frame of the animation.
        // animation = requestAnimationFrame(animateLine)
      }
    } else {
      map.setFilter('lot-selected-3d', ['==', ['get', 'humanId'], null])
      map.setFilter('lot-selected-3d-shadow', ['==', ['get', 'humanId'], null])
      map.getLayer('length-line') && map.setFilter('length-line', ['==', ['get', 'lot'], null])
      map.getLayer('length-number') && map.setFilter('length-number', ['==', ['get', 'lot'], null])
      if (map.getLayer('lots-dark')) {
        map.setPaintProperty('lots-dark', 'fill-opacity', 0)
      }
      popup && popup.remove()
    }
  }, [lotPopup, isMapLoaded, map])

  useEffect(() => {
    async function init() {
      
      if (categorizedPoints?.length && !pointsOnsiteInitialized && isMapLoaded) {
        dispatch(setOnsitePointsInitialized())
        await attachOnsitePointsToTheMap(map, categorizedPoints, project, dispatch)   
        await showOnsiteAll(map, categorizedPoints, 14, 24)    
      }
    }
    init()
  }, [categorizedPoints, isMapLoaded, dispatch])  

  useEffect(() => {
    Object.keys(stagesPopups).forEach((stageId) => stagesPopups[stageId].remove())
    if (selectedStage && map && isMapLoaded) {
      dispatch(clearFilterLots())
      dispatch(clearFilterHouses())
      const filter = ['case', ['==', ['get', 'stageId'], selectedStage ? selectedStage.id : 0]]
      map.setPaintProperty('lots-filtered-out', 'fill-opacity', [...filter, 0, 1])
      map.setPaintProperty('lots-filtered-in', 'fill-opacity', [...filter, 1, 0])
      map.setPaintProperty('lots-lines-outline-filtered-in', 'line-opacity', [...filter, 0.4, 0])
      map.setPaintProperty('lots-lines-outline-filtered-in-highlight', 'line-opacity', [
        ...filter,
        1,
        0,
      ])

      map.setPaintProperty('lot-numbers', 'text-color', [
        ...filter,
        mapColors.highlighted.type,
        mapColors.default.type,
      ])
      map.setPaintProperty('lot-numbers', 'text-halo-color', [
        ...filter,
        mapColors.highlighted.typeHalo,
        mapColors.default.typeHalo,
      ])
      Object.keys(stagesPopups).forEach((stageId) => {
        if (stageId !== selectedStage.id) {
          stagesPopups[stageId].addTo(map)
          stagesPopups[stageId].getElement().onclick = () => {
            dispatch(hideLotPopup())
            const stage = stages.find((s) => s.id === stageId)
            dispatch(setSelectedStage(stage))
            if (!stage.lots) return
            const bounds = new mapboxgl.LngLatBounds()
            stage.lots.forEach((lotId) => {
              const lot = lotsById[lotId]
              lot.geo?.geometry?.coordinates[0] &&
                lot.geo.geometry.coordinates[0].forEach((coord) => bounds.extend(coord))
            })
            map.fitBounds(bounds, { maxZoom: 18, padding })
          }
        }
      })
    }
  }, [selectedStage])

  useEffect(() => {
    if (
      (filteredLots?.length === lots?.length &&
      filteredHouses?.length === allHousesLength) ||
      !isMapLoaded ||
      !map?.getLayer('lots-filtered-out')
    )
      return
    dispatch(setSelectedStage())
    const houseLots = (filteredHouses || []).map(house => (house.lot || {}).humanId)
    const filter = [
      'case',
      [
        'all',        
        isNaN(lotsFilters.area.min) || ['>=', ['get', 'sqm'], lotsFilters.area.min],
        isNaN(lotsFilters.area.max) || ['<=', ['get', 'sqm'], lotsFilters.area.max],
        isNaN(lotsFilters.frontage.min) || ['>=', ['get', 'width'], lotsFilters.frontage.min],
        isNaN(lotsFilters.frontage.max) || ['<=', ['get', 'width'], lotsFilters.frontage.max],
        isNaN(lotsFilters.price.min) || ['>=', ['get', 'price'], lotsFilters.price.min],
        isNaN(lotsFilters.price.max) || ['<=', ['get', 'price'], lotsFilters.price.max],
        ["in", ['get', 'humanId'], ['literal', houseLots]],
        ['!=', ['get', 'status'], 'Sold'],
        ['!=', ['get', 'status'], 'Settled'],
        ['!=', ['get', 'status'], 'Unreleased'],
      ],
    ]
    map.setPaintProperty('lots-filtered-out', 'fill-opacity', [...filter, 0, 1])
    map.setPaintProperty('lots-filtered-in', 'fill-opacity', [...filter, 1, 0])
    map.setPaintProperty('lots-lines-outline-filtered-in', 'line-opacity', [...filter, 0.4, 0])
    map.setPaintProperty('lots-lines-outline-filtered-in-highlight', 'line-opacity', [
      ...filter,
      1,
      0,
    ])
    map.setPaintProperty('lot-numbers', 'text-color', [
      ...filter,
      mapColors.highlighted.type,
      mapColors.default.type,
    ])
    map.setPaintProperty('lot-numbers', 'text-halo-color', [
      ...filter,
      mapColors.highlighted.typeHalo,
      mapColors.default.typeHalo,
    ])
  }, [map, lotsFilters, houseFilters, isMapLoaded])

  useEffect(() => {
    if (
      isMapLoaded &&
      filteredLots?.length === lots?.length &&
      filteredHouses?.length === allHousesLength &&
      !selectedStage &&
      map.getLayer('lots-filtered-out')
    ) {
      // no lot filtered or stage selected, remove shades and highlights
      map.setPaintProperty('lots-filtered-out', 'fill-opacity', 0)
      map.setPaintProperty('lots-filtered-in', 'fill-opacity', 0)
      map.setPaintProperty('lots-lines-outline-filtered-in', 'line-opacity', 0)
      map.setPaintProperty('lots-lines-outline-filtered-in-highlight', 'line-opacity', 0)
      map.setPaintProperty('lot-numbers', 'text-color', mapColors.default.type)
      map.setPaintProperty('lot-numbers', 'text-halo-color', mapColors.default.typeHalo)
    }
  }, [map, lotsFilters, selectedStage, filteredLots, lots, filteredHouses, allHousesLength, isMapLoaded])

  let currentLotId
  let currentStageId
  const [viewport, setViewport] = useState({
    lng: project.geo?.geometry?.coordinates ? project?.geo?.geometry?.coordinates[0] : 152.838,
    lat: project.geo?.geometry?.coordinates ? project?.geo?.geometry?.coordinates[1] : -27.694,
    zoom: project.zoomLevel ? +project.zoomLevel : 15.5,
  })

  useEffect(() => {
    Object.keys(stagesPopups).forEach((stageId) => stagesPopups[stageId].remove())
    const newPopups = {}
    if (isMapLoaded) {
      const stagesOutlines = convertStagesToGeoJsonOutline(stages, lotsById);
      const stagesWithCenterPoints = convertStagesOutlinesToGeoJsonCenteredPoints(stagesOutlines, lotsById)
      stagesWithCenterPoints.features.forEach(stage => {
        const stagePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          anchor: 'bottom',
        })
        // const center = getCenter(coordinates)
        stagePopup.setLngLat(stage.geometry.coordinates).setHTML(
          `
          <div class="tooltip-stage-wrapper">
            <span class="tooltip-text-stage">Stage ${stage.properties.number}<br />
              <span class="tooltip-text-stage-body">${stage.properties.name}</span>
            </span>
          </div>
        `,
        )
        newPopups[stage.id] = stagePopup
      })
      setStagesPopups(newPopups)
    }
  }, [stages, lots, map, isMapLoaded])

  useEffect(() => {
    if (!project) return
    async function init() {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: project.mapboxStyleUrl || process.env.REACT_APP_MAPBOX_STYLE_URL,
        center: isSalesMode ? undefined : [viewport.lng, viewport.lat],
        bounds: isSalesMode ? project.lngLatBounds : undefined,
        fitBoundsOptions: isSalesMode ? { padding: { bottom: vh(35) } } : undefined,
        zoom: viewport.zoom,
        attributionControl: false,
      })
      const mapStyle = project?.mapboxStyleUrl || undefined;
      dispatch(mapSet(map))
      let measurementsData
      if (project.measurementsUrl) {
        try {
          const response = await fetch(project.measurementsUrl)
          measurementsData = await response.json()
          setMeasurements(measurementsData)
        } catch (e) {
          console.log('failed to load measurements data')
        }
      }


      map.on('load', async () => {
        const position = isSalesMode ? 'bottom-right' : undefined
        map.addControl(new mapboxgl.NavigationControl(), position)
        map.addControl(new PitchToggle({ minpitchzoom: 14 }), position)
        map.addControl(new mapboxgl.AttributionControl(), isSalesMode ? 'top-left' : undefined)
        const directions = new MapboxDirections({
          accessToken: process.env.REACT_APP_MAPBOX_TOKEN.replace(/"/g, ''),
          unit: 'metric',
          profile: 'mapbox/driving',
          controls: { inputs: false, instructions: false },
          interactive: false,
          flyTo: false,
        })
        directions.on('route', ({ route }) => {
          const bounds = new mapboxgl.LngLatBounds()
          route.forEach((r) => {
            r.legs &&
              r.legs.forEach((leg) => {
                leg.steps &&
                  leg.steps.forEach((step) => {
                    step.intersections &&
                      step.intersections.forEach((intersection) => {
                        bounds.extend(intersection.location)
                      })
                  })
              })
          })
          const extraPadding = isMobile() ? 50 : 100
          map.fitBounds(bounds, {
            padding: {
              ...padding,
              left: (padding.left || 0) + extraPadding,
              right: (padding.right || 0) + extraPadding,
              top: (isMobile() ? 30 : padding.top || 0) + extraPadding,
              bottom:
                (isMobile()
                  ? window.innerHeight / 2 - 88
                  : isSalesMode
                    ? 500
                    : padding.bottom || 0) + extraPadding,
            },
          })
        })
        map.addControl(directions)
        dispatch(setDirections(directions))
        const scale = new mapboxgl.ScaleControl({
          maxWidth: isSalesMode ? 200 : 100,
        })
        map.addControl(scale, 'bottom-left')
        //IMAGES
        if (project.mapLogo?.url) {
          const logo = new Image(512, 512)
          logo.crossOrigin = "Anonymous";
          logo.onload = () => {
            map.addImage('logo', logo)
          }
          logo.src = project.mapLogo?.url.replace('/image/upload/', '/image/upload/w_512,h_512,c_pad/')
        }
        const center = project.geo
        const options = { steps: 100, units: 'kilometers' }
        const radiusRanges = (project.mapRadiusRanges || '10').split(';')
        const radiusLabels = radiusRanges.map(radius => ({
          ...destination(center, radius, 0, { units: 'kilometers' }),
          properties: {
            label: radius >= 1 ? radius + 'km' : (radius * 1000) + 'm',
          },
        }))
        const radiusPolygons = radiusRanges.map(radius =>
          circle(center, radius, options)
        )

        map.addSource('radiuses-polygon', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: radiusPolygons
          },
        })
        map.addLayer({
          id: 'radius-shadow',
          type: 'line',
          source: 'radiuses-polygon',
          paint: {
            'line-color': '#000',
            'line-width': 10,
            'line-opacity': 0.2,
            'line-blur': 10,
          },
          layout: {
            'line-join': 'round',
            visibility: 'none',
          },
        })
        map.addLayer({
          id: 'radius',
          type: 'line',
          source: 'radiuses-polygon',
          paint: {
            'line-color': '#fafe81',
            'line-width': 2,
          },
          layout: {
            'line-join': 'round',
            visibility: 'none',
          },
        })

        map.addLayer({
          id: 'radius-labels',
          type: 'symbol',
          interactive: true,
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: radiusLabels,
            },
          },
          layout: {
            'text-field': ['get', 'label'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 18, 18, 24],
            // 'text-offset': [0, 0.1],
            ...(mapStyle ? { 'text-font': ['Karla Bold', 'Arial Unicode MS Regular'] } : undefined),
            'text-anchor': 'bottom',
            'text-allow-overlap': true,
            visibility: 'none',
          },
          paint: {
            'text-color': 'hsl(42, 2%, 34%)',
            'text-halo-color': 'hsl(0, 0%, 100%)',
            'text-halo-width': 1.5,
            'text-halo-blur': 1,
          },
        })
        // END OF CLUSTERS
        map.addSource('lots-source', {
          type: 'geojson',
          data: convertLotsToGeoJson(lotsOnMap),
          generateId: true,
        })
        map.addSource('stages-source', {
          type: 'geojson',
          data: convertStagesToGeoJson(stages, lotsById),
          generateId: true,
        })
        const stagesOutlines = convertStagesToGeoJsonOutline(stages, lotsById);
        map.addSource('stages-outline-source', {
          type: 'geojson',
          data: stagesOutlines,
          generateId: true,
        })
        map.addSource('stages-center-source', {
          type: 'geojson',
          data: convertStagesOutlinesToGeoJsonCenteredPoints(stagesOutlines, lotsById),
          generateId: true,
        })
        const lotNumbers = getLotNumbers(lotsOnMap)
        map.addSource('lot-numbers', {
          type: 'geojson',
          data: lotNumbers,
          generateId: true,
        })
        if (measurementsData) {
          map.addSource('measurements-length-source', {
            type: 'geojson',
            data: measurementsData,
            generateId: true,
          })
        }

        // Workaround to ensure Kinley map does render lot outlines due to missing raster-tree layer
        map.addLayer({
          id: 'raster-trees',
          type: 'raster',
          source: {
              type: 'raster',
              tiles: [
                  'https://studio.mapbox.com/tilesets/jackbeilby.1zix4wwv'
              ],
              tileSize: 256
          },
          paint: {
              'raster-opacity': 0.5
          }
        });

        map.addLayer({
          id: 'lots',
          type: 'fill',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          layout: {},
          paint: { 'fill-color': mapColors.default.fill },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lots-dark',
          type: 'fill',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          layout: {
            visibility: 'none',
          },
          paint: {
            'fill-opacity-transition': {
              duration: 1000,
            },
            'fill-color': 'hsl(109, 37%, 75%)',
          },
          filter: ['all', ['!=', ['get', 'humanId'], lotPopup ? lotPopup.humanId : 0]],
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lots-filtered-out',
          type: 'fill',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          paint: {
            'fill-opacity': 0,
            'fill-opacity-transition': {
              duration: 1000,
            },
            'fill-color': mapColors.default.fill,
          },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lots-filtered-in',
          type: 'fill',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          paint: {
            'fill-opacity': 0,
            'fill-opacity-transition': {
              duration: 1000,
            },
            'fill-color': mapColors.highlighted.fill,
          },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lot-tooltip',
          type: 'symbol',
          source: 'lot-numbers',
          minzoom: LOT_MINZOOM + 1,
          layout: {
            'icon-image': 'tooltip',
          },
        })

        map.addLayer({
          id: 'lots-lines-outline',
          type: 'line',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          layout: {},
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              'hsl(0, 0%, 100%)',
              mapColors.default.outline,
            ],
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 0.5],
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'lots-lines-outline-filtered-in',
          type: 'line',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          layout: {},
          paint: {
            'line-color': mapColors.highlighted.outline,
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 10, 15],
            'line-blur': ['case', ['boolean', ['feature-state', 'hover'], false], 8.2, 15],
            'line-opacity': 0,
          },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lots-lines-outline-filtered-in-highlight',
          type: 'line',
          source: 'lots-source',
          minzoom: LOT_MINZOOM,
          layout: {},
          paint: {
            'line-color': 'hsl(0, 0%, 100%)',
            'line-opacity': 0,
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'lots-lines-glow',
          type: 'line',
          source: 'lots-source',
          minzoom: LOT_MINZOOM + 1,
          layout: {},
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              'hsl(0, 0%, 100%)',
              'transparent',
            ],
            'line-blur': ['case', ['boolean', ['feature-state', 'hover'], false], 10, 0],
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.38, 0],
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 10, 0],
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'lot-selected-3d-shadow',
          type: 'fill',
          source: 'lots-source',
          minzoom: LOT_MINZOOM + 1,
          layout: {},
          paint: {
            'fill-color': mapColors.selected.fill,
          },
          filter: ['==', ['get', 'lot'], lotPopup ? +lotPopup.humanId : 0],
        }, project.overlayLayerId)

        map.addLayer({
          id: 'lot-selected-3d',
          type: 'fill-extrusion',
          source: 'lots-source',
          minzoom: LOT_MINZOOM + 1,
          layout: {},
          paint: {
            'fill-extrusion-color': mapColors.selected.fillExtrusion,
            'fill-extrusion-base': 1,
            'fill-extrusion-height': 1.1,
          },
          filter: ['==', ['get', 'humanId'], lotPopup ? lotPopup.humanId : 0],
        })

        /*map.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': 14
          });
        // add the DEM source as a terrain layer with exaggerated height
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        */

        // add a sky layer that will show when the map is highly pitched
        map.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }
        });

        let mapBoxModelLayerConfig;
        try {
          if (project.modelDataUrl) {
            const url = project.modelDataUrl + "?ts=" + (new Date().getTime())
            const response = await fetch(url)
            mapBoxModelLayerConfig = await response.json()
          }
        } catch (e) {
          console.log('failed to load 3d mapBoxModelLayerConfig data')
        }
        if (mapBoxModelLayerConfig) {
          const presentationMode = isSalesMode ? 'sales' : isMobile() ? 'mobile' : 'desktop';
          const { frontendEnabled3dModels } = project;
          const debugMapBoxModelLayer = getURLQueryParams('DebugMapBoxModelLayer') === "true";

          if (debugMapBoxModelLayer) {
            window.__MapBoxModelLayer = {
              map: map,
              mapBoxModelLayerConfig: mapBoxModelLayerConfig
            };
          }
          const mapBoxModelLayer = new MapBoxModelLayer({
            addStats: getURLQueryParams('stats3d') === "true" || debugMapBoxModelLayer,

            hideWhenZoomLessThan: project.frontendModelMinZoom || 14,
            mapBoxModelLayerConfig: mapBoxModelLayerConfig,
            onAdd: function (layer) {
              function addModels(
                geodata,
                f_On_MapBox_Option_Created,
                f_Add_Model_Hi,
                f_Add_Model_Low,
              ) {
                const mapboxOptionsArray = []
                const length = geodata.features.length

                for (let i = 0; i < length; i++) {
                  const feature = geodata.features[i]
                  const geometry = feature.geometry

                  const coords = getGeometryCenter(geometry)

                  const mapboxOptions = {
                    geodata: coords,
                    altitude: 0,
                    rotation: [Math.PI / 2, 0, 0],
                    scale: 1,
                  }

                  if (f_On_MapBox_Option_Created) {
                    f_On_MapBox_Option_Created(mapboxOptions, i)
                  }

                  mapboxOptionsArray.push(mapboxOptions)

                  if (f_Add_Model_Hi) {
                    f_Add_Model_Hi(mapboxOptions, i)
                  }
                }

                if (f_Add_Model_Low) {
                  f_Add_Model_Low(mapboxOptionsArray)
                }
              }

              if (getURLQueryParams('DebugCursor') === "true") {

                const div = document.createElement('div');
                document.body.appendChild(div);

                div.setAttribute(
                  'style',
                  `display: block;
                  top: 0px;
                  z-index: 9999;
                  position: fixed;
                  margin: 10px auto;
                  width: 50%;
                  padding: 10px;
                  border: none;
                  border-radius: 3px;
                  font-size: 12px;
                  text-align: center;
                  color: #222;
                  background: #fff;`
                );

                map.on('mousemove', function (e) {
                  div.innerHTML =
                    // e.point is the x, y coordinates of the mousemove event relative
                    // to the top-left corner of the map
                    JSON.stringify(e.point) +
                    '<br />' +
                    // e.lngLat is the longitude, latitude geographical position of the event
                    JSON.stringify(e.lngLat.wrap());
                });

                map.on('click', function (e) {
                  console.log(JSON.stringify(e.point));
                  console.log(JSON.stringify(e.lngLat.wrap()));
                });
              }

              const debugDrawTrees = getURLQueryParams('DebugDrawTrees') === "true";
              
              frontendEnabled3dModels.trees[presentationMode] &&
                mapBoxModelLayerConfig?.trees &&
                mapBoxModelLayerConfig.trees.forEach((model_params) => {

                  const layer_name = model_params.layerName;
                  const promises = [];
                  const trees_geodata = {
                    check_interval: 0,
                    attemps: 0,
                    geodata: {}
                  };

                  // TODO !
                  trees_geodata.geodata = {
                    features: map.queryRenderedFeatures(
                      [
                        [-99999999, -99999999],
                        [99999999, 99999999],
                      ],
                      { layers: [layer_name] },
                    ),
                  };

                  Promise.all(promises).then(
                    () => {
                      applyTrees(
                        model_params,
                        trees_geodata.geodata
                      );
                    },
                    () => {
                      console.error("An error has occured - can't apply trees");
                    });
                });

              function applyTrees(model_params, trees_geodata) {
                addModels(
                  trees_geodata,
                  function (mapboxOptions) {
                    mapboxOptions.altitude =
                      (model_params.altitude ? model_params.altitude : 0.0) +
                      getRandomArbitrary(0.0, 0.03)
                    mapboxOptions.rotation = [Math.PI / 2, getRandomArbitrary(0, 2 * Math.PI), 0]
                    mapboxOptions.scale = model_params.scaleRandomArbitrary
                      ? getRandomArbitrary(
                        model_params.scaleRandomArbitrary[0],
                        model_params.scaleRandomArbitrary[1],
                      )
                      : getRandomArbitrary(0.65, 0.95)
                  },
                  function f_Add_Model_Hi(mapboxOptions) {
                    //
                    // performace improvements - you don't need to add 
                    // hi-lod trees just because you don't have them;
                    // you use the same urls for hi-lod and low-lod
                    // 

                    if (debugDrawTrees) {
                      let lowLodUrl;
                      if (Array.isArray(model_params.lowLodUrl)) {
                        const i = getRandomInt(0, model_params.lowLodUrl.length)
                        lowLodUrl = model_params.lowLodUrl[i];
                      } else {
                        lowLodUrl = model_params.lowLodUrl;
                      }
                      layer.addModel(
                        mapboxOptions,
                        lowLodUrl,
                        layer.mapHighResScene,
                        Math.floor(getRandomArbitrary(10, 20)), // timeout
                        null,
                        false, // bBypassFlatten
                      );
                    }
                  },
                  function f_Add_Model_Low(mapboxOptionsArray) {
                    if (debugDrawTrees) {
                      return;
                    }

                    if (Array.isArray(model_params.lowLodUrl)) {

                      const randomizer = {};

                      for (let i = 0; i < mapboxOptionsArray.length; i++) {

                        const url_indx = getRandomInt(0, model_params.lowLodUrl.length);

                        if (!randomizer[url_indx]) {
                          randomizer[url_indx] = [];
                        }

                        randomizer[url_indx].push(mapboxOptionsArray[i]);
                      }

                      for (let url_indx in randomizer) {

                        const r_lowLodUrl = model_params.lowLodUrl[parseInt(url_indx)];
                        const r_mapboxOptionsArray = randomizer[url_indx];

                        layer.addInstancedModel(
                          r_mapboxOptionsArray,
                          r_lowLodUrl,
                          layer.mapHighResScene,
                          Math.floor(getRandomArbitrary(100, 500))
                        );
                      }
                    }
                    else {
                      const lowLodUrl = model_params.lowLodUrl;

                      layer.addInstancedModel(
                        mapboxOptionsArray,
                        lowLodUrl,
                        layer.mapHighResScene,
                        Math.floor(getRandomArbitrary(100, 500))
                      );
                    }
                  },
                );
              }

              // houses 
              frontendEnabled3dModels.houses[presentationMode] &&
                mapBoxModelLayerConfig?.houses &&
                mapBoxModelLayerConfig.houses.forEach((model_params, i) => {

                  const layer_name = model_params.layerName;
                  const promises = [];
                  const house_random_materials_cache = {};
                  const houses_geodata = {
                    check_interval: 0,
                    attemps: 0,
                    geodata: {}
                  };

                  if (model_params.randomTextures) {
                    for (let i = 0; i < model_params.randomTextures.length; i++) {
                      const textures_object = model_params.randomTextures[i];
                      for (let key in textures_object.images) {
                        const imageObj = textures_object.images[key];
                        promises.push(
                          new Promise((resolve, reject) => {
                            const image = new Image()
                            image.onload = () => {
                              imageObj.image = image;
                              resolve(image)
                            }
                            image.onerror = error => {
                              console.error(error);
                              reject(error);
                            }
                            image.crossOrigin = "anonymous";
                            image.src = imageObj.url;
                          }),
                        );
                      }
                    }
                  }

                  // TODO !
                  houses_geodata.geodata = {
                    features: map.queryRenderedFeatures(
                      [
                        [-99999999, -99999999],
                        [99999999, 99999999],
                      ],
                      { layers: [layer_name] },
                    ),
                  };

                  /*promises.push( new Promise( (resolve, reject) => {
                    // queryRenderedFeatures adhoc
                    houses_geodata.check_interval = setInterval( () => {
                      if (!map.loaded()) { 
                        return; 
                      } 
                      const _layer = map.getLayer(layer_name);
                      //houses_geodata.geodata = {
                      //  features: map.querySourceFeatures('composite', {
                      //    'sourceLayer': _layer.sourceLayer
                      //  })
                      //};
                      houses_geodata.geodata = {
                        features: map.queryRenderedFeatures(
                          [
                            [-9999999, -9999999],
                            [9999999, 9999999],
                          ],
                          { layers: [layer_name] },
                        ),
                      };
                      if (houses_geodata.geodata.features && 
                          houses_geodata.geodata.features.length
                      ) {
                        console.log("Houses::map.queryRenderedFeatures from " + houses_geodata.attemps + " attemp");
                        clearInterval(houses_geodata.check_interval);
                        resolve();
                        return;
                      }

                      houses_geodata.attemps++;
                      if (houses_geodata.attemps > 3) {
                        clearInterval(houses_geodata.check_interval);
                        reject();
                        return;
                      }
                    }, Math.floor(getRandomArbitrary(2500, 3000)));
                  }));*/

                  Promise.all(promises).then(
                    () => {
                      applyHouses(
                        model_params,
                        houses_geodata.geodata,
                        house_random_materials_cache
                      );
                    },
                    () => {
                      console.error("An error has occured - can't apply houses");
                    });
                });

              function applyHouses(
                model_params,
                houses_geodata,
                house_random_materials_cache
              ) {

                const highLodUrl = model_params.highLodUrl
                  ? model_params.highLodUrl
                  : model_params.lowLodUrl

                const def_altitude = model_params.altitude || 0.01
                const def_scale = model_params.scale || 0.6

                const mapboxOptionsArray = []
                const length = houses_geodata.features.length

                // tmp
                const p_orig = new THREE.Vector3()
                const q_orig = new THREE.Quaternion()
                const s_orig = new THREE.Vector3()
                const p2 = new THREE.Vector3()
                const q2 = new THREE.Quaternion()
                const s2 = new THREE.Vector3()

                for (let i = 0; i < length; i++) {

                  const feature = houses_geodata.features[i]
                  const geometry = feature.geometry

                  if (geometry.type === 'LineString') {

                    const coords_origin = geometry.coordinates[0]
                    const coords_direction = geometry.coordinates[1]

                    const altitude = parseFloat(
                      (feature.properties && feature.properties.altitude) || def_altitude,
                    )
                    let scale = parseFloat(
                      (feature.properties && feature.properties.scale) || def_scale,
                    )

                    let scale_r = getRandomInt(0, 2); // getRandomInt - maximum not included
                    if (!scale_r) {
                      scale_r = -1;
                    }

                    scale = [scale_r * scale, scale, scale];

                    const mapboxOptionsOrigin = {
                      geodata: coords_origin,
                      altitude: altitude,
                      rotation: [Math.PI / 2, 0, 0],
                      scale: scale, // now can recieve Number or Array(3)
                      modelData: model_params
                    }

                    const mapboxOptionsDirection = {
                      geodata: coords_direction,
                      altitude: altitude,
                      rotation: [Math.PI / 2, 0, 0],
                      scale: scale, // now can recieve Number or Array(3)
                    }

                    const matrix_orig = layer.getMatrixFromMapboxOptions(mapboxOptionsOrigin)
                    matrix_orig.decompose(p_orig, q_orig, s_orig)

                    const p1 = p_orig.clone()
                    p1.x += 0.000001

                    const matrix = layer.getMatrixFromMapboxOptions(mapboxOptionsDirection)
                    matrix.decompose(p2, q2, s2)

                    const dAx = p1.x - p_orig.x
                    const dAy = p1.y - p_orig.y
                    const dBx = p2.x - p_orig.x
                    const dBy = p2.y - p_orig.y
                    const angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy)

                    mapboxOptionsOrigin.rotation[1] =
                      scale_r * angle + (scale_r === -1 ? Math.PI : 0);

                    layer.addModel(
                      mapboxOptionsOrigin,
                      highLodUrl,
                      layer.mapHighResScene,
                      Math.floor(getRandomArbitrary(300, 600)),
                      (mapboxOptions, model) => { // onAdd

                        const changeMap = (images, index) => {

                          const imageObject = images[index];

                          model.traverse(node => {
                            if (node.material &&
                              node.material.map &&
                              imageObject.randomTextureNames[node.material.map.name]
                            ) {

                              const imageObj_key = node.material.map.name;
                              const imageObj_settings = imageObject.randomTextureNames[imageObj_key]
                              const imageObj = imageObject.images[imageObj_key];

                              const house_random_materials_cache_key = node.material.name + "-" + imageObj_key + "-" + index;

                              if (!house_random_materials_cache[house_random_materials_cache_key]) {

                                const material =
                                  house_random_materials_cache[house_random_materials_cache_key] =
                                  node.material.clone();

                                material.map = material.map.clone();
                                material.map.image = imageObj.image;

                                if (imageObj_settings.low) {
                                  material.map_original = material.map;
                                  material.map_low = material.map.clone();
                                  material.map_low.image =
                                    imageObject.images[imageObj_key + "_low"].image;

                                  material.map_low.needsUpdate = true;
                                }

                                material.map.needsUpdate = true;
                              }

                              node.material =
                                house_random_materials_cache[house_random_materials_cache_key];

                              node.onBeforeRender =
                                (renderer, scene, camera, geometry, material, group) => {

                                  if (node.material === material) { // due screen draw

                                    if (!node.__mesh_material_selector) {
                                      node.__mesh_material_selector = {
                                        map: 0 // 0 - original, 1 - low
                                      };
                                    }

                                    if (_isMobile && imageObj_settings.low) { // use low texture for mobile

                                      if (node.__mesh_material_selector.map !== 1) {
                                        node.__mesh_material_selector.map = 1;
                                        node.material.map = material.map_low;
                                        node.material.needsUpdate = true;
                                      }
                                    }
                                    else {

                                      if (imageObj_settings.low &&
                                        layer.map.transform.tileZoom < 19
                                      ) { // overall view - set low sized texture

                                        if (node.__mesh_material_selector.map !== 1) {
                                          node.__mesh_material_selector.map = 1;
                                          node.material.map = material.map_low;
                                          node.material.needsUpdate = true;
                                        }
                                      }
                                      else { // zommed in -> set original 2k texture

                                        if (node.__mesh_material_selector.map !== 0) {
                                          node.__mesh_material_selector.map = 0;
                                          node.material.map = material.map_original;
                                          node.material.needsUpdate = true;
                                        }
                                      }
                                    }
                                  }
                                };
                            }
                          });
                        };

                        if (model_params.randomTextures &&
                          model_params.randomTextures.length) {
                          changeMap(
                            model_params.randomTextures,
                            getRandomInt(0, model_params.randomTextures.length)
                          ); // getRandomInt - maximum not included
                        }

                      },
                      false, // bBypassFlatten
                    );

                    mapboxOptionsArray.push(mapboxOptionsOrigin)

                    if (getURLQueryParams('debug3d') === "true") {
                      // debug
                      const material2 = new THREE.LineBasicMaterial({
                        color: 0xff0000,
                      })
                      const geometry2 = new THREE.Geometry()
                      geometry2.vertices.push(
                        new THREE.Vector3(p_orig.x, p_orig.y, p_orig.z + 0.00000001),
                        new THREE.Vector3(p2.x, p2.y, p2.z + 0.00000001),
                      )
                      const line2 = new THREE.Line(geometry2, material2)
                      layer.mapHighResScene.add(line2)
                    }
                  }
                  else {
                    console.error("geometry.type !== 'LineString'!");
                  }
                }
              }

              // buildings ------------
              frontendEnabled3dModels.buildings[presentationMode] &&
              mapBoxModelLayerConfig?.buildings &&
                mapBoxModelLayerConfig.buildings.forEach((modelData) => {
                  const mapboxOptions = {
                    geodata: modelData.coords,
                    altitude: modelData.altitude,
                    rotation: modelData.rotation,
                    scale: modelData.scale,
                    modelData: modelData
                  }

                  const url = _isMobile ? modelData.lowLodUrl :
                    (modelData.highLodUrl || modelData.lowLodUrl);

                  layer.addModel(
                    mapboxOptions,
                    url,
                    layer.mapHighResScene,
                    Math.floor(getRandomArbitrary(100, 300)), // timeout
                    null,
                    true, // bBypassFlatten
                  )
                });
            },
          })
          map.addLayer(mapBoxModelLayer, map.getLayer('poiRef') ? 'poiRef' : undefined);
          mapBoxModelLayer.toggleVisiblity(getURLQueryParams('bypass3dlayer') ? false : true);

          if (debugMapBoxModelLayer) {
            window.__MapBoxModelLayer.mapBoxModelLayer = mapBoxModelLayer;
          }

        }

        map.addLayer({
          id: 'stages-outline-color-coming-soon',
          minzoom: LOT_MINZOOM - 1,
          source: 'stages-outline-source',
          filter: ['all', ['==', ['get', 'releaseStatus'], 'COMING_SOON']],
          type: 'line',
          layout: {},
          paint: {
            'line-color': ['get', 'color'],
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 5, 3],
            'line-blur': ['case', ['boolean', ['feature-state', 'hover'], false], 5, 3],
          },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'stages-fill-color-coming-soon',
          type: 'fill',
          maxzoom: 20,
          source: 'stages-outline-source',
          filter: ['all', ['==', ['get', 'releaseStatus'], 'COMING_SOON']],
          paint: {
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              LOT_MINZOOM - 3.5,
              0,
              LOT_MINZOOM - 3,
              0.5,
              LOT_MINZOOM,
              0.5,
              LOT_MINZOOM + 1,
              0.2,
            ],
            'fill-color': ['get', 'color'],
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'stages-release-name-coming-soon',
          type: 'symbol',
          maxzoom: 20,
          minzoom: LOT_MINZOOM + 1,
          source: 'stages-center-source',
          filter: ['all', ['==', ['get', 'releaseStatus'], 'COMING_SOON']],
          layout: {
            'text-allow-overlap': true,
            'text-field': ['to-string', ['get', 'name']],
            ...(mapStyle ? { 'text-font': ['Avenir LT Std 95 Black', 'Arial Unicode MS Regular'] } : undefined),
            'text-line-height': 1.1,
            'text-size': ['interpolate', ['linear'], ['zoom'], LOT_MINZOOM - 3, 0, LOT_MINZOOM + 5, 32],
            'text-transform': 'uppercase',                
            'text-letter-spacing': 0.3,
            'text-offset': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              ['literal', [0, 0.3]],
              22,
              ['literal', [0, 0.3]],
            ],
            "text-anchor": "top"
          },
          paint: {
            'text-color': 'hsl(0, 0%, 100%)',
            'text-halo-color': 'rgba(0, 0, 0, 0.6)',
            'text-halo-width': 0.7,
            'text-halo-blur': 0.2,    
            
          },
        }, project.overlayLayerId)



        map.addLayer({
          id: 'stages-fill-color',
          type: 'fill',
          maxzoom: LOT_MINZOOM + 1,
          source: 'stages-source',
          filter: ['all', ['!=', ['get', 'releaseStatus'], 'COMING_SOON']],
          paint: {
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              LOT_MINZOOM - 3.5,
              0,
              LOT_MINZOOM - 3,
              0.5,
              LOT_MINZOOM,
              0.5,
              LOT_MINZOOM + 1,
              0,
            ],
            'fill-color': ['get', 'color'],
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'stages-pattern',
          type: 'fill',
          maxzoom: LOT_MINZOOM + 1,
          source: 'stages-source',
          paint: {
            'fill-pattern': 'speckle2',
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              LOT_MINZOOM - 3.5,
              0,
              LOT_MINZOOM - 3,
              1,
              LOT_MINZOOM,
              1,
              LOT_MINZOOM + 1,
              0,
            ],
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'stages-number',
          type: 'symbol',
          maxzoom: LOT_MINZOOM + 1,
          source: 'stages-center-source',
          layout: {
            'text-allow-overlap': true,
            'text-field': ['to-string', ['get', 'displayNumber']],
            ...(mapStyle ? { 'text-font': ['Avenir LT Std 85 Heavy', 'Arial Unicode MS Regular'] } : undefined),
            'text-line-height': 1,
            'text-size': ['interpolate', ['linear'], ['zoom'], LOT_MINZOOM - 3, 0, LOT_MINZOOM + 5, 80],
            "text-anchor": "bottom"
          },
          paint: {
            'text-color': 'hsl(0, 0%, 100%)',
            'text-halo-color': 'rgba(0, 0, 0, 0.64)',
            'text-halo-width': 0.8,
            'text-halo-blur': 0.3,            
            'text-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              LOT_MINZOOM - 3.5,
              0,
              LOT_MINZOOM - 3,
              1,
              LOT_MINZOOM,
              1,
              LOT_MINZOOM + 1,
              0,
            ],
          },
        })

        map.addLayer({
          id: 'stages-release-name',
          type: 'symbol',
          maxzoom: LOT_MINZOOM + 1,
          source: 'stages-center-source',
          layout: {
            'text-allow-overlap': true,
            'text-field': ['to-string', ['get', 'name']],
            ...(mapStyle ? { 'text-font': ['Avenir LT Std 95 Black', 'Arial Unicode MS Regular'] } : undefined),
            
            'text-line-height': 1.1,
            'text-size': ['interpolate', ['linear'], ['zoom'], LOT_MINZOOM - 3, 0, LOT_MINZOOM + 5, 40],
            'text-transform': 'uppercase',                
            'text-letter-spacing': 0.3,
            'text-offset': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              ['literal', [0, 0.3]],
              22,
              ['literal', [0, 0.3]],
            ],
            "text-anchor": "top"
          },
          paint: {
            'text-color': 'hsl(0, 0%, 100%)',
            'text-halo-color': 'rgba(0, 0, 0, 0.6)',
            'text-halo-width': 0.7,
            'text-halo-blur': 0.2,    
            
            'text-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              LOT_MINZOOM - 3.5,
              0.5,
              LOT_MINZOOM - 3,
              1,
              LOT_MINZOOM,
              1,
              LOT_MINZOOM + 1,
              0,
            ],
          },
        })

        map.addLayer({
          id: 'stages-boundries-fill',
          type: 'fill',
          source: 'stages-source',
          filter: ['all', ['!=', ['get', 'releaseStatus'], 'COMING_SOON']],
          layout: {},
          maxzoom: LOT_MINZOOM + 1,
          paint: {
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
            'fill-color': '#ffffff',
          },
        }, project.overlayLayerId)

        map.addLayer({
          id: 'stages-boundries-fill-coming-soon',
          type: 'fill',
          source: 'stages-outline-source',
          filter: ['all', ['==', ['get', 'releaseStatus'], 'COMING_SOON']],
          layout: {},
          maxzoom: 20,
          paint: {
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
            'fill-color': '#ffffff',
          },
        }, project.overlayLayerId)
        map.addLayer({
          id: 'lot-numbers',
          type: 'symbol',
          minzoom: LOT_MINZOOM - 1,
          source: 'lot-numbers',
          layout: {
            'icon-image': [
              'case',
              ['==', ['get', 'status'], 'Available'],
              'available',
              ['==', ['get', 'status'], 'Sold'],
              'sold',
              ['==', ['get', 'status'], 'Settled'],
              'sold',
              ['==', ['get', 'status'], 'Unreleased'],
              'sold',
              ['==', ['get', 'status'], 'Deposited'],
              'under-deposit',
              ['==', ['get', 'status'], 'On Hold'],
              'under-deposit',
              'available',
            ],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': ['get', 'description'],
            'text-line-height': 1,
            'text-size': ['interpolate', ['linear'], ['zoom'], 16, 8, 22, 28],
            'text-radial-offset': 0.8,
            'text-allow-overlap': true,
            'text-variable-anchor': ['bottom'],
            'symbol-avoid-edges': true,
            'text-ignore-placement': true,
            ...(mapStyle ? { 'text-font': ['Karla Regular', 'Arial Unicode MS Regular'] } : undefined),
            'icon-size': ['interpolate', ['linear'], ['zoom'], 16, 0.1, 22, 0.37],
            'text-anchor': 'bottom',
            'text-max-width': 20,
          },
          paint: {
            'text-color': mapColors.default.type,
            'text-halo-color': mapColors.default.typeHalo,
            'text-opacity': [ "interpolate", ["linear"], ["zoom"], LOT_MINZOOM, 0, LOT_MINZOOM + 1, 1 ],
            'icon-opacity': [ "interpolate", ["linear"], ["zoom"], LOT_MINZOOM, 0, LOT_MINZOOM + 1, 1 ],
          },
        })

        map.addLayer({
          id: 'logo',
          type: 'symbol',
          interactive: true,
          // maxzoom: 13.5,
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  ...project.geo,
                },
              ],
            },
          },
          layout: {
            'icon-image': 'logo',
            'icon-size': ['interpolate', ['exponential', 1], ['zoom'], 12, 0.3, 22, 2],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 13.5, 1, 14, 0],
          },
        })
        if (measurementsData) {
          map.addLayer({
            id: 'length-line',
            type: 'line',
            source: 'measurements-length-source',
            minzoom: LOT_MINZOOM + 1,
            layout: { 'line-cap': 'square', 'line-join': 'round' },
            paint: {
              'line-dasharray': [4, 5],
              'line-color': 'hsl(113, 10%, 47%)',
              'line-translate': [0, 0],
            },
            filter: ['==', ['get', 'lot'], lotPopup ? lotPopup.humanId : 0],
          }, project.overlayLayerId)

          map.addLayer({
            id: 'length-number',
            type: 'symbol',
            source: 'measurements-length-source',
            minzoom: LOT_MINZOOM + 1,
            layout: {
              'text-line-height': 1.7,
              'text-size': ['interpolate', ['linear'], ['zoom'], 18, 13, 22, 17],
              'text-radial-offset': -1,
              'symbol-avoid-edges': true,
              ...(mapStyle ? { 'text-font': ['Karla Regular', 'Arial Unicode MS Regular'] } : undefined),
              'symbol-placement': 'line-center',
              'text-justify': 'auto',
              'text-padding': 100,
              'text-rotation-alignment': 'map',
              'text-anchor': 'top',
              'text-pitch-alignment': 'map',
              'text-field': ['to-string', ['get', 'length_txt']],
              'text-max-width': 30,
            },
            paint: {
              'text-color': 'hsl(0, 0%, 28%)',
              'text-halo-color': 'hsl(0, 0%, 100%)',
              'text-halo-width': 0.5,
              'text-halo-blur': 1,
            },
            filter: ['==', ['get', 'lot'], lotPopup ? lotPopup.humanId : 0],
          }, project.overlayLayerId)
        }

        const callStageSource = (fn, sid, args) => ['stages-source', 'stages-outline-source'].forEach(source => map[fn]({ source, id: sid }, args))
        function stageClick(e) {
          const hasPoi = map
            .queryRenderedFeatures(e.point)
            .find((f) => f.layer.id.indexOf('onsite-') === 0 || f.layer.id.indexOf('nearby-') === 0)
          if (hasPoi) return
          const bounds = new mapboxgl.LngLatBounds()
          bounds.extend(e.lngLat)
          const stage = stages.find((s) => s.id === e.features[0].properties.id)
          dispatch(setSelectedStage(stage))

          map.fitBounds(bounds, { padding, maxZoom: 18 })
        }
        function stageMouseOver(e) {
          map.getCanvas().style.cursor = 'pointer'
          const feature = e.features[0]
          if (currentStageId || currentStageId === 0) {
            callStageSource('removeFeatureState', currentStageId)
          }
          currentStageId = e.features[0].id
          callStageSource('setFeatureState', currentStageId, { hover: true })
        }
        function stageMouseLeave(e) {
          callStageSource('setFeatureState', currentStageId, { hover: false })
          currentStageId = null
          map.getCanvas().style.cursor = ''
          popup.remove()
        }

        map.on('click', 'stages-boundries-fill', stageClick)
        map.on('mouseover', 'stages-boundries-fill', stageMouseOver)
        map.on('mouseleave', 'stages-boundries-fill', stageMouseLeave)

        map.on('click', 'stages-boundries-fill-coming-soon', stageClick)
        map.on('mouseover', 'stages-boundries-fill-coming-soon', stageMouseOver)
        map.on('mouseleave', 'stages-boundries-fill-coming-soon', stageMouseLeave)

        map.on('click', function (e) {
          const bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
          ]
          const features = map.queryRenderedFeatures(bbox, {
            layers: ['lots'],
          })

          if (!features.length) {
            dispatch(hideLotPopup())
          }

          const stageFeatures = map.queryRenderedFeatures(bbox, {
            layers: ['stages-boundries-fill'],
          })
          if (!stageFeatures.length) {
            dispatch(setSelectedStage())
          }
        })

        map.on('mousemove', 'lots', function (e) {
          if (currentLotId || currentLotId === 0) {
            map.removeFeatureState({
              source: 'lots-source',
              id: currentLotId,
            })
          }
          if (e.features[0].properties.status === 'Unreleased') {
            map.getCanvas().style.cursor = ''
            return
          }
          map.getCanvas().style.cursor = 'pointer'
          currentLotId = e.features[0].id

          map.setFeatureState(
            {
              source: 'lots-source',
              id: currentLotId,
            },
            {
              hover: true,
            },
          )
        })

        map.on('mouseleave', 'lots', function (e) {
          map.setFeatureState(
            {
              source: 'lots-source',
              id: currentLotId,
            },
            {
              hover: false,
            },
          )

          currentLotId = null
          map.getCanvas().style.cursor = ''
          // popup.remove()
        })

        map.on('click', 'lots', (e) => {
          console.log('clickLot', map.layers)
          const hasPoi = map
            .queryRenderedFeatures(e.point)
            .find((f) => f.layer.id.indexOf('onsite-') === 0 || f.layer.id.indexOf('nearby-') === 0)
          if (hasPoi) return

          const features = map.queryRenderedFeatures(e.point, { layers: ['lots'] })
          const { id, status } = features[0].properties
          if (status === 'Unreleased')
            return
          if (!cachedPackages[id]) {
            dispatch(Api.fetchLotPackages(id))
          }
          const lot = lots.find((lot) => lot.id === id)
          dispatch(changeTab('land'))
          dispatch(showLotPopup(lot))
        })

        map.on('click', 'points', (e) => {
          map.fitBounds(lngLatBounds, { padding, maxZoom })
        })

        const layers = map.getStyle().layers
        const source = map.getSource('composite')
        setIsMapLoaded(true)
      })
    }
    init()
  }, [project, lots])
  const selectedLotStage = lotPopup?.stage?.id
  useEffect(() => {
    if (map && isMapLoaded) {
      let zoomBegin
      const init = () => {
        zoomBegin = map.getZoom()
      }
      const track = () => {
        if (selectedStage) {
          const zoom = map.getZoom()
          if (zoom < zoomBegin && zoom < 17.5) {
            dispatch(setSelectedStage())
          } 
        } else if (selectedLotStage && !selectedStage) {
          const zoom = map.getZoom()
          if (zoom > zoomBegin && zoom >= 17.5) {
            const stage = stages.find((s) => s.id === selectedLotStage)
            if (stage)
              dispatch(setSelectedStage(stage))
          }           
        }
      }
      map.on('zoomstart', init)
      map.on('zoom', track)
      return function cleanup() {
        map.off('zoomstart', init)
        map.off('zoom', track)
      }
    }
  }, [map, selectedLotStage, selectedStage, isMapLoaded])

  return <S.MapContainer ref={mapContainer} />
}

export default Map
