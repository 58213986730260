import mapboxgl from 'mapbox-gl'
import { isMobile } from '../utils/functions'
import {
  FETCH_LOTS,
  PRICE_MIN,
  PRICE_MAX,
  AREA_MIN,
  AREA_MAX,
  FILTER_LOTS,
  FILTER_LOTS_CLEAR,
  FETCH_HOUSES,
  FILTER_HOUSES,
  FILTER_HOUSES_CLEAR,
  SHOW_TOAST,
  HIDE_TOAST,
  MAP_SET,
  EMAIL_ERROR,
  EMAIL_ERROR_RESET,
  SORT_HOUSES,
  SORT_LOTS,
  SHOW_LOT_POPUP,
  HIDE_LOT_POPUP,
  FETCH_FAVOURITES,
  ADD_LOT_TO_FAVOURITES,
  ADD_PACKAGE_TO_FAVOURITES,
  FETCH_STAGES,
  FETCH_POINTS,
  FETCH_POINTS_CATEGORY,
  SHOW_POINT_POPUP,
  SET_POINTS_INITIALIZED,
  SET_ONSITE_POINTS_INITIALIZED,
  SET_CACHED_PACKAGES,
  FETCH_PROJECT_DATA,
  SET_MAP_POPUP,
  SET_MAP_PADDING_REFS,
  FETCH_BUILDERS,
  SIGNIN_SALES,
  SIGNOUT_SALES,
  SET_SELECTED_POI,
  SET_SELECTED_STAGE,
  SET_PDF_URL,
  TOGGLE_CONTENT_VISIBILITY,
  SET_CONTENT_VISIBILITY,
  SET_TOGGLE_BUTTON_VISIBILITY,
  SET_AGREEMENT_MODAL_TYPE,
  DISPLAY_ROUTE_TO_POINT,
  HIDE_ROUTE_TO_POINT,
  CHANGE_TAB,
  SET_DIRECTIONS,
  SET_MAP_SCALE_VISIBILITY
} from './constants'
const initialState = {
  activeTab: 'land',
  contentPaneVisible: !isMobile(),
  mapScaleVisible: true,
  setToggleButtonVisibility: false,
  routeVisible: false,
  directions: null,
  agreementModalType: undefined,
  project: {},
  pdfUrl: undefined,
  lots: {
    data: [],
    loaded: false,
    sortBy: undefined,
    filters: {
      price: {
        min: undefined,
        max: undefined,
      },
      area: {
        min: undefined,
        max: undefined,
      },
      frontage: {
        min: undefined,
        max: undefined,
      },
    },
  },
  houses: {
    data: [],
    sortBy: undefined,
    filters: {
      price: {
        min: undefined,
        max: undefined,
      },
      area: {
        min: undefined,
        max: undefined,
      },
    },
  },
  stages: {
    loaded: false,
    data: [],
  },
  points: {
    data: [],
    selectedPoi: undefined,
  },
  builders: {
    data: [],
  },
  pointsInitialized: false,
  pointsOnsiteInitialized: false,
  pointsCategory: {
    data: [],
  },
  toast: {
    show: false,
    body: 'Success',
    type: 'success',
  },
  map: {
    map: null,
  },
  mapPaddingRefs: {},
  pointPopup: null,
  lotPopup: null,
  selectedStage: null,
  emailError: false,
  favourites: {
    lots: [],
    packages: [],
  },
  cachedPackages: {},
  mapPopup: new mapboxgl.Popup({
    closeButton: false,
  }),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
        ...(isMobile() ? {} : { contentPaneVisible: true }),
      }
    case SET_DIRECTIONS:
      return {
        ...state,
        directions: action.directions,
      }
    case DISPLAY_ROUTE_TO_POINT:
      return {
        ...state,
        routeVisible: true,
        routeOnDismiss: action.onDismiss,
      }
    case HIDE_ROUTE_TO_POINT:
      return {
        ...state,
        routeVisible: false,
        routeOnDismiss: undefined,
      }
    case TOGGLE_CONTENT_VISIBILITY:
      return {
        ...state,
        contentPaneVisible: !state.contentPaneVisible,
      }
    case SET_CONTENT_VISIBILITY:
      return {
        ...state,        
        contentPaneVisible: action.contentPaneVisible,
      }    
    case SET_MAP_SCALE_VISIBILITY:
      return {
        ...state,        
        mapScaleVisible: action.mapScaleVisible,
      }          
    case SET_TOGGLE_BUTTON_VISIBILITY:
      return {
        ...state,
        contentToggleButtonVisible: action.contentToggleButtonVisible,
      }

    case FETCH_PROJECT_DATA:
      const lngLatBounds = new mapboxgl.LngLatBounds()
      const coords = action.project?.boundries?.geometry?.coordinates
      if (coords) coords[0].forEach((b) => lngLatBounds.extend(b))
      return {
        ...state,
        project: {
          ...action.project,
          lngLatBounds,
        },
      }
    case SET_MAP_POPUP:
      return {
        ...state,
        mapPopup: action.mapPopup,
        ...(action.mapPopup && !isMobile() ? { contentPaneVisible: true } : {}),
      }
    case SET_POINTS_INITIALIZED:
      return {
        ...state,
        pointsInitialized: true,
      }
    case SET_ONSITE_POINTS_INITIALIZED:
      return {
        ...state,
        pointsOnsiteInitialized: true,
      }
    case FETCH_LOTS:
      return {
        ...state,
        lots: {
          ...state.lots,
          loaded: true,
          data: action.lots,
        },
      }
    case FETCH_HOUSES:
      return {
        ...state,
        houses: {
          ...state.houses,
          data: action.houses,
        },
      }
    case FETCH_STAGES:
      return {
        ...state,
        stages: {
          ...state.stages,
          loaded: true,
          data: action.stages,
        },
      }
    case FETCH_POINTS:
      return {
        ...state,
        points: {
          ...state.points,
          data: action.points,
        },
      }
    case SET_SELECTED_POI:
      return {
        ...state,
        points: {
          ...state.points,
          selectedPoi: action.selectedPoi,
        },
        ...(action.selectedPoi && !isMobile() ? { contentPaneVisible: true } : {}),
      }
    case SET_MAP_PADDING_REFS:
      const mapPaddingRefs = { ...state.mapPaddingRefs }
      if (action.mapPaddingRefs?.bottom !== undefined)
        mapPaddingRefs.bottom = action.mapPaddingRefs.bottom
      if (action.mapPaddingRefs?.top !== undefined) mapPaddingRefs.top = action.mapPaddingRefs.top
      if (action.mapPaddingRefs?.map !== undefined) mapPaddingRefs.map = action.mapPaddingRefs.map

      return {
        ...state,
        mapPaddingRefs,
      }
    case FETCH_BUILDERS:
      return {
        ...state,
        builders: {
          ...state.builders,
          data: (action.builders || [])
            .filter(({ packagesCount }) => !!packagesCount)
            .sort(function (a, b) {
              const nameA = a.name.toUpperCase()
              const nameB = b.name.toUpperCase()
              if (nameA < nameB) return -1
              if (nameA > nameB) return 1
              return 0
            }),
        },
      }
    case FETCH_POINTS_CATEGORY:
      return {
        ...state,
        pointsCategory: {
          ...state.pointsCategory,
          data: action.pointsCategory,
        },
      }
    case FETCH_FAVOURITES:
      return {
        ...state,
        favourites: {
          lots: action.favourites.lots && action.favourites.lots.map(({item}) => item),
          packages: action.favourites.packages && action.favourites.packages.map(({item}) => item),
        },
      }
    case ADD_PACKAGE_TO_FAVOURITES:
      const newPackage = state.houses.data.find((p) => p.id === action.packageId)

      const index = state.favourites.packages.findIndex((p) => p.id === action.packageId)
      let newArray
      if (index >= 0) {
        newArray = [
          ...state.favourites.packages.splice(0, index),
          ...state.favourites.packages.splice(index + 1, state.favourites.packages.length),
        ]
      } else {
        newArray = [...state.favourites.packages, newPackage]
      }

      return {
        ...state,
        favourites: {
          ...state.favourites,
          packages: newArray,
        },
      }
    case ADD_LOT_TO_FAVOURITES:
      const newLot = state.lots.data.find((lot) => lot.id === action.lotId)

      const index2 = state.favourites.lots.findIndex((l) => l.id === action.lotId)
      let newArray2
      if (index2 >= 0) {
        newArray2 = [
          ...state.favourites.lots.splice(0, index2),
          ...state.favourites.lots.splice(index2 + 1, state.favourites.lots.length),
        ]
      } else {
        newArray2 = [...state.favourites.lots, newLot]
      }
      return {
        ...state,
        favourites: {
          ...state.favourites,
          lots: newArray2,
        },
      }
    case FILTER_LOTS:
      return {
        ...state,
        lots: {
          ...state.lots,
          data: state.lots.data,
          sortBy: state.lots.sortBy,
          filters: {
            ...state.lots.filters,
            [action.filterType]: {
              min: action.filterData[0],
              max: action.filterData[1],
            },
          },
        },
      }
    case FILTER_LOTS_CLEAR:
      return {
        ...state,
        lots: {
          ...state.lots,
          data: state.lots.data,
          filters: {
            ...initialState.lots.filters,
          },
        },
      }
    case SORT_HOUSES:
      return {
        ...state,
        houses: {
          ...state.houses,
          sortBy: action.sortBy,
        },
      }
    case SORT_LOTS:
      return {
        ...state,
        lots: {
          ...state.lots,
          sortBy: action.sortBy,
        },
      }
    case FILTER_HOUSES:
      let filterData =
        action.filterType === 'area' || action.filterType === 'price'
          ? {
              min: action.filterData[0],
              max: action.filterData[1],
            }
          : action.filterData
      return {
        ...state,
        houses: {
          data: state.houses.data,
          sortBy: state.houses.sortBy,
          filters: {
            ...state.houses.filters,
            [action.filterType]: filterData,
          },
        },
      }
    case FILTER_HOUSES_CLEAR:
      return {
        ...state,
        houses: {
          data: state.houses.data,
          filters: {
            ...initialState.houses.filters,
          },
        },
      }
    case SHOW_TOAST:
      return {
        ...state,
        toast: {
          show: true,
          body: action.payload.body || initialState.toast.body,
          type: action.payload.type || initialState.toast.type,
        },
      }
    case HIDE_TOAST:
      return {
        ...state,
        toast: {
          show: false,
        },
      }
    case EMAIL_ERROR:
      return {
        ...state,
        emailError: action.message || 'Error submitting form. Please verify all fields',
      }
    case EMAIL_ERROR_RESET:
      return {
        ...state,
        emailError: false,
      }
    case SHOW_LOT_POPUP:
      const selectedStage = state.stages.data.find((stage) => stage.id === action.lot?.stage?.id)
      return {
        ...state,
        selectedStage,
        lotPopup: action.lot,
      }
    case SHOW_POINT_POPUP:
      return {
        ...state,
        pointPopup: action.data,
      }
    case HIDE_LOT_POPUP:
      return {
        ...state,
        lotPopup: null,
      }
    // MAP CASES
    case MAP_SET:
      return {
        ...state,
        map: {
          ...state.map,
          map: action.map,
        },
      }
    case SET_CACHED_PACKAGES:
      return {
        ...state,
        cachedPackages: {
          ...state.cachedPackages,
          [action.id]: action.data,
        },
      }
    case SIGNIN_SALES:
      return {
        ...state,
        activeTab: 'internalPois',
        auth: {
          token: action.token,
          isSalesPersonLogged: action.isSalesPersonLogged,
        },
      }
    case SIGNOUT_SALES:
      return {
        ...state,
        auth: action.signOutSalesVersion
          ? undefined
          : { ...state.auth, isSalesPersonLogged: false },
      }
    case SET_SELECTED_STAGE:
      return {
        ...state,
        selectedStage: action.selectedStage,
        ...(action.selectedStage && !isMobile() ? { contentPaneVisible: true } : {}),
      }
    case SET_PDF_URL:
      return {
        ...state,
        pdfUrl: action.pdfUrl,
      }
    case SET_AGREEMENT_MODAL_TYPE:
      return {
        ...state,
        agreementModalType: action.agreementModalType,
      }
    default:
      return state
  }
}
