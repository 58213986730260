import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import mapboxgl from 'mapbox-gl'
import { useSpring, animated, config } from 'react-spring'
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons'
import './Nearby.css'
import {
  getGeoPoints,
  getAllNearbyPoints,
  getCategorizedNearbyPoints,
  getMap,
  getSelectedPoi,
  isSalesVersion,
  getMapPadding,
  getProject,
} from '../../redux/selectors'
import {
  showNearby,
  showNearbyAll,
  hideNearbyAll,
  attachNearbyPointsToTheMap,
} from '../../mapLayers/points'
import {
  setPointsInitialized,
  setSelectedPoi,
  hideRouteToPoint,
  setMapPaddingRefs,
  setToggleButtonVisibility,
} from '../../redux/actions'
import { isMobile, renderAuxNavigation, renderStandaloneCard, rsz } from '../../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'
import ExpandedPoiList from './ExpandedPoiList'
import Poi from './Poi'

const S = {
  Square: styled.div`
    width: 50px;
    margin: 50px;
    height: 1724px;
    background: red;
  `,
  ExpandMarker: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
        display &&
        css`
          display: flex;
          position: absolute;
          top: -12px;
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          div {
            display: flex;
            color: #333;
            box-shadow: 0 12px 33px -8px #2b2b2b;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            background: #fff;
            justify-content: center;
            align-items: center;
          }
        `}

      ${({ expanded }) =>
        expanded &&
        css`
          position: fixed;
          top: 0px;
          width: 100vw;
          z-index: 100;
          padding-bottom: 40px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          div {
            margin-top: 0.9rem;
          }
        `}
    }
  `,
  PoiCard: styled(animated.div)`
    width: 100vw;
    position: absolute;
    left: 0;
    background: #fff;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  PoiWrapper: styled(animated.div)`
    min-height: calc(50vh - 88px);
    min-height: calc(var(--vh, 1vh) * 50 - 88px);
    ${({ expanded }) =>
      expanded &&
      css`
        height: calc(100vh - 88px);
        height: calc(var(--vh, 1vh) * 100 - 88px);
        overflow-y: scroll;
      `}
  `,
  Container: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: calc(100vh - 88px);
      height: calc(var(--vh, 1vh) * 100 - 88px);
      background: #f1f5fa;
      overflow-y: scroll;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 320px;
        //min-height: 280px;
        overflow-x: scroll;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(2, 2, 3, 0.76)),
          to(rgba(16, 18, 32, 0.59))
        );
        background-image: linear-gradient(180deg, rgba(2, 2, 3, 0.76), rgba(16, 18, 32, 0.59));
        background-position: 0px 0px, 50% 50%;
        background-size: auto, cover;

        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }
      `}
  `,
  CategoryListWrapper: styled.div`
    min-width: fit-content;
    width: 100%;

    &::-webkit-scrollbar-thumb {
      height: 1rem;
      border-radius: 0.5rem;
      background: rgba(100, 100, 100, 0.8);
    }

    &::-webkit-scrollbar {
      visibility: none;
      height: 1rem;
      width: 1rem;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      visibility: none;
      height: 1rem;
      width: 1rem;
    }
  `,
  TopRef: styled.div`
    margin-top: -1rem;
    width: 1px;
    height: 1px;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 0;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 0rem;
        display: none;
      `}
  `,
  BlurredBackground: styled.div`
    position: absolute;
    left: 0px;
    top: 0%;
    right: 0%;
    bottom: 0px;
    overflow: hidden;
    width: 100%;
    height: 34vh;
    margin-top: 7.3rem;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 0;
    }
    ${({ theme }) =>
      theme.isEmbeddedMode &&
      css`
        @media screen and (max-width: 991px) {
          margin-top: 0rem;
        }
      `}
    padding-top: 0rem;
    -o-object-fit: fill;
    object-fit: fill;
    img {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    div {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(46%, hsla(0, 0%, 100%, 0)),
          color-stop(84%, #f3f6fb)
        ),
        -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.66)), color-stop(35%, hsla(0, 0%, 100%, 0)));
      background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 46%, #f3f6fb 84%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.66), hsla(0, 0%, 100%, 0) 35%);
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: none;
      `}
  `,
  NearbyContainer: styled.div`
    display: flex;
    padding-top: 1rem;
    //margin-top: 1.5rem;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      min-height: calc(100vh - 88px);
      min-height: calc(var(--vh, 1vh) * 100 - 88px);
      margin-top: 0;
      background: #fff;
      ${({ transparent }) =>
        transparent &&
        css`
          background: transparent;
        `}
      padding: 5.2vw;
      padding-bottom: 0;
    }
    p {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      color: rgb(139, 138, 138);
    }
    p.moreCategories {
      margin-top: -0.5rem;
    }
    z-index: 5;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        //padding-top: 1rem;
        padding-bottom: 2rem;
        display: flex;
        flex-wrap: nowrap;
        padding-left: 5vw;
        padding-right: 5vw;
        align-items: center;
        justify-content: center;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
      `}
  `,
  Icon: styled.div`
    position: absolute;
    top: 5px;
  `,
  CardContainer: styled.div`
    position: relative;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 45%;
    height: 12rem;
    margin-bottom: 2.5rem;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 14px 40px -1px rgba(0, 0, 0, 0.39);
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }

    h2 {
      position: relative;
      z-index: 2;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-right: 1rem;
      padding-left: 1rem;
      font-family: alverata, sans-serif;
      color: #fff;
      font-size: 1rem;
      line-height: 1.2;
      font-weight: 700;
      text-decoration: none;
      text-shadow: 1px 1px 12px #000;
      -o-object-fit: fill;
      object-fit: fill;
    }
    img {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: 100%;
      height: 100%;
      opacity: 0.95;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
    }

    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 42vw;
      height: 48vw;
      background: #f1f5fa;
      margin-bottom: 5.2vw;
      h2 {
        font-size: 0.9rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        //width: 7.2vw;
        //height: 7.2vw;
        margin-right: 2.2rem;
        margin-bottom: 0;

        width: 10vw;
        min-width: 200px;
        height: 15vh;
        min-height: 200px;
        border-radius: 15px;
        background-color: transparent;

        h2 {
          font-size: 1.6rem;
          margin-bottom: 1rem;
          padding-left: 1.5rem;
        }
      `}
  `,
  CardContainerGradient: styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(60%, rgba(189, 152, 152, 0)),
      to(rgba(0, 0, 0, 0.45))
    );
    background-image: linear-gradient(180deg, rgba(189, 152, 152, 0) 60%, rgba(0, 0, 0, 0.45));
  `,
  BreadCrumb: styled.div`
    position: relative;
    z-index: 1;
    margin-bottom: 0rem;
    text-decoration: none;
    margin-top: 0rem;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 10px;
      `}

    a {
      display: inline-block;
      padding: 0.2rem 0.7rem;
      border-style: solid;
      border-width: 1px;
      border-color: #9dc1d3;
      border-radius: 100px;
      text-decoration: none;
      color: #fff;
      font-weight: 400;

      ${({ theme }) =>
        theme.isSalesMode &&
        css`
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #cfe1ef;
          border-radius: 100px;
        `}
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-right: 0.5rem;
      a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background: rgba(0, 0, 0, 0.15);
        &.all {
          text-overflow: unset;
          overflow: unset;
        }
        ${({ solidBackground }) =>
          solidBackground &&
          css`
            overflow: hidden;
            color: #444;
            background: #fff;
          `}
      }
    }
  `,
  BreadArrow: styled.img`
    width: 0.8rem;
    margin-right: 0.2rem;
    margin-left: 0.25rem;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  `,
  SecondaryNav: styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
    .MuiSvgIcon-root {
      font-size: 1rem;
      margin-right: 7px;
      margin-bottom: 0;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin: 0;
      margin-top: 0;
      position: fixed;
      z-index: 200;
      width: 88vw;
      top: 5.2vw;
      left: 5.2vw;
    }
  `,
  CategoryLevel: styled.div`
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    ${({ isActive }) =>
      !isActive &&
      css`
        color: #4c4949;
        cursor: default;
      `}
  `,
  All: styled.div`
    font-weight: 400;
    margin-right: 5px;
    cursor: pointer;
  `,
  SelectedPoi: styled.div`
    margin-left: 5px;
    color: #4c4949;
  `,
  BackButton: styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0.2rem 1.5rem 0.2rem 0.7rem;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #5ea5ca;
    color: #fff;
    text-decoration: none;
    img {
      width: 0.7rem;
      height: auto;
      margin-right: 0.3rem;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: max-content;
    }
  `,
}

const BackButton = ({ onClick }) => (
  <S.BackButton onClick={onClick}>
    <img src="/images/back-arrow.svg" alt="" />
    <div>Back</div>
  </S.BackButton>
)

const BlurredBackground = ({ src }) => (
  <S.BlurredBackground>
    <img src={src} />
    <div />
  </S.BlurredBackground>
)

const Nearby = () => {
  const dispatch = useDispatch()
  const container = useRef()
  const map = useSelector(getMap)
  const project = useSelector(getProject)
  const mapPadding = useSelector(getMapPadding)
  const isSalesMode = useSelector(isSalesVersion)
  const { lngLatBounds, zoomLevel: maxZoom } = useSelector(getProject) || {}
  const geoPoints = useSelector(getGeoPoints)
  const categorizedPoints = useSelector(getCategorizedNearbyPoints)
  const allNearbyPoints = useSelector(getAllNearbyPoints)
  const pointsInitialized = useSelector((state) => state.pointsInitialized)
  const [initialized, setInitialized] = useState()
  const selectedPoi = useSelector(getSelectedPoi)
  const [selectedCategory, setselectedCategory] = useState(null)
  const setPoi = (poi) => dispatch(setSelectedPoi(poi))
  const poicardRef = useRef()
  const [expanded, setExpanded] = useState(false)
  const padding = useMemo(
    () => ({
      top: (mapPadding?.top || 0) + 50,
      bottom: (mapPadding?.bottom || 0) + 50,
      left: (mapPadding?.left || 0) + 50,
      right: (mapPadding?.right || 0) + 50,
    }),
    [mapPadding],
  )

  const expandable = isMobile() && !!selectedPoi
  const bottomOffset = 88
  const height = window.innerHeight / 2 - bottomOffset
  const [{ y }, set, stop] = useSpring(() => ({ y: 0 }))
  const open = () => {
    set({
      y: 0 - window.innerHeight + bottomOffset + height,
      immediate: false,
      config: config.stiff,
    })
  }
  const close = () => {
    set({ y: 0, immediate: false, config: config.stiff })
  }
  useEffect(() => {
    if (isMobile()) {
      dispatch(setMapPaddingRefs({ bottom: poicardRef }))
      return function cleanup() {
        dispatch(setMapPaddingRefs({ bottom: null }))
      }
    }
  }, [expandable, poicardRef.current?.offsetHeight])

  useEffect(() => {
    dispatch(setToggleButtonVisibility(true))
    return function () {
      dispatch(setToggleButtonVisibility(false))
    }
  }, [dispatch])

  useEffect(() => {
    setPoi()
    const initialize = async () => {
      await attachNearbyPointsToTheMap(map, categorizedPoints, project, dispatch)
      dispatch(setPointsInitialized())
    }
    if (!pointsInitialized && categorizedPoints?.length) {
      setInitialized(true)
      initialize()
    } else if (categorizedPoints?.length) {
      setInitialized(true)
    }
  }, [categorizedPoints?.length])
  useEffect(() => {
    if (isMobile() && !selectedPoi) {
      dispatch(setToggleButtonVisibility(true))
      close()
      setExpanded(false)
    } else {
      dispatch(setToggleButtonVisibility(false))
    }
  }, [selectedPoi, expandable])
  useEffect(() => {
    if (container.current) {
      container.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
    }
  }, [selectedPoi, selectedCategory])

  useEffect(() => {
    if (!initialized || !pointsInitialized) return
    const bounds = new mapboxgl.LngLatBounds()

    if (selectedPoi) {
      if (selectedPoi.properties.category !== selectedCategory?.id) {
        const categoryObject = categorizedPoints.find(
          (category) => category.id === selectedPoi.properties.category,
        )
        setselectedCategory(categoryObject)
      }
      const coords = selectedPoi.geometry.coordinates
      map.fitBounds([coords, coords], {
        pitch: 0,
        bearing: 0,
        zoom: 18,
        maxZoom: 18,
        minZoom: 18,
        easing: function (t) {
          return 1 - Math.pow(1 - t, 5)
        },
        duration: 1500,
        padding: isMobile() ? { bottom: height } : padding,
      })
      return
    } else if (selectedCategory) {
      hideNearbyAll(map, categorizedPoints)
      showNearby(map, [selectedCategory.id])
      const fitTo = geoPoints.filter(
        (point) =>
          point.properties.type === 'NEARBY' && point.properties.category === selectedCategory.id,
      )

      if (!fitTo || !fitTo.length) return
      fitTo.forEach((point) => bounds.extend(point.geometry.coordinates))
    } else {
      if (!allNearbyPoints || !allNearbyPoints.length) return
      showNearbyAll(map, allNearbyPoints)
      allNearbyPoints.forEach((point) => bounds.extend(point.geometry.coordinates))
    }
    bounds.extend(lngLatBounds)
    map.fitBounds(bounds, { padding, maxZoom })
  }, [
    pointsInitialized,
    initialized,
    geoPoints,
    selectedPoi,
    selectedCategory,
    categorizedPoints,
    allNearbyPoints,
    padding,
    lngLatBounds,
    maxZoom,
  ])

  const handleBackClick = () => {
    if (selectedPoi) {
      setPoi(null)
    } else clearSelection()
  }

  const clearSelection = () => {
    dispatch(hideRouteToPoint())
    setPoi(null)
    setselectedCategory(null)
  }

  const handleCategoryClick = (category) => {
    dispatch(hideRouteToPoint())
    setPoi(null)
    setselectedCategory(category)
  }

  const BreadCrumb = ({ solidBackground }) => (
    <S.SecondaryNav>
      <S.BreadCrumb solidBackground={solidBackground}>
        <a onClick={clearSelection} className="all">
          All
        </a>
        <S.BreadArrow src="/images/back-arrow.svg" />
        <a onClick={() => handleCategoryClick(selectedCategory)}>{selectedCategory?.name}</a>
      </S.BreadCrumb>
      <BackButton onClick={handleBackClick} />
    </S.SecondaryNav>
  )
  if (selectedPoi && isSalesMode)
    return (
      <>
        <Poi item={selectedPoi} />
        {renderAuxNavigation(<BreadCrumb />)}
      </>
    )
  if (expandable) {
    return renderStandaloneCard(
      <>
        <S.PoiCard
          ref={poicardRef}
          expanded={expanded}
          style={{ top: `calc(var(--vh, 1vh) * 100 - 88px - ${height}px)`, y }}
        >
          <S.PoiWrapper expanded={expanded}>
            <Poi item={selectedPoi} />
          </S.PoiWrapper>
          <S.ExpandMarker
            onClick={() => {
              if (expanded) {
                close()
                setTimeout(() => setExpanded(false), 100)
              } else {
                setExpanded(true)
                setTimeout(open, 100)
              }
            }}
            expanded={expanded}
            display={poicardRef.current?.offsetHeight > height}
          >
            <div>
              {expanded ? <ExpandMoreRounded size="large" /> : <ExpandLessRounded size="large" />}
            </div>
          </S.ExpandMarker>
        </S.PoiCard>
        <S.SecondaryNav style={{ width: 'min-content' }}>
          <BackButton onClick={handleBackClick} />
        </S.SecondaryNav>
      </>,
    )
  }
  if (isSalesMode && !selectedCategory?.id) {
    return (
      <S.Container className="outer" style={{ justifyContent: 'initial' }}>
        <S.TopRef ref={container} />
        <S.CategoryListWrapper>
          <S.NearbyContainer transparent={true} className="inner">
            {selectedCategory?.id && <p className="moreCategories">Other Categories</p>}
            {categorizedPoints
              .filter((category) => category.id != selectedCategory?.id)
              .map((category) => (
                <S.CardContainer key={category.id} onClick={() => handleCategoryClick(category)}>
                  <h2>{category.name}</h2>
                  <img
                    src={rsz(category.imageUnselected?.url, {
                      width: 600,
                      height: 600,
                      crop: 'fill',
                      quality: 'auto',
                    })}
                    name={category.name}
                    alt={category.name}
                  />
                  <S.CardContainerGradient />
                </S.CardContainer>
              ))}
          </S.NearbyContainer>
        </S.CategoryListWrapper>
      </S.Container>
    )
  }

  return (
    <>
      <S.Container>
        <S.TopRef ref={container} />
        {selectedCategory && (
          <div>
            <BlurredBackground
              src={rsz(
                selectedPoi ? selectedPoi.properties.facade : selectedCategory.imageUnselected?.url,
                {
                  width: 500,
                  crop: 'fill',
                  quality: 'auto',
                },
              )}
            />

            {isSalesMode ? renderAuxNavigation(<BreadCrumb />) : <BreadCrumb />}

            {selectedPoi && !isSalesMode ? (
              <Poi item={selectedPoi} />
            ) : (
              <ExpandedPoiList
                category={selectedCategory}
                setSelectedPoi={setPoi}
                selectedPoi={selectedPoi}
              />
            )}
          </div>
        )}
        {selectedPoi && selectedCategory && !isSalesMode ? (
          <>
            <S.NearbyContainer>
              <p className="morePois">Related POIs</p>
              {selectedCategory.items
                .filter((item) => item.id != selectedPoi.id)
                .map((item) => (
                  <S.CardContainer key={item.id} onClick={() => setPoi(item)}>
                    <h2>{item.properties.name}</h2>
                    <img src={rsz(item.properties.facade, {
                        width: 400,
                        crop: 'fill',
                        quality: 'auto',
                      })} alt={item.properties.name} />
                    <S.CardContainerGradient />
                  </S.CardContainer>
                ))}
            </S.NearbyContainer>
          </>
        ) : isSalesMode && selectedCategory?.id ? null : (
          <S.NearbyContainer transparent={true}>
            {selectedCategory?.id && <p className="moreCategories">Other Categories</p>}
            {categorizedPoints
              .filter((category) => category.id != selectedCategory?.id)
              .map((category) => (
                <S.CardContainer key={category.id} onClick={() => handleCategoryClick(category)}>
                  <h2>{category.name}</h2>
                  <img
                    src={rsz(category.imageUnselected?.url, {
                      width: 300,
                      height: 300,
                      crop: 'fill',
                      quality: 'auto',
                    })}
                    name={category.name}
                    alt={category.name}
                  />
                  <S.CardContainerGradient />
                </S.CardContainer>
              ))}
          </S.NearbyContainer>
        )}
      </S.Container>
    </>
  )
}

export default Nearby
