import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getMap } from '../redux/selectors'

const WebGL = () => {
  const map = useSelector(getMap)

  useEffect(() => {}, [])

  if (!map) {
    return null
  }

  map.on('load', () => {
    // TODO add here all the logic related to 3D models
    map.on('click', function (e) {
      // TODO remove
    })
  })

  return null
}

export default WebGL
