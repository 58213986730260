import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha"
import TagManager from 'react-gtm-module'
import _ from 'lodash'
import Card from '@material-ui/core/Card'
import Checkbox from '@material-ui/core/Checkbox'
import CardContent from '@material-ui/core/CardContent'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Status from './Status'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import Api from '../redux/api'
import { emailValidator } from '../utils/Validate'

import {
  showToast,
  emailErrorReset,
  addLotToFavourites,
  addPackageToFavourites,
  setAgreementModalType,
  setMapScaleVisibility
} from '../redux/actions'
import {
  emailError,
  getFavouriteLots,
  getFavouritePackages,
  isSalesVersion,
  getHouses,
  getProject,
} from '../redux/selectors'
import { formatPrice, renderAuxNavigation } from '../utils/functions'
import { VirtualKeyboardContext } from './VirtualKeyboard'
import TextField from '@material-ui/core/TextField'
import BackButton from '../reusable/BackButton'
import CustomSelect from '../reusable/CustomSelect'

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    marginTop: '1.5rem',
    paddingBottom: '0.75rem',
    paddingRight: 0,
    paddingLeft: 0,
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: '#C7C7C7',
    fontSize: '0.85rem',
    color: '#333',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}))(InputBase)

const CustomInputSales = withStyles((theme) => ({
  input: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginRight: 0,
    backgroundColor: 'white',
    borderRadius: '100px',
    borderColor: 'transparent',
    fontSize: '0.8rem',
    fontFamily: 'Karla, sans-serif',
    '&.MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '100px',
      borderColor: 'transparent',
    },
  },
}))(InputBase)

const S = {
  Card: styled(Card)`
    &&& {
      .MuiCardContent-root {
        padding: 0;
      }
      margin-top: 1vh;
      margin-bottom: 1vh;
      padding: 3rem 2rem;
      height: auto;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.15);
      @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
        margin-top: 0.5rem;
        margin-bottom: 0;
        min-height: calc(100% - 0.5rem);
      }
      ${({ theme }) =>
      theme.isSalesMode &&
      css`
          color: white;
          padding: 0;
          background-color: transparent;
          box-shadow: initial !important;
          overflow: visible;
        `}
    }
  `,
  CustomSelectWrapper: styled.div`
    position: 'relative';
    width: 100%;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: initial;
        display: inline-flex;
        &&& {
          .MuiFormControl-root {
            width: 14rem;

            margin-bottom: 0;
            bottom: 2px;
            .Mui-error {
              .MuiInputBase-input {
                border-color: red;
                border-width: 2px !important;
              }
            }
          }
        }
      `}
    &&& {
      .MuiFormControl-root {
        height: 65px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        .Mui-error {
          .MuiInputBase-input {
            border-bottom-color: red;
            border-bottom-width: 2px !important;
          }
        }
      }
    }
  `,
  SuburbPicker: styled.div`
    position: absolute;
    background: white;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 12px 33px -10px #2b2b2b;
    padding: 10px;
    z-index: 10;
    div.option {
      cursor: pointer;
      padding: 10px 0 0 0;
    }
    div.option:hover {
      background: #dedede;
    }
  
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        color: #333;
      `}
  `,  
  SuburbContainer: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: 100%;
      overflow-y: scroll;
    }
  `,
  LotModal: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: 100%;
      overflow-y: scroll;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
  `,
  LotHeader: styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  CloseModal: styled.div`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    box-shadow: 0px 5px 10px #dde0d3;
    border: 1px solid #f8f8f8;
    cursor: pointer;
  `,
  LotStatus: styled.div`
    display: flex;
    margin: 20px 0px 10px 0px;
  `,
  FavoriteIcon: styled(FavoriteIcon)`
    color: rgb(243, 104, 39);
  `,
  FavoriteBorderIcon: styled(FavoriteBorderIcon)`
    color: rgb(243, 104, 39);
  `,
  StatusContainer: styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
  `,
  FavoriteIconContainer: styled.div`
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      height: 20px;
    }
  `,
  Status: styled(Status)`
    margin-right: 5px;
  `,
  LotDetails: styled.div`
    display: flex;
    justify-content: space-between;
    p {
      color: #c7c7c7;
      display: inline;
    }
  `,
  Thanks: styled.div`
    h2 {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      font-family: Freighttext, Georgia, sans-serif;
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1;
    }
    p {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
  `,
  Error: styled.div`
    font-size: 0.9rem;
    margin-top: 1rem;
    color: red;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        color: white;
      `}
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    ${({ theme }) =>
    theme.isSalesMode &&
    css`
      max-width: 792px;
      .MuiFormHelperText-root {
        color: white !important;
      }
    `}

    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    h2 {
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-family: Freighttext, Georgia, sans-serif;
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1;
    }
    p.soldOut {
      font-size: 0.9rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    p.agreementNotice {
      display: flex;
      margin-top: 0;
      align-items: center;
      .MuiCheckbox-root {
        margin-left: -9px;
        ${({ theme }) =>
        theme.isSalesMode &&
        css`
          color: white;
        `}
      }
      a {
        text-decoration: underline !important;
      }
    }
    div.group {
      display: flex;
      flex-direction: column;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        div.group {
          flex-direction: row;
          width: initial;
          position: relative;
        }
        .MuiInput-underline:before {
          border-bottom: none !important;
        }
        .MuiInput-underline.Mui-focused:after {
          transform: initial;
        }
        .MuiInput-underline:after {
          transform: initial;
          border-bottom: none !important;
        }
        &.MuiInput-underline:before {
          border-bottom: none !important;
        }

        .Mui-error .MuiInputBase-input {
          border-color: red;
          border-width: 2px;
        }

        .MuiInputAdornment-root {
          position: absolute;
          right: 1.5rem;
          bottom: 1rem;
        }

        .MuiFormHelperText-root {
          margin-left: 1rem;
        }        
      `}

    input {
      margin-top: 1.5rem;
      padding-bottom: 0.75rem;
      padding-right: 0px;
      padding-left: 0px;
      border-style: none none solid;
      border-width: 1px;
      border-color: #000 #000 #e7e7e7;
      font-size: 0.85rem;
      color: #333;

      &::placeholder {
        color: #333;
        opacity: 1;
      }
      ${({ theme }) =>
      theme.isSalesMode &&
      css`
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 0.8rem;
          padding-right: 1rem;
          padding-left: 1rem;
          margin-right: 1rem;
          background-color: white;
          border-radius: 100px;
          border-color: transparent;
          width: 12rem;
          margin-top: 1.5rem;
        `}
    }

    p {
      font-size: 0.7rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
  `,
  Button: styled.button`
    background: #fff9a3;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    box-shadow: 0px 5px 10px #dde0d3;

    &:hover {
      background: #eee892;
    }
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        background: #d3d3cf;
        &:hover {
          background: #c2c2be;
        }
      `}
  `,
  Enquire: styled.div`
    width: 100%;
    margin-top: 0;
    margin-bottm: 2vh;
    border-radius: 100px;
    background-color: #ffb899;
    box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.33);
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-block;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    text-align: center;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 1rem;
        background-color: #4a9bc0;
      `}
  `,
}
const PropertyModalv2 = ({ lotId, hideBackButton, handleClose, title: overrideTitle, subtitle, handleSave, type, packageId }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    postCode: '',
    howDidYouHearAboutUs: '',
    agreement: false
  })
  const virtualKeyboard = useContext(VirtualKeyboardContext)
  const [error, setError] = useState()
  const recaptchaRef = useRef()
  const postCodeRef = useRef()
  const [suburbPickerStyle, setSuburbPickerStyle] = useState({})
  const [suburbLoading, setSuburbLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [suburbOptions, setSuburbOptions] = useState([])
  const [suburb, setSuburb] = useState()
  
  const project = useSelector(getProject)
  const formRef = useRef()
  const hasEmailError = useSelector(emailError)
  const isSalesMode = useSelector(isSalesVersion)
  const favouriteLots = useSelector(getFavouriteLots)
  const favouritePackages = useSelector(getFavouritePackages)
  const houses = useSelector(getHouses)
  const lots = useSelector((state) => state.lots.data)

  let title = overrideTitle || (type === 'favourites' ? 'Save Favourites' : 'Enquire')
  if (packageId) {
    const hPackage = houses.find((p) => p.id === packageId)
    if (hPackage?.houseType) title = hPackage.houseType.name + ' Enquiry'
  } else if (lotId) {
    const lot = lots.find((l) => l.id === lotId)
    if (lot) title = 'Lot ' + lot.humanId + ' Enquiry'
  }

  useEffect(() => {
    virtualKeyboard.setVisible(true)
    dispatch(setMapScaleVisibility(false))

    const getRectsInterval = setInterval(() => {
      if (!postCodeRef.current)
        return;
      const containerRect = postCodeRef.current.getBoundingClientRect();
      const newStyle = {
        top: -10 + (isSalesMode ? 0 : containerRect.top) + containerRect.height,
        left: isSalesMode ? containerRect.width : containerRect.left,
      }
      if (JSON.stringify(newStyle) !== JSON.stringify(suburbPickerStyle)) {
        setSuburbPickerStyle(newStyle)
      }
    }, 500);


    return function cleanup() {
      dispatch(setMapScaleVisibility(true))
      virtualKeyboard.setVisible(false)
      clearInterval(getRectsInterval)
    }
  }, [dispatch])

  const onPickSuburb = (e) => {
    suburbOptions.forEach(s => {
      if (+s.id === +e.target.id) {
        setSuburb(s)
        setFormData({
          ...formData,
          postCode: s.postcode
        })
      }
        
    })
  }

  const checkFieldsAreFilled = () => {
    let filled = false
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.mobilePhone &&
      formData.postCode &&
      formData.howDidYouHearAboutUs &&
      suburb
    ) {
      filled = true
    }
    return filled
  }
  const isBtnDisabled = () => {
    let isBtnDisabled = true
    if (checkFieldsAreFilled() && emailValidator.test(formData.email)) {
      isBtnDisabled = false
    } else {
      isBtnDisabled = true
    }
    return setIsDisabled(isBtnDisabled)
  }
  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      howDidYouHearAboutUs: value,
    })
  }
  const handleInputValueChange = (e) => {
    const { value, name } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleSearchDebounce = _.debounce(
    (v) => {
      searchPostcode(v)
    },
    300, { trailing: true }
  )
  const handlePostcodeChange = (e) => {
    const { value } = e.target
    setFormData({
      ...formData,
      postCode: value,
    })
    if (value.length >= 4) {
      setSuburbOptions([])
      setSuburb()
      handleSearchDebounce(value)
    } else if (+suburb?.postcode !== +value) {
      setSuburb();
      setSuburbOptions([])
    }
  }
  const handleAgreementClick = () => {
    setFormData({ ...formData, agreement: !formData.agreement });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError()
    setIsFormTouched(true)
    if (checkFieldsAreFilled() === false) {
      setError('All fields must be filled in')
    } else if (checkFieldsAreFilled() && emailValidator.test(formData.email) === false) {
      setError('Please enter a valid email')
    } else if (!formData.agreement) {
      setError('Please aknowledge that you have agreed to the privacy policy and terms by checking the box above.')
    } else {
      let captchaToken
      try {
        captchaToken = await recaptchaRef.current.executeAsync();
      } catch (e) {
        console.log("error obtaining token", e)
      }
      if (!captchaToken)
        return setError('Please complete the CAPTCHA challenge before progressing')
      
      const payload = {
        ...formData,
        packageId,
        lotId,
        captchaToken,
        suburb: suburb?.location,
        state: suburb?.state
      }


      if (packageId) {
        const isInFavourite = favouritePackages.findIndex((p) => p.id === packageId) >= 0
        if (!isInFavourite) {
          dispatch(addPackageToFavourites(packageId))
        }
      } else if (lotId) {
        const isInFavourite = favouriteLots.findIndex((p) => p.id === lotId) >= 0
        if (!isInFavourite) {
          dispatch(addLotToFavourites(lotId))
        }
      }
      if (handleSave) {
        handleSave(payload)
      } else {
        dispatch(Api.postEnquireForm(payload))
      }
      if (project.gTag) {
        try {
          const tagManagerArgs = {
            dataLayer: {
                event: 'HLAppFormSubmitted', // event to be picked up by GTM
            }
          }
          
          TagManager.dataLayer(tagManagerArgs);
        } catch (e) {
          console.log('*** error setting gTag', e)
        }
      } else {
        console.log('*** no gTag found for project', project.id)
      }
      if (project.reaTag) {
        try {
          console.log('*** track REA tag', )
          window.REA.track_conversion(project.reaTag)
          console.log('*** finish track REA')
        } catch (e) {
          console.log('*** error tracking REA', e)
        }
      } else {
        console.log('*** no REA tag found for project', project.id)
      }      
      setTimeout(() => {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          postCode: '',
          howDidYouHearAboutUs: '',
        })
        setSuburb()
      }, 2000)

      if (!handleSave) {
        if (hasEmailError) {
          setError(hasEmailError)
          dispatch(emailErrorReset())
        } else {
          setIsSubmitted(true)
          formRef.current && formRef.current.reset()
        }
      }
    }
  }

  useEffect(() => {
    isBtnDisabled()
  })

  const saveText = type === 'favourites' ? 'Send' : 'Enquire'

  const focusKeyboard = (e) => {
    virtualKeyboard.setActiveInput({
      ref: e.target,
      onChange: handleInputValueChange,
    })
  }
  const blurKeyboard = () => virtualKeyboard.setActiveInput()
  const searchPostcode = async (value) => {
    setSuburbLoading(true)
    try {
      const r = await Api.fetchPostCodeSuburbs(value)
      if (r?.localities?.locality && r?.localities?.locality.length) {
        if (r?.localities?.locality.length === 1)
          setSuburb(r?.localities?.locality[0])
        else
          setSuburbOptions(r?.localities?.locality)
      } else {
        dispatch(
          showToast({
            body: 'Please enter a valid AU post code.',
            type: 'error',
          }),
        )        
      }
    } catch (e) {
      dispatch(
        showToast({
          body: e?.response?.data?.error?.errorMessage ||
            'We couldn\'t fetch suburbs for this post code. Please try again later.',
          type: 'error',
        }),
      )
    }
    setSuburbLoading(false)
  }

  const popup = (e) => {
    e.preventDefault()
    dispatch(setAgreementModalType('privacy'))
  }

  return (
    <S.LotModal>
      {!isSalesMode && !hideBackButton && <BackButton onClickHandler={handleClose}></BackButton>}
      <S.Card>
        <CardContent>
          {(isSubmitted && !hasEmailError) ? (
            <S.Thanks>
              <h2>Thank you</h2>
              <p>Your enquiry has been received.</p>
            </S.Thanks>
          ) : (
            <S.Form ref={formRef}>
              <h2>{title}</h2>
              {subtitle && (<p className="soldOut">{subtitle}</p>)}
              <p>* All fields required</p>
              <div className="group">
                <TextField
                  placeholder="First name"
                  name="firstName"
                  onChange={handleInputValueChange}
                  autoFocus={true}
                  onFocus={focusKeyboard}
                  onBlur={blurKeyboard}
                  value={formData.firstName}
                  error={isFormTouched && !formData.firstName}
                />
                <TextField
                  placeholder="Last name"
                  name="lastName"
                  onChange={handleInputValueChange}
                  onFocus={focusKeyboard}
                  onBlur={blurKeyboard}
                  value={formData.lastName}
                  error={isFormTouched && !formData.lastName}
                />
                <TextField
                  placeholder="Email"
                  name="email"
                  onChange={handleInputValueChange}
                  onFocus={focusKeyboard}
                  onBlur={blurKeyboard}
                  type={isSalesMode ? 'text' : 'email'}
                  value={formData.email}
                  error={isFormTouched && (!formData.email || !emailValidator.test(formData.email))}
                />
              </div>
              <div className="group">
                <TextField
                  placeholder="Mobile phone"
                  name="mobilePhone"
                  onChange={handleInputValueChange}
                  onFocus={focusKeyboard}
                  onBlur={blurKeyboard}
                  type={isSalesMode ? 'text' : 'number'}
                  value={formData.mobilePhone}
                  error={isFormTouched && !formData.mobilePhone}
                />
                <TextField
                  placeholder="Post code"
                  name="postCode"
                  onChange={handlePostcodeChange}
                  onFocus={focusKeyboard}
                  onBlur={blurKeyboard}
                  type={isSalesMode ? 'text' : 'number'}
                  value={formData.postCode}
                  error={isFormTouched && (!formData.postCode || !suburb)}
                  autocomplete="off"
                  ref={postCodeRef}
                  helperText={suburb ? (suburb.location +', '+ suburb.state) : formData.postCode ? 'A valid AU postcode is required' : null}
                />
                {
                  !suburb && (suburbOptions?.length || suburbLoading) ? (
                      <S.SuburbPicker style={suburbPickerStyle}>
                        { suburbLoading ? (
                          <em>Loading suburbs...</em>
                        ) : (
                          <>
                            Please pick your suburb:<br />
                          {
                            suburbOptions.map(s => (
                              <div 
                                key={s.id}
                                id={s.id}
                                className="option" 
                                onClick={onPickSuburb}>
                                  {s.location}, {s.state}
                                </div>
                            ))
                          }                           
                          </>
                        )}

                    </S.SuburbPicker>
                  ) : null
                }
                <CustomSelect
                  noShrinkLabel={true}
                  data={
                    (project?.frontendEnquirySources || []).map(({name, value}) => ({
                      value, label: name
                    }))
                  }
                  emptyLabel="How did you hear about us?"
                  value={formData.howDidYouHearAboutUs}
                  onAction={handleSelectChange}
                  CustomWrapper={S.CustomSelectWrapper}
                  CustomInput={isSalesMode ? CustomInputSales : CustomInput}
                  error={isFormTouched && !formData.howDidYouHearAboutUs}
                />
              </div>
              <p className="agreementNotice">
                <Checkbox
                  size="small"
                  color="primary"
                  checked={formData.agreement}
                  name="agreement"
                  onClick={handleAgreementClick}
                  required={true}
                />
                <div>
                  By checking this box I acknowledge that I have read the{' '}
                  <a href={project.privacyPolicyUrl} target="_blank">
                    privacy policy
                  </a>{' '}
                  and agree to receive marketing messages to the email and/or phone number provided.
                </div>
              </p>

              <S.Error>{
                error || hasEmailError
              }</S.Error>

              <S.Enquire isDisabled={isDisabled} onClick={handleSubmit}>
                {saveText}
              </S.Enquire>
              
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                style={isSalesMode ? {position: 'absolute', bottom: '3rem', left: 15} : { alignSelf: 'center', margin: 20 }}
                op
                badge={isSalesMode ? 'bottomleft' : 'bottomright'}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY.replace(/"/g, '')}
              />

            </S.Form>
          )}
        </CardContent>
      </S.Card>
      {isSalesMode && !hideBackButton && renderAuxNavigation(<BackButton onClickHandler={handleClose} />)}
    </S.LotModal>
  )
}

export default PropertyModalv2
