import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import mapboxgl from 'mapbox-gl'
import CustomSlider from '../CustomSlider'
import {
  filterLots,
  clearFilterLots,
  clearFilterHouses,
  sortLots,
  hideLotPopup,
  setToggleButtonVisibility,
} from '../../redux/actions'
import PropertyModalv2 from '../PropertyModalv2'
import {
  getLotsGroupedByStages,
  getMap,
  isSalesVersion,
  isSalesPersonLogged,
  getLotsSortBy,
  getMapPadding,
  getAvailableLotsCoordinates,
  getCategorizedOnsitePoints
} from '../../redux/selectors'
import {
  getCenter,
  formatPrice,
  renderAuxNavigation,
  vh,
  isMobile,
  renderStandaloneCard,
  getLotMinZoom,
  getLotsBoundaries,
} from '../../utils/functions'
import TuneIcon from '@material-ui/icons/Tune'
import CloseIcon from '@material-ui/icons/Close'
import LotCard from '../LotCard'
import BackButton from '../../reusable/BackButton'
import StagesWrapper from './components/StagesWrapper'
import CustomSelect from '../../reusable/CustomSelect'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'
import { showOnsiteAll } from '../../mapLayers/pointsInternal'

const S = {
  FilterButton: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      box-shadow: 0 12px 33px -8px #2b2b2b;
      border-radius: 24px;
      width: 48px;
      height: 48px;
      position: absolute;
      z-index: 102;
      top: 1rem;
      right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ display }) =>
      display === 'true'
        ? css`
              color: white;
              background-color: #5b9abb;
              border-radius: 20px;
              width: 40px;
              height: 40px;
            `
        : css`
              background-color: #6faed7;
              color: #fff;
              border: 2px solid #fff;
            `}
    }
  `,
  ButtonSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DetailContainer: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      &.enquiry {
        height: 100%;
        .main {
          height: 100%;
        }
      }
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        background-color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0.75)),
          to(rgba(0, 0, 0, 0.49))
        );
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
        padding-bottom: 0;
        &.lot {
          //height: 350px;
          //min-height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 2rem;
          padding-top: 1em;
          min-width: fit-content;
        }

        .main {
          display: block;
          justify-content: center;
          align-items: center;
          margin-left: 10vw;
          margin-right: 10vw;
        }
        .backContainer {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
          margin-top: -1.5rem;
        }
      `}
  `,
  ButtonsWrapper: styled.div`
    position: relative;
    display: flex;
    width: 75%;
    height: 2.2rem;
    justify-content: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #f5faff;
  `,
  Button: styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  ButtonText: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    color: #443f3f;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  ButtonTextSelected: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  Filters: styled.div`
    display: flex;
    justify-content: space-between;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: 15vw;
        margin-right: 15px;
        margin-bottom: 25px;
        margin-left: 50px;
      `}
  `,
  CustomSelect: styled(CustomSelect)`
    margin-bottom: 2.5rem;
  `,
  SortClear: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: 20vw;
        min-width: 400px;
        max-width: 800px;
        justify-content: flex-start;
        margin-left: 2vw;
        a {
          background-color: #f2f3f9;
        }
      `}
  `,
  ButtonSelected: styled.div`
    position: absolute;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    ${({ selected }) =>
      selected === 'landForSale' &&
      css`
        left: 0;
      `}
  `,
  FilterOverlay: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
      display === 'true'
        ? css`
              z-index: 100;
              width: 100vw;
              height: calc(100vh - 88px);
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.6);
            `
        : css`
              display: none;
            `}
    }
  `,
  SliderSection: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
      display === 'true'
        ? css`
              position: absolute;
              background: #fff;
              display: unset;
              border-radius: 15px;
              margin: 0.6rem;
              z-index: 100;
              top: 0;
              left: 0;
              padding: 1rem;
              width: calc(100vw - 1.2rem);
            `
        : css`
              display: none;
            `}
    }

    margin-bottom: 2.5rem;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-direction: row;
        background-color: rgba(16, 18, 32, 0.59);
        width: 100vw;
        justify-content: center;
        align-items: center;
        margin-bottom: 0rem;
        .MuiInputBase-input {
          background-color: rgba(19, 21, 43, 0.31) !important;
          border: none !important;
          border-radius: 15px !important;
          padding-left: 30px !important;
          padding-right: 40px !important;
        }
        .MuiInputBase-root {
          color: white !important;
        }
        .MuiSelect-icon {
          color: white !important;
        }
        .MuiSlider-root {
          color: #5a99c2 !important;
        }
        .MuiSlider-thumb {
          background-color: #444e50 !important;
          background-image: url('/images-new/thumb-bg.png') !important;
        }
        h3 {
          color: white !important;
        }
      `}
  `,
  SliderContainer: styled.div`
    display: flex;
  `,
  CategoryContainer: styled.div`
    padding-top: 0rem;
  `,
  CardsContainer: styled.div`
    height: 100%;
    padding-top: 0rem;
    padding-left: 1px;
    padding-right: 1px;

    .package-card:last-child {
      margin-bottom: 2rem;
    }
  `,
  OverflowContainer: styled.div`
    overflow: auto;
  `,
  FilterResults: styled.div`
    font-family: Karla, sans-serif;
    font-weight: 400;
    font-size: 110%;
    color: #89b085;
    margin-top: 0.4rem;
    margin-left: 20px;
    padding: 10px 0 10px 0;
    align-self: center;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 0;
        color: white;
      `}
  `,
  ClearFilterBtn: styled.div`
    font-family: Karla, sans-serif;
    font-weight: 400;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 31%;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: unset
    }
    cursor: pointer;
    align-items: center;
    height: 66px;
    margin-left: 15px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1.5,
      height: 2.6rem;
      color: rgb(51,51,51);
      margin: 0;
      border: 1px solid #bae1f5;      
      border-radius: 100px;
      padding: 10px 12px 10px 12px;
      align-self: flex-end;
      width: 100%;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: initial;
        justify-content: flex-start;
        margin-bottom: 25px;
        a {
          border-radius: 15px;
          white-space: nowrap;
          padding-left: 25px;
          padding-right: 25px;
          font-size: 0.85rem;
          border: 2px solid #7a9196;
          background-color: rgba(19, 21, 43, 0.31) !important;
          color: white;
        }
      `}
  `,
}
const Land = () => {
  const dispatch = useDispatch()
  const isSalesMode = useSelector(isSalesVersion)
  const isSalesPerson = useSelector(isSalesPersonLogged)
  const [lotData, setLotData] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const [lotDetails, setLotDetails] = useState()
  const [showEnquireForm, setShowEnquireForm] = useState()
  const lots = useSelector((state) => state.lots.data)
  const lotsGroupedByStages = useSelector(getLotsGroupedByStages)
  const availableLotsCoordinates = useSelector(getAvailableLotsCoordinates)
  const categorizedPoints = useSelector(getCategorizedOnsitePoints)
  const lotFilters = useSelector((state) => state.lots.filters)
  const lotsSortBy = useSelector(getLotsSortBy)
  const map = useSelector(getMap)
  const lotPopup = useSelector((state) => state.lotPopup)
  const [calculated, setCalculated] = useState(false)
  const isSales = useSelector(isSalesVersion)
  const pointsOnsiteInitialized = useSelector((state) => state.pointsOnsiteInitialized)

  const lotcardRef = useRef()
  const availableLots = lots.filter(
    (lot) =>
      lot.stage?.enabled &&
      lot.status !== 'Sold' &&
      lot.status !== 'Deposited' &&
      lot.status !== 'Settled' &&
      lot.status !== 'Unreleased',
  )

  let resultNumber = lotsGroupedByStages
    .filter((stage) => stage.enabled && stage.lotsData.length)
    .reduce((sum, x) => sum + x.lotsData.length, 0)
  resultNumber = resultNumber === 1 ? `1 result` : `${resultNumber} results`

  useEffect(() => {
    dispatch(clearFilterLots())
    dispatch(clearFilterHouses())
  }, [])
  useEffect(() => {
    if (categorizedPoints?.length && pointsOnsiteInitialized) {
      showOnsiteAll(map, categorizedPoints, 14, 24)
    }
  }, [categorizedPoints, pointsOnsiteInitialized, dispatch])
  useEffect(() => {
    if (!map || (!availableLotsCoordinates?.length && !lots?.length)) return
    const bounds = new mapboxgl.LngLatBounds()
    try {
      if (availableLotsCoordinates?.length)
        availableLotsCoordinates.forEach((coord) => bounds.extend(coord))
      else {
        const lotsBoundaries = getLotsBoundaries(lots)
        lotsBoundaries.forEach((coord) => bounds.extend(coord))
      }        
    } catch (e) {
      console.log('**** err extending bounds', e)
    }

    const ref = setInterval(() => {
      if (map.isStyleLoaded() && map.loaded()) {
        clearInterval(ref)
        const opts = {
          pitch: 0,
          padding: isSales ? { bottom: vh(35) } : undefined,
        }
        if (availableLotsCoordinates?.length)
          opts.zoom = getLotMinZoom() + 1
        map.fitBounds(bounds, opts)
      }
    }, 500)
    return function () {
      clearInterval(ref)
    }
  }, [map, availableLotsCoordinates?.length, lots?.length])
  useEffect(() => {
    if (lotPopup) {
      setLotDetails(lots.find((lot) => lot.id === lotPopup.id))
    } else {
      setLotDetails(null)
    }
  }, [lotPopup, lotData])
  if (!calculated && availableLots.length) {
    const areaMinLotRaw =
      availableLots
        .map((lot) => lot.sqm)
        .reduce((min, next) => (next < min ? next : min), 99999999) || 0
    const areaMinNonZero =
      Math.floor(Math.min(...availableLots.filter((lot) => !!lot.sqm).map((lot) => lot.sqm)) / 10) *
      10
    const areaMinLot = Math.round(Math.floor(areaMinLotRaw / 10) * 10) || areaMinNonZero

    const areaMaxLotRaw = availableLots
      .map((lot) => lot.sqm)
      .reduce((max, next) => (next > max ? next : max), 0)
    const areaMaxLot = Math.round(Math.ceil(areaMaxLotRaw / 10) * 10)

    const frontageMinLotRaw =
      availableLots
        .map((lot) => lot.dimensions?.width)
        .reduce((min, next) => (next < min ? next : min), 99999999) || 0
    const frontageMinLot = Math.round(Math.floor(frontageMinLotRaw / 10) * 10)

    const frontageMaxLotRaw = availableLots
      .map((lot) => lot.dimensions?.width)
      .reduce((max, next) => (next > max ? next : max), 0)

    const priceMinLotRaw = Math.min(...availableLots.map((lot) => lot.price))
    const priceMinLotNonZero =
      Math.floor(
        Math.min(...availableLots.filter((lot) => !!lot.price).map((lot) => lot.price)) / 10000,
      ) * 10000
    const priceMinLot = Math.floor(priceMinLotRaw / 10000) * 10000 || priceMinLotNonZero

    const priceMaxLotRaw = Math.max(...availableLots.map((lot) => lot.price))
    const priceMaxLot = Math.ceil(priceMaxLotRaw / 10000) * 10000
    setLotData({
      areaMin: areaMinLot,
      areaMax: areaMaxLot,
      frontageMin: frontageMinLot,
      frontageMax: frontageMaxLotRaw,
      priceMin: priceMinLot,
      priceMax: priceMaxLot,
    })
    setCalculated(true)
  }
  const valuetext = (value) => {
    return `${value}m`
  }
  const dismissLotCard = () => {
    dispatch(hideLotPopup)
    setLotDetails(null)
    
    if (!map || !availableLotsCoordinates?.length) return

    const bounds = new mapboxgl.LngLatBounds()
    availableLotsCoordinates.forEach((coord) => {
      coord && bounds.extend(coord)
    })
    map.fitBounds(bounds, {
      padding: isSalesMode ? { bottom: vh(35) } : undefined,
      pitch: 0,
      bearing: 0,
      easing: function (t) {
        return 1 - Math.pow(1 - t, 5)
      },
      duration: 2500,
    })
  }
  if (lotDetails) {
    const LotDetails = showEnquireForm ? (
      <S.DetailContainer className="enquiry">
        <div className="main">
          <PropertyModalv2
            lotId={lotDetails.id}
            isLot={true}
            handleClose={() => setShowEnquireForm(false)}
          />
        </div>
      </S.DetailContainer>
    ) : (
      <S.DetailContainer className="lot">
        {!isSalesMode && <BackButton onClickHandler={dismissLotCard}></BackButton>}
        <div className="main">
          <LotCard lot={lotDetails} handleView={() => setShowEnquireForm(true)} />
        </div>
        {isSalesMode &&
          renderAuxNavigation(
            <div className="backContainer">
              <BackButton onClickHandler={dismissLotCard}></BackButton>
            </div>,
          )}
      </S.DetailContainer>
    )
    if (isMobile()) return renderStandaloneCard(LotDetails)
    else return LotDetails
  } else if (showEnquireForm) {
    return (
      <S.DetailContainer className="enquiry">
        <div className="main">
          <PropertyModalv2
            handleClose={() => setShowEnquireForm(false)}
          />
        </div>
      </S.DetailContainer>
    )
  }
  return (
    <React.Fragment>
      <S.CategoryContainer>
        {isSales && <StagesWrapper stages={lotsGroupedByStages} setShowEnquireForm={setShowEnquireForm} />}
        {calculated && (
          <>
            <S.FilterOverlay display={`${showFilters}`} />
            <S.SliderSection display={`${showFilters}`}>
              {isSalesPerson && (
                <CustomSlider
                  label="Price"
                  marks={[
                    { value: lotData.priceMin, label: formatPrice(lotData.priceMin) },
                    { value: lotData.priceMax, label: formatPrice(lotData.priceMax) },
                  ]}
                  valueLabelFormat={formatPrice}
                  valuetext={valuetext}
                  value={
                    lotFilters.price.min === undefined
                      ? [lotData.priceMin, lotData.priceMax]
                      : [lotFilters.price.min, lotFilters.price.max]
                  }
                  min={lotData.priceMin}
                  max={lotData.priceMax}
                  step={10000}
                  handleValueChange={(e, value) => dispatch(filterLots(value, 'price'))}
                />
              )}

              <CustomSlider
                label="Area"
                marks={[
                  { value: lotData.areaMin, label: lotData.areaMin + 'm2' },
                  { value: lotData.areaMax, label: lotData.areaMax + 'm2' },
                ]}
                valuetext={valuetext}
                valueLabelFormat={(value) => value + 'm2'}
                value={
                  lotFilters.area.min === undefined
                    ? [lotData.areaMin, lotData.areaMax]
                    : [lotFilters.area.min, lotFilters.area.max]
                }
                min={lotData.areaMin}
                max={lotData.areaMax}
                step={10}
                handleValueChange={(e, value) => dispatch(filterLots(value, 'area'))}
              />

              <CustomSlider
                label="Frontage"
                marks={[
                  { value: lotData.frontageMin, label: lotData.frontageMin + 'm2' },
                  { value: lotData.frontageMax, label: lotData.frontageMax + 'm2' },
                ]}
                valuetext={valuetext}
                valueLabelFormat={(value) => value + 'm2'}
                value={
                  lotFilters.frontage.min === undefined
                    ? [lotData.frontageMin, lotData.frontageMax]
                    : [lotFilters.frontage.min, lotFilters.frontage.max]
                }
                min={lotData.frontageMin}
                max={lotData.frontageMax}
                step={1}
                handleValueChange={(e, value) => dispatch(filterLots(value, 'frontage'))}
              />

              <S.SortClear>
                <S.CustomSelect
                  data={[
                    {
                      label: 'Area: Low to High',
                      value: 'area-asc',
                    },
                    {
                      label: 'Area: High to Low',
                      value: 'area-desc',
                    },
                    ...(isSalesPerson
                      ? [
                        {
                          label: 'Price: Low to High',
                          value: 'price-asc',
                        },
                        {
                          label: 'Price: High to Low',
                          value: 'price-desc',
                        },
                      ]
                      : []),
                  ]}
                  value={lotsSortBy}
                  emptyLabel="Sort By"
                  onAction={(value) => dispatch(sortLots(value))}
                ></S.CustomSelect>
                <S.ClearFilterBtn>
                  <a onClick={() => dispatch(clearFilterLots())}>Clear Filters</a>
                </S.ClearFilterBtn>
                {isSales && !!resultNumber && <S.FilterResults>{resultNumber}</S.FilterResults>}
              </S.SortClear>
            </S.SliderSection>
          </>
        )}
        {!isSales && <StagesWrapper stages={lotsGroupedByStages} setShowEnquireForm={setShowEnquireForm} />}
        <S.FilterButton
          display={`${showFilters}`}
          onClick={() => {
            setShowFilters((f) => {
              dispatch(setToggleButtonVisibility(f))
              return !f
            })
          }}
        >
          {showFilters ? <CloseIcon fontSize="small" /> : <TuneIcon />}
        </S.FilterButton>
      </S.CategoryContainer>
    </React.Fragment>
  )
}

export default Land
