import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import mapboxgl from 'mapbox-gl'
import styled, { css } from 'styled-components'
import GetAppIcon from '@material-ui/icons/GetApp'
import Status from '../../Status'

import { formatPrice, getLotMinZoom, isMobile } from '../../../utils/functions'
import {
  getMapPopup,
  getMap,
  isSalesVersion,
  isSalesPersonLogged,
  getMapPadding,
  getProject
} from '../../../redux/selectors'
import PropertyModalv2 from '../../PropertyModalv2'
import Api from '../../../redux/api'
import {
  setPdfUrl,
  showLotPopup,
  showToast,
  setSelectedStage,
  setToggleButtonVisibility,
  setContentVisibility
} from '../../../redux/actions'
import { MOBILE_WIDTH_THRESHOLD } from '../../../config'
import { getCenter } from '../../../utils/functions'

const S = {
  StagesWrapper: styled.div`
    height: auto;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 9px 50px -33px #000;

    a.register {
      border-style: none;
      border-width: 1px;
      border-color: #f0f1f2;
      border-radius: 10px;
      background-color: #fff;
      text-decoration: none;
      cursor: pointer;
      line-height: 1px;
      div {
        margin-right: 0.5rem;
        padding: 0rem 1rem 0.1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #bbe1f5;
        border-radius: 20px;
        box-shadow: none;
        font-family: Karla, sans-serif;
        color: #353535;
        font-size: 0.85rem;
        line-height: 1.6;
        font-weight: 400;
        text-decoration: none;
        width: fit-content;
      }
    }

    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      background-color: unset;
      box-shadow: unset;
      border-radius: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
    ${({ selected }) =>
      selected &&
      css`
        border-style: solid;
        border-width: 1px;
        border-color: #cdeeff;
        box-shadow: 0 0 39px -13px #7bc4eb;
      `};
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 2vw;
        margin-bottom: 0rem;
        //padding-bottom: 1.5rem;
        background-color: rgba(19, 21, 43, 0.31);
        box-shadow: 0 9px 70px -33px #000;
        //height: initial;
        height: 18rem;
        //padding: 1rem;
        border-radius: 15px;
        &:first-child {
          margin-left: 10vw;
        }
        &:last-child {
          margin-right: 10vw;
        }

        a.register {      
          display: block; 
          margin-left: 1.5rem;
          font-size: 1rem;
          text-align: center;
          text-decoration: none;
          background-color: transparent;
          div {
            color: #fff;
            font-size: 0.9rem;
            border-color: #fff;
            width: fit-content;
          }
        }
      `}

      h2.comingSoon {
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #bdbdbd;
        font-size: 1.4rem;
        line-height: 0.9;
        font-weight: 400;
        margin-left: 0.3rem;
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
            color: #ddd;
            font-size: 1.3rem;
            margin-left: 1.75rem;
              `}
          }      
  `,
  PropertyModalContainer: styled.div`
    ${({ theme }) =>
      theme.isSalesMode &&
      css` 
        background-color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0.75)),
          to(rgba(0, 0, 0, 0.49))
        );
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
      `}
  `,
  StagesWrapperHorizontal: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      background-color: #fff;
      margin: 0.6rem;      
      border-radius: 15px;
      height: 70vh;
      height: calc(var(--vh, 1vh) * 100 - 88px - 1.2rem);
      overflow-y: scroll;
      padding-bottom: 30px;
      padding-top: 0px;
      position: relative;
      box-shadow: 0 2px 20px -8px #2b2b2b;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: scroll;
        height: 20rem;
        //min-height: 350px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0vw;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(2, 2, 3, 0.76)),
          to(rgba(16, 18, 32, 0.59))
        );
        background-image: linear-gradient(180deg, rgba(2, 2, 3, 0.76), rgba(16, 18, 32, 0.59));
        align-items: center;
        background-color: transparent;
        
        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }

        .scroll-container {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-right: auto;
          margin-left: auto;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }
      `}
  `,
  TopRef: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      width: 10px;
      height: 2.8rem;
    }
  `,
  GetAppIcon: styled(GetAppIcon)`
    margin-right: 3px;
    && {
      background: transparent;
      font-size: 1rem;
    }
  `,
  StageTitleRow: styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ExpandStage: styled.div`
    ${({ theme }) =>
      theme.isSalesMode
        ? css`
            position: relative;
            padding: 0.5rem 0.5rem 0rem;
            border-style: none;
            border-width: 1px;
            border-color: #f0f0f0;
            border-radius: 18px;
            background-color: transparent;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
            margin-left: 1.5rem;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
          `
        : css`
            position: relative;
            display: block !important;
            margin-bottom: 0rem;
            padding: 0.5rem 0.5rem 0rem;
            @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
              padding-left: 0;
            }
            justify-content: space-between;
            align-items: center;
            border-style: none;
            border-width: 1px;
            border-color: #f0f0f0;
            border-radius: 18px;
            background-color: transparent;
          `}
  `,
  LotTable: styled.table`
    width: 100%; 
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        min-width: 250px;
        max-width: 350px;
      `}
  `,
  TableWrapper: styled.div`
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
      `}
  `,
  TableBody: styled.tbody``,
  ExpandButton: styled.div`
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.2rem 0.3rem;
    border-radius: 200px;
    background-color: #5ea5ca;
    cursor: pointer;
    img {
      position: relative;
      left: auto;
      top: 0%;
      right: 0%;
      bottom: auto;
      width: 1rem;
      height: 0.9rem;
      margin-right: 0rem;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  `,
  ExpandStageItem: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.9rem;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 1rem;
        margin-bottom: 0rem;
        line-height: initial;
        align-items: center;
        white-space: nowrap;
      `}

    h2 {
      margin-top: 0px;
      margin-right: 1rem;
      margin-bottom: 0px;
      font-family: alverata, sans-serif;
      color: #413d3d;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
      ${({ theme }) =>
      theme.isSalesMode &&
      css`
          color: #fff;
        `}

      &.thin {
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #bdbdbd;
        font-size: 1.4rem;
        line-height: 0.9;
        font-weight: 400;
        ${({ theme }) =>
      theme.isSalesMode &&
      css`
            color: #fff;
            font-size: 1.5rem;
          `}
      }
    }

    ${({ theme }) =>
      theme.isSalesMode
        ? css`
            a {
              display: block;
              margin-top: 0.3rem;
              margin-left: 1rem;
              padding: 4px 1.5rem;
              height: 100%;
              -webkit-box-pack: justify;
              -webkit-justify-content: space-between;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-flex: 0;
              -webkit-flex: 0 auto;
              -ms-flex: 0 auto;
              flex: 0 auto;
              border-style: solid;
              border-width: 1px;
              border-color: #fff;
              border-radius: 100px;
              background-color: transparent;
              color: #fff;
              font-size: 1rem;
              text-align: center;
              text-decoration: none;
              max-width: 100%;
              div {
                padding-left: 0rem;
                font-size: 0.9rem;
              }
            }
          `
        : css`
            a {
              border-style: none;
              border-width: 1px;
              border-color: #f0f1f2;
              border-radius: 10px;
              background-color: #fff;
              text-decoration: none;
              cursor: pointer;

              &.brochure {
                margin-right: 0.5rem;
                line-height: 1px;
                div {
                  margin-right: 0rem;
                  padding: 0rem 1rem 0.1rem;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #bbe1f5;
                  border-radius: 20px;
                  box-shadow: none;
                  font-family: Karla, sans-serif;
                  color: #353535;
                  font-size: 0.85rem;
                  line-height: 1.6;
                  font-weight: 400;
                  text-decoration: none;
                }
              }
              &.view {
                line-height: 1px;
                div {
                  margin-right: 0.5rem;
                  padding: 0rem 1rem 0.1rem;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #bbe1f5;
                  border-radius: 20px;
                  box-shadow: none;
                  font-family: Karla, sans-serif;
                  color: #353535;
                  font-size: 0.85rem;
                  line-height: 1.6;
                  font-weight: 400;
                  text-decoration: none;
                }
              }
            }
          `}}


  `,
  StageTitle: styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    margin: 0 10px;
  `,
  BrochureWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    margin-right: 10px;
  `,
  StageActioBtn: styled.div`
    padding: 0 8px;
    background: white;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
  `,
  ViewBtn: styled.input`
    padding: 0 15px;
    border-radius: 30px;
    border: 1px solid #e9e9e9;
    background: white;
    margin: 10px 0px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  `,
  Table: styled.table`
    font-size: 0.7rem;
    width: 100%;

    tbody {
      text-align: center;

      tr {
        &:hover {
          background-color: whitesmoke;
          cursor: pointer;
        }

        td:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      sup {
        vertical-align: super;
        font-size: xx-small;
      }
    }
  `,

  TableHeader: styled.thead`
    &.hasPrices {
      width: 18vw;
      min-width: 20rem;
    }
    .availability {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 10%;
      padding-left: 0.5rem;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .lot-no,
    .lot-frontage,
    .lot-area,
    .lot-price,
    .lot-view {
      padding-right: 1rem;

      h3 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0px;
        margin-bottom: 0px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-family: Karla, sans-serif;
        font-size: 0.85rem;
        font-weight: 1000;
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
            display: block;
            color: #fff;
          `}
      }
    }

    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        .lot-frontage {
          flex: 0.27;
        }
      `}
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        .lot-price {
          flex: 0.29;
        }
      `}
  `,
  TableRow: styled.tr`
    .lot-no,
    .lot-frontage,
    .lot-area,
    .lot-price,
    .lot-view {      

      h3 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0px;
        margin-bottom: 0px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-family: Karla, sans-serif;
        color: #757575;
        font-size: 0.85rem;
        font-weight: 400;
        color: rgb(117, 117, 117);
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
                display: block;
                color: #fff;
              `}        
      }
    }
    .lot-view {
      padding-right: 1rem;
      justify-content: flex-end;
      align-items: center;
      justify-content: flex-start;

      a {
        display: flex;
        flex-direction: row;
        border-style: none;
        border-width: 1px;
        border-color: #f0f1f2;
        border-radius: 10px;
        border-style: solid;
        border-width: 1px;
        border-color: #bbe1f5;
        border-radius: 20px;
        background-color: #fff;
        text-decoration: none;
        width: fit-content;
        div.number {
          margin-right: 0rem;
          padding: 0rem 0.5rem 0rem;

          box-shadow: none;
          font-family: Karla, sans-serif;
          color: #353535;
          font-size: 0.85rem;
          line-height: 1.6;
          font-weight: 400;
          text-decoration: none;
        }
        ${({ theme }) =>
      theme.isSalesMode &&
      css`        
            width: fit-content;
            height: 1.6rem;
            padding-right: 0.5rem;
            padding-left: 0.1rem;
            border-color: #fff;
            border-radius: 100px;
            background-color: transparent;
            align-items: center;

            div.number {
              color: white;
              padding: 0;
              flex: initial;
              /*
              white-space: nowrap
              margin-right: 0rem;
              padding: 0rem 0.5rem 0rem;

              box-shadow: none;
              font-family: Karla, sans-serif;
              color: #fff;
              font-size: 0.85rem;
              line-height: 1.6;
              font-weight: 400;
              text-decoration: none;
              */
            }
            `}      


        div.availability {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;

          padding-left: 0.5rem;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          .indicator-dot {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.5rem;
            border-radius: 10px;
            background-color: #64fe40;
            margin-right: 0.3rem;
          }
        }
      }
    }
  `,
  BackToTop: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;

      position: fixed;
      bottom: calc(88px + 1rem);
      width: calc(100% - 1.2rem - 30px);
      margin-left: 15px;
      justify-content: flex-end;
      a {
        display: flex;
        align-self: flex-end;
        justify-content: space-around;
        padding: 0.2rem 0.7rem;
        border: 1px solid #e3e3e3;
        background-color: #fff;
        margin-bottom: 15px;
        font-size: 0.9rem;
        font-family: Karla, sans-serif;
        color: #000;
        border-radius: 15px;
        text-align: center;
        font-weight: bold;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  `,
}
const StagesWrapperHorizontal = ({ children }) => {
  const container = useRef()
  return (
    <S.StagesWrapperHorizontal>
      <div className="scroll-container">
        <S.TopRef ref={container} />
        {children}
      </div>
      <BackToTop
        onClick={() => {
          if (container.current) {
            container.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
          }
        }}
      />
    </S.StagesWrapperHorizontal>
  )
}

const BackToTop = ({ onClick }) => (
  <S.BackToTop>
    <a href="#stageListTop" onClick={onClick}>
      Back to Top
    </a>
  </S.BackToTop>
)

const StagesWrapper = ({ stages: stagesProp = [], setShowEnquireForm }) => {
  const dispatch = useDispatch()
  const map = useSelector(getMap)
  const project = useSelector(getProject)
  const mapPopup = useSelector(getMapPopup)
  const lotsLoaded = useSelector((state) => state.lots.loaded)
  const stagesLoaded = useSelector((state) => state.stages.loaded)
  const isSalesMode = useSelector(isSalesVersion)
  const isSalesPerson = useSelector(isSalesPersonLogged)
  const padding = useSelector(getMapPadding)
  const selectedStage = useSelector((state) => state.selectedStage)
  const lots = useSelector((state) => state.lots.data)
  const stages = useMemo(() => stagesProp.filter((stage) => stage.enabled && stage.lotsData.length && !stage.packageOnly), [stagesProp])
  const lotsById = useMemo(() => {
    const lotMap = {}
    lots.forEach((lot) => (lotMap[lot.id] = lot))
    return lotMap
  }, [lots])

  const [expandedStages, setExpandedStages] = useState([])

  useEffect(() => {
    dispatch(setToggleButtonVisibility(true))
    return function () {
      dispatch(setToggleButtonVisibility(false))
    }
  }, [dispatch])
  useEffect(() => {
    setExpandedStages(
      stages.map((stage) => stage.id),
    )
  }, [stages])

  const zoomToStage = (stage) => {
    if (!stage.lots) return
    const bounds = new mapboxgl.LngLatBounds()
    let hasCoords = false
    stage.lots.forEach((lotId) => {
      const lot = lotsById[lotId]
      if (lot.geo?.geometry?.coordinates[0]?.length) {
        hasCoords = true
        lot.geo.geometry.coordinates[0].forEach((coord) => bounds.extend(coord))
      }
    })
    if (hasCoords) map.fitBounds(bounds, { maxZoom: getLotMinZoom() + 1, padding })
    else
      dispatch(
        showToast({
          type: 'warning',
          body: `Stage boundaries are not defined on map`,
        }),
      )
  }

  const toggleStageLots = (event, stage) => {
    event.stopPropagation()

    const { id } = stage
    const index = expandedStages.indexOf(id)
    if (index < 0) {
      setExpandedStages([...expandedStages, id])
    } else {
      setExpandedStages([
        ...expandedStages.slice(0, index),
        ...expandedStages.slice(index + 1, expandedStages.length),
      ])
    }
  }

  const onViewClick = (event, stage) => {
    event.stopPropagation()
    zoomToStage(stage)
    if (isMobile()) {
      dispatch(setContentVisibility(false))
    }
    dispatch(setSelectedStage(stage))


  }

  const onLotClickHandler = (lot) => {
    if (!lot.geo) {
      dispatch(
        showToast({
          type: 'warning',
          body: `Lot ${lot.humanId} boundaries are not defined on map`,
        }),
      )
      return
    }

    dispatch(showLotPopup(lot))
  }

  const onBrochureClick = (e, stage) => {
    if (!isSalesMode)
      return;
    e.preventDefault()
    if (!stage.brochure) return
    dispatch(setPdfUrl(stage.brochure.url))
    dispatch(
      Api.broadcastAction({
        actionType: 'viewBrochure',
        entityType: 'stage',
        entityId: stage.id,
      }),
    )
  }
  if (lotsLoaded && stagesLoaded && stages.length === 0)
    return (
        <S.PropertyModalContainer>
          <PropertyModalv2
            hideBackButton={true}
            title={`${project.frontendName} is currently sold out of available land.`}
            subtitle="New lots are expected to be released shortly. Please register your details below so we can keep you informed."
          />
        </S.PropertyModalContainer>    
    )
  return (
    <StagesWrapperHorizontal>
      {stages
        .map((stage) => {
          const stageLotsSections = []
          if (isSalesMode) {
            const stageLots = [...stage.lotsData]
            while (stageLots.length > 0) stageLotsSections.push(stageLots.splice(0, 5))
          } else {
            stageLotsSections.push(stage.lotsData)
          }

          return (
            <S.StagesWrapper key={stage.id} selected={selectedStage?.id === stage.id}>
              <S.ExpandStage onClick={isSalesMode ? (event) => toggleStageLots(event, stage) : undefined}>
                <S.ExpandStageItem className="ExpandStageItem">
                  <h2>Stage {stage.number}</h2>
                  <h2 className="thin">{stage.name}</h2>
                </S.ExpandStageItem>
                <S.ExpandStageItem>
                  <a href="#" className="view" onClick={(event) => onViewClick(event, stage)}>
                    <div>View Stage</div>
                  </a>
                  {stage.brochure && (
                    <a
                      href={stage.brochure.url}
                      target="_blank"
                      className="brochure"
                      onClick={(event) => onBrochureClick(event, stage)}
                    >
                      <div>Brochure</div>
                    </a>
                  )}
                </S.ExpandStageItem>
                {!isSalesMode && stage.releaseSatus !== 'COMING_SOON' && (
                  <S.ExpandButton onClick={(event) => toggleStageLots(event, stage)}>
                    {expandedStages.includes(stage.id) ? (
                      <img src="/images-new/back-arrow.svg" alt="" />
                    ) : (
                      <img className="rotate" src="/images-new/back-arrow.svg" alt="" />
                    )}
                  </S.ExpandButton>
                )}
              </S.ExpandStage>
              { stage.releaseSatus === 'COMING_SOON' ? (
                <>
                <h2 className="comingSoon">Coming Soon</h2>
                <a href="#" className="register" onClick={() => {
                  setShowEnquireForm(true)
                }}>
                  <div>Register your Interest</div>
                </a>
                </>
              ) : (
                  <S.TableWrapper>
                  {expandedStages.includes(stage.id) &&
                    stageLotsSections.map((stageLotsSection, index) => (
                      <S.LotTable key={index}>
                        <S.TableHeader className={isSalesPerson && 'hasPrices'}>
                          <tr>
                          <td className="lot-no">
                            <h3>Lot</h3>
                          </td>
                          <td className="lot-frontage">
                            <h3>Frontage</h3>
                          </td>
                          <td className="lot-area">
                            <h3>Area</h3>
                          </td>
                          {isSalesPerson && (
                            <td className="lot-price">
                              <h3>Price</h3>
                            </td>
                          )}
                          </tr>
                        </S.TableHeader>
                        <S.TableBody>
                          {stageLotsSection.map((lot, index) => (
                            <S.TableRow key={index}>
                              <td className="lot-view">
                                <a href="#" onClick={() => onLotClickHandler(lot)}>
                                  <div className="availability">
                                    <Status status={lot.status} />
                                  </div>
                                  <div className="number">{lot.humanId}</div>
                                </a>
                              </td>
                              <td className="lot-frontage">
                                <h3>{lot.dimensions ? lot.dimensions.width : 0}m</h3>
                              </td>
                              <td className="lot-area">
                                <h3>
                                  {lot.sqm}m<sup>2</sup>
                                </h3>
                              </td>
                              {isSalesPerson && (
                                <td className="lot-price">
                                  <h3>{formatPrice(lot.price) || '-'}</h3>
                                </td>
                              )}
                            </S.TableRow>
                          ))}
                        </S.TableBody>
                      </S.LotTable>
                    ))}
                </S.TableWrapper>
                )
              }
            </S.StagesWrapper>
          )
        })}
    </StagesWrapperHorizontal>
  )
}

export default StagesWrapper
