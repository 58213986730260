import polylabel from 'polylabel'
import ReactDOM from 'react-dom'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import union from '@turf/union'
import buffer from '@turf/buffer'
import turfCenter from '@turf/center'
import convex from '@turf/convex'
import {featureCollection, point} from '@turf/helpers'
import { Cloudinary } from 'cloudinary-core'

export const formatPrice = (x) => x && `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
export const cloudinary = new Cloudinary({ cloud_name: 'intrapac', secure: true })
export const rsz = (url, params) => {
  if (url) {
    const replacedUrl = url.replace(/http[s]?:\/\/res.cloudinary.com\/intrapac\/image\/upload\//, '').replace(/\/v[0-9]+\//, '/')
    return cloudinary.url(replacedUrl, params)
  }
}

export function getPoints(p1, p2, counter) {
  const distanceBetweenPoints = Math.sqrt((p2.x - p1.x) ^ (2 + (p2.y - p1.y)) ^ 2)

  const result = [[p1.x, p1.y]]

  for (let i = 1; i <= counter; i++) {
    const distanceRatio = i / 6 / distanceBetweenPoints
    const x = p1.x + distanceRatio * (p2.x - p1.x)
    const y = p1.y + distanceRatio * (p2.y - p1.y)

    result.push([x, y])
  }

  result.push([p2.x, p2.y])
  return result
}

export function getLotsBoundaries(lots) {
  const availableLotsCoordinates = []
  lots.forEach(lot => {
    const lotCoords = getCoords(lot.geo?.geometry?.coordinates)
    if (lotCoords) {
      lotCoords.forEach((polygon) =>
        polygon.forEach((coordinate) => availableLotsCoordinates.push(point(coordinate))),
      )
    }
  })
  const collection = featureCollection(availableLotsCoordinates)
  const bounds = convex(collection);
  return bounds.geometry.coordinates[0];
}

function getArrayDepth(value) {
  return Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0
}
export function getCoords(coordinates) {
  let i = getArrayDepth(coordinates)
  let coords = coordinates
  if (i > 3)
    for (i; i > 3; i--) {
      coords = coords[0]
    }
  else
    for (i; i < 3; i++) {
      coords = [coordinates]
    }
  return coords.filter(Boolean)
}
export function getCenter(coordinates) {
  if (!coordinates || !coordinates.length) return [0, 0]
  try {
    const coords = getCoords(coordinates)
    const center = polylabel(coords, 1.0)
    if (isNaN(center[0])) return [0, 0]
    return center
  } catch (e) {
    return [0, 0]
  }
}

export function easeToCoordinate(map, coords, padding) {
  const zoom = isMobile() ? 19 : 20;
  map.fitBounds([coords, coords], {
    pitch: 45,
    bearing: 0,
    maxZoom: zoom,
    // center: [long, lat],
    minZoom: zoom,
    zoom,
    easing: function (t) {
      return 1 - Math.pow(1 - t, 5)
    },
    duration: 2000,
    padding,
  })
}

export function getURLQueryParams(name, url) {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regexS = '[\\?&]' + name + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(url)
  return results == null ? null : results[1]
}

export function vw(v) {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}

export function vh(v) {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  return (v * h) / 100
}

export function remToPixels(r) {
  return r * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function renderAuxNavigation(children) {
  return ReactDOM.createPortal(children, document.getElementById('auxNavigation'))
}

export function renderStandaloneCard(children) {
  return ReactDOM.createPortal(children, document.getElementById('cardContainer'))
}


export function isSalesUrl(project) {
  console.log("**** isSalesUrl?", project?.frontendSalesDomains, window.location.host)
  return (
    window.location.pathname.indexOf('/sales') === 0 ||
    (project?.frontendSalesDomains || '').split(';').some((i) => i === window.location.host)
  )
}

export const convertLotsToGeoJson = (lots) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: [],
  }

  lots.forEach((lot) => {
    const { id, status, sqm, price, geo, humanId, dimensions, stage } = lot
    if (geo && stage && stage.enabled) {
      geoJson.features.push({
        type: 'Feature',
        id,
        properties: {
          id,
          status,
          stageId: stage.id,
          sqm: sqm ? sqm : 0,
          price: price ? price : 0,
          humanId,
          width: (dimensions || {}).width || 0,
          depth: (dimensions || {}).depth || 0,
        },
        geometry: geo.geometry,
      })
    }
  })

  return geoJson
}

export const convertStagesToGeoJson = (stages, lotsById) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: [],
  }

  stages.forEach((stage) => {
    const { id, geo, name, number, releaseSatus: releaseStatus, enabled, color, lots } = stage

    if (enabled) {
      let coordinates = []
      lots.forEach((lotId) => {
        const lot = lotsById[lotId]
        if (lot?.geo) {
          const c = lot?.geo?.geometry.coordinates
          const arrDepth = getArrayDepth(c)
          if (arrDepth === 3 || arrDepth === 4)
            coordinates.push(arrDepth === 3 ? c : c[0])
        }
      })
      geoJson.features.push({
        type: 'Feature',
        id,
        properties: {
          id,
          name,
          number,
          releaseStatus,
          enabled,
          color,
        },
        geometry: {
          ...stage.geo?.geometry,
          type: 'MultiPolygon',
          coordinates,
        },
      })
    }
  })
  return geoJson
}

export const convertStagesToGeoJsonOutline = (stages, lotsById) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: [],
  }

  stages.forEach((stage) => {
    const { id, geo, name, number, releaseSatus: releaseStatus, enabled, color, lots } = stage

    if (enabled) {
      let coordinates = []
      let stageGeometry;
      lots.forEach((lotId, index) => {
        const lot = lotsById[lotId]
        if (lot?.geo) {
          const bufferedGeo = buffer(lot.geo, 0.5, {units: 'meters'})
          if (!stageGeometry)
            stageGeometry = bufferedGeo
          else {
            try {
              if (stageGeometry && bufferedGeo)
                stageGeometry = union(stageGeometry, bufferedGeo)
            } catch (e) {
              console.log("*** error", stage.name, stageGeometry, bufferedGeo, e)
            }   
          }
        }
      })
      if (stageGeometry?.geometry)
        geoJson.features.push({
          type: 'Feature',
          id,
          properties: {
            id,
            name,
            number,
            releaseStatus,
            enabled,
            color,
          },
          geometry: stageGeometry?.geometry
        })
    }
  })
  return geoJson
}

export const convertStagesOutlinesToGeoJsonCenteredPoints = (stagesFeatures, lotsById) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: [],
  }
  geoJson.features = stagesFeatures.features.map(({ properties, geometry }) => {
    const { id, name, number, releaseStatus } = properties
    const center = turfCenter(geometry).geometry.coordinates; //getCenter(geometry.coordinates)
    console.log('stage', name, center)
    return {
      type: 'Feature',
      id,
      properties: {
        name: releaseStatus === 'COMING_SOON' ? name+'\n(Coming Soon)' : name,
        number,
        displayNumber: 'Stage ' + number,
        releaseStatus,
      },
      geometry: {
        type: 'Point',
        coordinates: center,
      },
    }
  })
  return geoJson;
}

export const getLotNumbers = (lots) => {
  const numbers = {
    type: 'FeatureCollection',
    features: [],
  }
  lots.forEach((lot) => {
    if (lot.geo && lot.stage && lot.stage.enabled) {
      numbers.features.push({
        type: 'Feature',
        properties: {
          description: lot.humanId,
          status: lot.status,
          id: lot.id,
          enabled: lot.stage.enabled,

          stageId: lot.stage.id,
          sqm: lot.sqm || 0,
          price: lot.price || 0,
          humanId: lot.humanId,
          width: lot.dimensions?.width || 0,
          depth: lot.dimensions?.depth || 0,
        },
        geometry: {
          type: 'Point',
          coordinates:
            lot.geo?.geometry?.type === 'Point'
              ? lot.geo?.geometry?.coordinates
              : getCenter(lot.geo?.geometry?.coordinates),
        },
      })
    }
  })
  return numbers
}

export function isMobile() {
  return window.innerWidth <= MOBILE_WIDTH_THRESHOLD
}

export const getLotMinZoom = () => (isMobile() ? 16 : 17);

export const scaleToMapIcon = (s, squareSize) => {
  let r = s.replace('http://', 'https://')
  if (squareSize || squareSize !== null) {
    r = r.replace('/image/upload/', `/image/upload/c_fit,w_${squareSize || 52}/`).replace(/migrated\/v[0-9]+\//,'migrated/')
  }
  return r
}