import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import MailIcon from '@material-ui/icons/Mail'
import TextField from '@material-ui/core/TextField'
import Status from './Status'
import { emailValidator } from '../utils/Validate'
import { showToast } from '../redux/actions'

const S = {
  FavouritesModal: styled.div``,
  LotHeader: styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  LotStatus: styled.div`
    display: flex;
    margin: 20px 0px 10px 0px;
  `,
  FavoriteIcon: styled(FavoriteIcon)`
    color: rgb(243, 104, 39);
  `,
  FavoriteBorderIcon: styled(FavoriteBorderIcon)`
    color: rgb(243, 104, 39);
  `,
  StatusContainer: styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
  `,
  FavoriteIconContainer: styled.div`
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      height: 20px;
    }
  `,
  Status: styled(Status)`
    margin-right: 5px;
  `,
  LotDetails: styled.div`
    display: flex;
    justify-content: space-between;
    p {
      color: #c7c7c7;
      display: inline;
    }
  `,
  Form: styled.form`
    margin: 10px 20px;
    display: flex;
    flex-direction: column;

    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    input {
      font-size: 0.75rem;
      font-weight: bold;
      margin-top: 25px;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  `,
  Enquire: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    padding-top: 20px;
    svg {
      margin-right: 5px;
      font-size: 1rem;
    }
  `,
}
const FavouritesModal = ({ onSave }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const formRef = useRef()

  const checkFieldsAreFilled = () => {
    let filled = false
    if (formData.firstName && formData.email) {
      filled = true
    }
    return filled
  }
  const isBtnDisabled = () => {
    let isBtnDisabled = true
    if (checkFieldsAreFilled() && emailValidator.test(formData.email)) {
      isBtnDisabled = false
    } else {
      isBtnDisabled = true
    }
    return setIsDisabled(isBtnDisabled)
  }

  const handleInputValueChange = e => {
    const { value, name } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const displayToast = (body, type) => {
    dispatch(
      showToast({
        body,
        type,
      }),
    )
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (checkFieldsAreFilled() === false) {
      displayToast('All fields must be filled', 'error')
    } else if (checkFieldsAreFilled() && emailValidator.test(formData.email) === false) {
      displayToast('Email must be valid email', 'error')
    } else {
      onSave(formData)
      setFormData({
        firstName: '',
        email: '',
      })
      formRef.current.reset()
    }
  }

  useEffect(() => {
    isBtnDisabled()
  })

  return (
    <S.FavouritesModal>
      <Card>
        <CardContent>
          <S.LotHeader>
            <div>Save for Later</div>
          </S.LotHeader>
          <S.Form ref={formRef}>
            <TextField
              placeholder="First name"
              name="firstName"
              onChange={handleInputValueChange}
              value={formData.firstName}
            />
            <TextField
              placeholder="Email"
              name="email"
              onChange={handleInputValueChange}
              type="email"
              value={formData.email}
            />
            <S.Enquire isDisabled={isDisabled} onClick={handleSubmit}>
              <MailIcon /> Send
            </S.Enquire>
          </S.Form>
        </CardContent>
      </Card>
    </S.FavouritesModal>
  )
}

export default FavouritesModal
