import React, { useState, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

const S = {
  CustomSlider: styled.div`
    margin: 10px;

    #discrete-slider-custom {
      font-weight: 600;
    }
    .MuiSlider-markLabel {
      font-size: 12px;
    }

    h3 {
      font-family: Karla, sans-serif;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        min-width: 200px;
        max-width: 500px;
        width: 12vw;
        margin-right: 25px;
      `}
  `,
  ValueContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .valueLabel {
      font-size: 12px;
    }
  `,
}
const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'
const IOSSlider = withStyles({
  root: {
    color: '#6199B6',
    height: 1,
    // padding: '15px 0',
    marginBottom: 0,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -10,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  markLabel: {
    display: 'none',
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider)

const CustomSlider = ({
  label,
  step,
  marks,
  value,
  valuetext,
  defaultValue,
  min,
  max,
  className,
  valueLabelFormat,
  handleValueChange,
}) => {
  return (
    <S.CustomSlider className={className}>
      <h3 id="discrete-slider-custom">{label}</h3>

      <IOSSlider
        defaultValue={defaultValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={step}
        valueLabelDisplay="off"
        marks={marks}
        min={min}
        max={max}
        onChange={handleValueChange}
        value={value}
      />
      <S.ValueContainer>
        <h3>{valueLabelFormat((value || defaultValue)[0])}</h3>
        <h3>{valueLabelFormat((value || defaultValue)[1])}</h3>
      </S.ValueContainer>
    </S.CustomSlider>
  )
}

export default CustomSlider
