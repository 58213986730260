import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { isSalesVersion } from '../../redux/selectors'
import { vw } from '../../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'

const StyledCheckbox = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 1rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 200px;
  div {
    overflow: hidden;
    width: 20px;
    height: 20px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 10px;
  }
`

const S = {
  Wrapper: styled.div`
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      padding: 5.2vw;
    }      
  `,
  Title: styled.h1`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.77);
    z-index: 5;
    font-family: alverata, sans-serif;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 5rem;
      margin-bottom: 1rem;
    }        
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: #fff;
        font-size: 2.5rem;
        font-weight: 700;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.88);
      `}
  `,
  ListWrapper: styled.div`
    display: flex;
    overflow: visible;
    margin-top: 2.5rem;
    padding-top: 0rem;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;

    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;

        margin-top: 1rem;
        padding-top: 0rem;
        padding-bottom: 0rem;

        justify-content: initial;
        flex-wrap: initial;
        align-items: initial;
        align-content: initial;

        width: 60vw;
        min-width: 800px;
        height: 240px;
        scroll-snap-type: mandatory;
        scroll-snap-points-x: repeat(calc(9.9vw + 55px));
        scroll-snap-type: x mandatory;
        
        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }
      `}
  `,
  RightArrow: styled.a`
    position: absolute;
    top: 0;
    right: -80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
      transform: rotate(180deg);
      box-shadow: 0 13px 38px -16px rgba(0, 0, 0, 0.44);
    }
  `,
  LeftArrow: styled.a`
    position: absolute;
    top: 0;
    left: -80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
      box-shadow: 0 13px 38px -16px rgba(0, 0, 0, 0.44);
    }
  `,
  ListContent: styled.div`
    flex: 1;
    ${({ theme }) =>
      theme.isEmbeddedMode &&
      css`
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    `}      
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex: initial;
        flex-wrap: nowrap;
        height: 200px;
        width: auto;
        flex-direction: row;        
        align-items: flex-start;
        justify-content: flex-start;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        margin-bottom: -1rem;
      }
    `}
  `,
  ListItemColumn: styled.section`
    //width: 9.9vw;
    margin-right: 40px;
    scroll-snap-align: start;
    :last-child {
      margin-right: 15px;
    }
  `,
  ListItem: styled.div`
    position: relative;
    display: flex;
    cursor: pointer;
    width: 100%;
    min-width: 300px;
    height: auto;
    align-items: center;
    border-radius: 20px;
 
    background-color: #fff;
    margin: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    box-shadow: 0 13px 38px -16px rgba(0, 0, 0, 0.44);
    
    &:hover {
      ${StyledCheckbox} {
        background-color: #64aed6;
      }
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: 89vw;
      &:hover {
        ${StyledCheckbox} {
          background-color: initial;
        }
      }
    }   
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-bottom: 1.5rem;
        background-color: rgba(23, 24, 36, 0.35);
        &:hover {
          box-shadow: 0 0 20px -3px #7bc4eb;
        }
      `}
  `,

  ListItemDescription: styled.div`
    overflow: hidden;
    h2 {
      margin-top: 0px;
      margin-bottom: 0px;
      color: #4c4949;
      font-size: 0.85rem;
      line-height: 1.5;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      margin-bottom: 0px;
      color: #b6b6b6;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        h2 {
          color: white;
        }
        p {
          color: white;
        }
      `}
  `,
}

const ListItem = ({ item, index, onClick }) => (
  <S.ListItem key={index} onClick={onClick}>
    <StyledCheckbox>
      <div />
    </StyledCheckbox>
    <S.ListItemDescription>
      <h2>{item.properties.name}</h2>
      <p>{item.properties.address}</p>
    </S.ListItemDescription>
  </S.ListItem>
)

const ExpandedPoiList = ({ category, selectedPoi, setSelectedPoi }) => {
  const isSalesMode = useSelector(isSalesVersion)
  const container = useRef()
  let items = []
  if (isSalesMode) {
    for (let i = 0; i < category.items.length; i += 2) {
      const item1 = category.items[i]
      const item2 = category.items[i + 1]
      items.push(
        <S.ListItemColumn key={`itemColumn-${i}`}>
          <ListItem item={item1} index={i} onClick={() => setSelectedPoi(item1)} />
          {item2 && <ListItem item={item2} index={i + 1} onClick={() => setSelectedPoi(item2)} />}
        </S.ListItemColumn>,
      )
    }
  } else {
    items = category.items.map((item, index) => (
      <ListItem item={item} index={index} onClick={() => setSelectedPoi(item)} key={`item-${index}`} />
    ))
  }
  const scrollRight = () => {
    if (container.current) {
      container.current.scrollBy({
        top: 0,
        left: vw(9.9) + 50,
        behavior: 'smooth',
      })
    }
  }

  const scrollLeft = () => {
    if (container.current) {
      container.current.scrollBy({
        top: 0,
        left: (vw(9.9) + 50) * -1,
        behavior: 'smooth',
      })
    }
  }

  return (
    <S.Wrapper>
      <S.Title>{category.name}</S.Title>
      <S.ListWrapper ref={container}>
        <S.ListContent>{items}</S.ListContent>
      </S.ListWrapper>
      {isSalesMode && items.length >= 6 ? (
        <>
          <S.LeftArrow onClick={scrollLeft}>
            <img src="/images-new/back-arrow.svg" />
          </S.LeftArrow>
          <S.RightArrow onClick={scrollRight}>
            <img src="/images-new/back-arrow.svg" />
          </S.RightArrow>
        </>
      ) : null}
    </S.Wrapper>
  )
}

export default ExpandedPoiList
