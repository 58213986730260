import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { hideRouteToPoint } from '../redux/actions'

const S = {
  Wrapper: styled.div`
    position: absolute;
    //width: 100%;
    bottom: 24rem;
    left: calc(50% - 100px);
  `,
  ClearDirections: styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.8rem;
    background-color: #6EA4C5;
    box-shadow: 0 9px 50px -28px #000;
    box-shadow: 0 12px 33px -10px #2b2b2b;
    font-size: 0.9rem;
    font-family: Karla, sans-serif;
    color: #fff;
    width: 11.2rem;
    border-radius: 15px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    img {
      margin-left: 0.5rem
      width: 1rem;
    }
  `,
}

const ClearDirections = () => {
  const visible = useSelector((state) => state.routeVisible)
  const dispatch = useDispatch()
  return visible ? (
    <S.Wrapper>
      <S.ClearDirections onClick={() => dispatch(hideRouteToPoint())}>
        Clear Directions <img src="/images-new/cancel.svg" />
      </S.ClearDirections>
    </S.Wrapper>
  ) : null
}

export default ClearDirections
