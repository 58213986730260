import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MailIcon from '@material-ui/icons/Mail'

import mapboxgl from 'mapbox-gl'
import polylabel from 'polylabel'
import Status from '../Status'
import CustomSlider from '../CustomSlider'
import {
  sortHouses,
  addLotToFavourites,
  addPackageToFavourites,
  showToast,
  setToggleButtonVisibility,
} from '../../redux/actions'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'

import PackageCard from '../PackageCard'
import {
  getFavouriteLots,
  getFavouritePackages,
  getMap,
  getMapPadding,
  isSalesVersion,
} from '../../redux/selectors'
import { renderAuxNavigation, isMobile } from '../../utils/functions'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import FavouritesModal from '../FavouritesModal'
import Api from '../../redux/api'
import LotCard from '../LotCard'
import PropertyModalv2 from '../PropertyModalv2'
import BackButton from '../../reusable/BackButton'
const S = {
  LotCard: styled(LotCard)`
    margin-top: 0;
    margin-bottom: 3rem;
  `,

  Container: styled.div`
    margin-top: 3rem;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      padding: 5.2vw;
      background: #f1f5fa;
      height: calc(100vh - 88px);
      height: calc(var(--vh, 1vh) * 100 - 88px);
      overflow-y: scroll;
      margin-top: 0;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;

      ${({ showFavButton }) =>
        showFavButton &&
        css`
          height: calc(100vh - 88px - 40px);
          height: calc(var(--vh, 1vh) * 100 - 88px - 40px);
        `}
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        //height: 22.5vh;
        margin-top: initial;
        align-items: center;
        overflow: scroll;
        padding-top: 0rem;

        background-color: hsla(0, 0%, 100%, 0.28);
        background-color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0.75)),
          to(rgba(0, 0, 0, 0.49))
        );
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }

        .scroll {
          display: flex;
          flex-direction: row;
          padding-left: 40vw;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
      `}
  `,
  Button: styled.button`
    position: fixed;
    font-size: 0.75rem;
    font-weight: bold;
    bottom: 10px;
    width: 350px;
    left: 38px;
    border-radius: 5px;
    background-color: whitesmoke;
    z-index: 10;
    text-align: center;
    padding: 6px 10px;
    border: 1px solid #e6e6e6;
    color: #000;
    ${({ active }) =>
      active &&
      css`
        background: #e8f3ff;
        border: none;
      `}
    &:hover {
      background: whitesmoke;
    }
  `,
  CardListWrapper: styled.div`
    font-size: 0.75rem;
    width: 100%;
    thead {
      font-weight: bold;
      th {
        text-align: left;
        padding-left: 10px;
      }
    }
    tbody {
      text-align: center;
      overflow: auto;

      tr {
        td:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        td:last-of-type {
          vertical-align: middle;
        }
      }
      sup {
        vertical-align: super;
        font-size: xx-small;
      }
    }

    .lots-tbody {
      tr {
        &:hover {
          background-color: rgb(63, 81, 181, 0.4);
          cursor: pointer;
        }
      }
    }
  `,
  ViewBtn: styled.input`
    padding: 0 15px;
    color: #c7c7c7;
    border-radius: 30px;
    border: 1px solid #e9e9e9;
    background: white;
    margin: 10px 0px;
    font-size: 12px;
    cursor: pointer;
  `,
  CategoryContainer: styled.div``,
  CustomSlider: styled(CustomSlider)`
    width: 100%;
  `,
  SortBy: styled.div`
    display: flex;
    align-items: center;
  `,
  CardsContainer: styled.div`
    position: relative;
    .MuiCard-root {
      margin: 5px 0px;
      width: 96%;
    }

    .package-card:last-child {
      margin-bottom: 2rem;
    }
  `,
  TableContainer: styled.div`
    overflow: auto;
    padding-right: 10px;
  `,
  CloseRoundedIcon: styled(CloseRoundedIcon)`
    border-radius: 30px;
    background-color: white;
    && {
      width: 18px;
      height: 18px;
      margin-top: 8px;
      margin-right: 4px;
    }
  `,
  LotsWrapper: styled.div``,
  Header: styled.div`
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid #ccc;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 30px;
  `,
  PackagesWrapper: styled.div`
    margin-top: 30px;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      .package-card {
        width: 89.6vw !important;
        margin-top: 0 !important;
      }
    }
  `,
  Empty: styled.div`
    height: auto;
    margin-top: 1rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 23px 49px -9px rgba(0, 0, 0, 0.16);
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 5rem;
        margin-bottom: 5rem;
        background-color: initial;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
  `,
  EmptyP: styled.p`
    padding: 1rem;
    box-shadow: none;
    color: rgba(51, 51, 51, 0.69);
    font-size: 0.85rem;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
      `}
  `,
  Enquire: styled.div`
    top: calc(130px);
    
    z-index: 5;
    //position: fixed;
    display: flex;
    align-items: stretch;
    background-color: whitesmoke;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 10px 1rem 10px 1rem;

    border-radius: 100px;
    box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.33);
    font-size: 0.8rem;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      top: initial;
      bottom: 88px;
      width: 100vw;
      border-radius: 0;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        top: initial;
        //min-width: 10vw;
        //max-width: 350px;
        background-color: #4a9bc0;
        color: white;
      `}
    svg {
      margin-right: 5px;
      font-size: 1rem;
    }
  `,
}

const Favourites = () => {
  const dispatch = useDispatch()
  const [showEnquireForm, setShowEnquireForm] = useState(false)
  const [popup, setPopup] = useState(null)
  const [lotData, setLotData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const houses = useSelector(getFavouritePackages)
  const lots = useSelector(getFavouriteLots)
  const map = useSelector(getMap)
  const padding = useSelector(getMapPadding)
  const isSalesMode = useSelector(isSalesVersion)
  const showFavButton = !isMobile() || !!lots.length || !!houses.length
  useEffect(() => {
    dispatch(setToggleButtonVisibility(!showFavButton))
    return function () {
      dispatch(setToggleButtonVisibility(false))
    }
  }, [showFavButton])
  const zoomTo = (lot) => {
    const coordinates =
      lot.geo?.geometry?.coordinates[0].length === 1
        ? lot.geo?.geometry?.coordinates[0]
        : lot.geo?.geometry?.coordinates

    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0]),
    )
    map.fitBounds(bounds, {
      padding,
    })
  }

  const onCloseModal = () => setShowModal(false)

  const handleHouseView = (house) => {
    setShowModal(true)
    setLotData(house)
    zoomTo(house)
  }
  // bathrooms | bedrooms | cars
  const handleSort = (sortBy) => {
    dispatch(sortHouses(sortBy))
  }

  const valuetext = (value) => {
    return `${value}m`
  }

  const onLotMouseLeaveHandler = () => {
    if (popup) {
      popup.remove()
    }

    setPopup(null)
  }

  const onLotDeleteHandler = (lot) => {
    dispatch(addLotToFavourites(lot.id))
  }

  const onPackageDeleteHandler = (p) => {
    dispatch(addPackageToFavourites(p.id))
  }

  const saveForLaterHandler = () => {
    if (!lots.length && !houses.length) {
      displayToast('Please add at least one favourite lot or package to Save your list', 'error')
      return
    }
    setShowModal(true)
  }

  const displayToast = (body, type) => {
    dispatch(
      showToast({
        body,
        type,
      }),
    )
  }

  const onClickLotBackButton = () => {
    setLotData(null)
  }

  const onViewClickHandler = () => {
    setShowEnquireForm(true)
  }

  const onSave = async (formData) => {
    const data = {
      ...formData,
      lots: lots.map((lot) => lot.id),
      packages: houses.map((house) => house.id),
    }

    try {
      await dispatch(Api.postFavourites(data))
      displayToast(
        'Success, your favourites are saved for later, check your inbox to get the access link',
        'success',
      )
      setShowModal(false)
      setShowEnquireForm(false)
    } catch (e) {
      displayToast('Error, something went wrong try again later', 'error')
    }
  }

  if (showModal) {
    return (
      <S.Container style={isSalesMode ? { justifyContent: 'center' } : undefined}>
        <PropertyModalv2
          lotId={lotData.id}
          type="favourites"
          handleSave={onSave}
          handleClose={onCloseModal}
        />
      </S.Container>
    )
  }

  if (showEnquireForm) {
    return (
      <S.Container style={isSalesMode ? { justifyContent: 'center' } : undefined}>
        <PropertyModalv2
          lotId={lotData.id}
          handleSave={onSave}
          handleClose={() => setShowEnquireForm(false)}
        />
      </S.Container>
    )
  }

  if (lotData) {
    return (
      <S.Container>
        <BackButton onClickHandler={onClickLotBackButton}></BackButton>
        <LotCard lot={lotData} handleView={onViewClickHandler} />
      </S.Container>
    )
  }

  if (isSalesMode) {
    let compiledList = [
      ...lots,
      ...houses
        .filter((house) => !!house.lot)
        .map((house) => ({
          ...house.lot,
        })),
    ]
    const showcasePackages = houses.filter((house) => !house.lot)
    compiledList = compiledList
      .filter((lot, index) => compiledList.findIndex((i) => i.id === lot.id) === index)
      .sort((a, b) => a.humanId - b.humanId)
    return (
      <S.Container>
        {compiledList.length || showcasePackages.length ? (
          <div class="scroll">
            {showcasePackages?.length && (
              <LotCard
                key="showcase"
                showcasePackages={showcasePackages}
                handleView={onViewClickHandler}
                favouriteMode={true}
              />
            )}
            {compiledList.map((lot) => (
              <LotCard
                key={lot.id}
                lot={lot}
                handleView={onViewClickHandler}
                favouriteMode={true}
              />
            ))}
          </div>
        ) : (
          <S.Empty>
            <S.EmptyP>
              It looks like you haven't added any Land or H&L Packages to your favourites.
              <br />
              <br />
              Click on the heart (❤) icon on any property to add it to your list and save it for
              later.
            </S.EmptyP>
          </S.Empty>
        )}
        {renderAuxNavigation(
          <S.Enquire onClick={saveForLaterHandler}>
            <MailIcon /> Save Favourites
          </S.Enquire>,
        )}
      </S.Container>
    )
  }
  return (
    <>
      {showFavButton ? (
        <S.Enquire onClick={saveForLaterHandler}>
          <MailIcon /> Save Favourites
        </S.Enquire>
      ) : null}

      <S.Container showFavButton={showFavButton}>
        <S.LotsWrapper>
          <S.Header>Land</S.Header>
          {lots.length > 0 && (
            <S.CardListWrapper>
                <S.CardsContainer>
                  {lots.map((lot) => (
                        <S.LotCard
                          lot={lot}
                          handleView={onViewClickHandler}
                          hidePackages={true}
                          expandable={false}
                        />
                  ))}
                </S.CardsContainer>
            </S.CardListWrapper>
          )}
          {lots.length === 0 && (
            <S.Empty>
              <S.EmptyP>
                Looks like you haven't added any Land to your favourites yet. Click the heart icon
                in the top right of any Property card to get started.
              </S.EmptyP>
            </S.Empty>
          )}
        </S.LotsWrapper>

        <S.PackagesWrapper>
          <S.Header>House & Land Packages</S.Header>
          {houses.length > 0 && (
            <S.CardListWrapper>
                <S.CardsContainer>
                  {houses.map((packageItem) => (
                        <PackageCard
                          className="package-card"
                          house={packageItem}
                          title={packageItem.houseType.name}
                          subtitle={packageItem.houseType.builder.name}
                          builderName={
                            packageItem.houseType.builder ? packageItem.houseType.builder.name : '-'
                          }
                          builderLogo={packageItem.houseType?.builder?.thumbnailUrl}
                          handleView={onViewClickHandler}
                          bed={packageItem.houseType.bed}
                          bath={packageItem.houseType.bath}
                          car={packageItem.houseType.car}
                          area={packageItem.houseType.area}
                          price={packageItem.price}
                          land={packageItem.lot?.land}
                          frontage={packageItem.lot?.dimensions?.width}
                          status={packageItem.lot?.status}
                          image={packageItem.facade?.url}
                          pdf={packageItem.pdf?.url}
                          lotNumber={packageItem.lot?.humanId}
                          mediaType="package"
                          mediaId={packageItem.id}
                        />
                  ))}
                </S.CardsContainer>
            </S.CardListWrapper>
          )}
          {houses.length === 0 && (
            <S.Empty>
              <S.EmptyP>
                It looks like you haven't added any Land to your favourites yet. Click the heart
                icon in the top right of any Property card to get started.
              </S.EmptyP>
            </S.Empty>
          )}
        </S.PackagesWrapper>
      </S.Container>
    </>
  )
}

export default Favourites
