import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled, { css } from 'styled-components'
import mapboxgl from 'mapbox-gl'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { vh, isMobile } from '../utils/functions'
import {
  changeTab,
  hideLotPopup,
  toggleContentVisibility,
  setContentVisibility,
  setSelectedPoi,
} from '../redux/actions'
import './categoryTabs/Nearby.css'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import { hideNearbyAll } from '../mapLayers/points'
import { hideOnsiteAll } from '../mapLayers/pointsInternal'
import {
  getCategorizedNearbyPoints,
  getCategorizedOnsitePoints,
  getMap,
  getAvailableLotsCoordinates,
  isSalesVersion,
  getProject,
} from '../redux/selectors'

const S = {
  Navigation: styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 25rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      margin-right: 0;
      margin-left: 0;
      justify-content: space-around;
      background-color: #f3f6fb;
      max-height: 88px;
      z-index: 110;
    }
    ${({ theme }) =>
      (theme.isEmbeddedMode &&
        css`
          @media screen and (max-width: 991px) {
            display: none;
            width: 0;
          }
        `) ||
      (theme.isSalesMode &&
        css`
          
          min-width: 650px;
          margin-right: auto;
          margin-bottom: 0rem;
          margin-left: auto;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          border: 1px none #000;
          height: 80px;
        `)}
  `,
  AuxNav: styled.div`
    ${({ visible }) =>
      visible
        ? css`
            display: flex;
            margin-right: 2rem;
            bottom: 0;
            height: 100%;
            justify-content: center;
            align-items: center;
            z-index: 5;
          `
        : css`
            display: none;
          `}
  `,
  RightButtonWrap: styled.div`
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;

    display: flex;
    margin-right: 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    a.enquireButton {
      display: inline-block;
      padding: 9px 15px;
      background-color: #3898ec;
      color: white;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer;

      width: auto;
      margin-right: 1rem;
      padding: 0.4rem 2rem;
      border-radius: 100px;
      font-size: 1rem;
      text-align: center;
    }
    a.intrapacButton {
      padding: 0.4rem 0.9rem 0.4rem 1rem;
      border-radius: 50px;
      background-color: #000;
      max-width: 100%;
      display: inline-block;
      img.intrapacLogoButton {
        width: 4.7rem;
      }
    }
  `,

  VisibilityToggle: styled.div`
    position: absolute;
    display: flex;
    left: -10vw;
    bottom: 0;
    height: 100%;
    //width: 11vw;
    justify-content: flex-end;
    align-items: center;
    z-index: 5;
    div {
      cursor: pointer;
      display: flex;
      padding: 0.2rem 0.7rem 0.2rem 0.7rem;
      align-items: center;
      background-color: #5ea5ca;
      color: #fff;
      text-decoration: none;
      border-radius: 100px;
      border: 1px solid #5ea5ca;
      position: relative;
      width: auto;
      height: auto;
      span {
        align-self: flex-start;
      }
      img.arrow {
        width: 0.7rem;
        height: auto;
        margin-right: 0.3rem;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
      img.info {
        width: 0.9rem;
        height: 0.9rem;
        margin-left: 0.3rem;
      }
      ${({ contentPaneVisible }) =>
        contentPaneVisible &&
        css`
          cursor: pointer;
          display: flex;
          background-color: #fff !important;
          border: 1px solid #b3c7d3;
          span {
            color: #000;
          }
          img.arrow {
            -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: rotate(270deg);
          }
        `}
    }
  `,
  Item: styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      max-width: unset;
      flex: 1;
    }
    &:hover {
      opacity: 1;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        flex-direction: row;
        margin-right: 1vw;
      `}
  `,
  Icon: styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
    opacity: 0.5;
    img {
      width: 1rem;
      height: 1rem;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
    div._w-logo {
      font-family: alverata, sans-serif;
      color: #fff;
      font-weight: 900;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 0.5rem;
      `}
    ${({ active }) =>
      active &&
      css`
        box-shadow: 0 7px 22px -10px rgba(0, 0, 0, 0.86);
        opacity: 1;
      `}

    ${({ background, active }) =>
      background === 'internalPois' &&
      css`
        display: flex;
        background-color: #86c18f;
      `}


    ${({ background, active }) =>
      background === 'nearby' &&
      css`
        border-radius: 200px;
        background-color: #ffdaaa;
      `}
    ${({ background, active }) =>
      background === 'land' &&
      css`
        border-radius: 200px;
        background-color: #e6e5ff;
      `}
    ${({ background, active }) =>
      background === 'homes' &&
      css`
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0rem;
        justify-content: center;
        align-items: center;
        background-color: rgba(186, 218, 255, 0.4);
      `}      
    ${({ background, active }) =>
      background === 'favourites' &&
      css`
        background-color: rgba(255, 209, 200, 0.36);
      `}
  `,
  Label: styled.div`
    padding-top: 0.5rem;
    opacity: 0.5;
    font-family: Karla, sans-serif;
    color: #4e4e4e;
    font-size: 0.8rem;
    font-weight: 400;
    text-decoration: none;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        padding-top: 0rem;
        font-size: 0.9rem;
      `}
    ${({ active }) =>
      active &&
      css`
        opacity: 1;
        color: #4c4949;
        font-weight: 400;
        text-shadow: 0 5px 2px rgba(0, 0, 0, 0.1);
      `}
  `,
}

function getSatelliteVisibility(project, activeTab, isSalesMode, isMobile) {
  const config = project?.satelliteLayerVisibility || {
    internalPois: 'salesModeOnly',
    land: 'salesModeOnly',
    nearby: 'all',
    homes: 'salesModeOnly',
    favourites: 'salesModeOnly',
  }
  const visibility = config[activeTab]
  switch (visibility) {
    case 'salesModeOnly':
      return isSalesMode ? 'visible' : 'none'
    case 'salesModeAndDesktop':
      return isMobile ? 'none' : 'visible'
    case 'none':
      return 'none'
    default:
      return 'visible'
  }
}

const Navigation = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector((state) => state.activeTab)
  const project = useSelector(getProject)
  const paddingRefs = useSelector((state) => state.mapPaddingRefs)
  const map = useSelector(getMap)
  const { lngLatBounds, zoomLevel: maxZoom } = useSelector(getProject) || {}
  const isSalesMode = useSelector(isSalesVersion)
  const contentPaneVisible = useSelector((state) => state.contentPaneVisible)
  const categorizedNearbyPoints = useSelector(getCategorizedNearbyPoints)
  const categorizedOnsitePoints = useSelector(getCategorizedOnsitePoints)
  const availableLotsCoordinates = useSelector(getAvailableLotsCoordinates)
  const { frontendEnabledTabs = {} } = project || {}
  useEffect(() => {
    if (map)
      map.on('style.load', () => {
        if (map.getLayer('mapbox-satellite')) {
          map.setLayoutProperty(
            `mapbox-satellite`,
            'visibility',
            getSatelliteVisibility(project, activeTab, isSalesMode, isMobile()),
          )
        }
      })
  }, [map])

  useEffect(() => {
    const availableTabs = Object.keys(frontendEnabledTabs).filter((k) => frontendEnabledTabs[k])
    if (map && availableTabs.indexOf(activeTab) === -1) handleChangeTab(availableTabs[0])
  }, [map, frontendEnabledTabs])

  useEffect(() => {
    if (map && map.getLayer('mapbox-satellite'))
      map.setLayoutProperty(
        `mapbox-satellite`,
        'visibility',
        getSatelliteVisibility(project, activeTab, isSalesMode, isMobile()),
      )
  }, [map, activeTab, project])
  const handleChangeTab = (tab) => {
    if (isMobile()) {
      if (tab === 'homes') dispatch(setContentVisibility(true))
      else if (tab === 'land') dispatch(setContentVisibility(false))
    }
    if (activeTab === tab) {
      if (tab === 'nearby' || tab === 'internalPois' || tab === 'land' || tab === 'homes') {
        // reset tab to default state on click
        dispatch(setSelectedPoi())
        dispatch(hideLotPopup())
        dispatch(changeTab())
        setTimeout(() => dispatch(changeTab(tab)), 100)
      } else {
        dispatch(changeTab(tab))
      }
      return
    }
    hideNearbyAll(map, categorizedNearbyPoints)
    hideOnsiteAll(map, categorizedOnsitePoints)
    dispatch(hideLotPopup())
    dispatch(changeTab(tab))

    if (tab === 'internalPois') {
      map.fitBounds(lngLatBounds, {
        pitch: 0,
        padding: isSalesMode ? { bottom: vh(16) } : undefined,
        maxZoom,
      })
    }
    if (tab === 'nearby') {
      map.easeTo({ pitch: 0 })
    }
  }

  const toggleVisibility = () => {
    if (paddingRefs?.bottom?.current) {
      const rect = paddingRefs.bottom.current.getBoundingClientRect()
      document.documentElement.style.setProperty(
        '--content-pane-height',
        (rect?.height || 700) + 'px',
      )
    }
    dispatch(toggleContentVisibility())
  }

  return (
    <>
      <S.Navigation>
        {isSalesMode && (
          <S.VisibilityToggle contentPaneVisible={contentPaneVisible}>
            <div onClick={toggleVisibility}>
              <img
                className="arrow"
                src={contentPaneVisible ? '/images/back-arrow-black.svg' : '/images/back-arrow.svg'}
                alt=""
              />
              <span>{contentPaneVisible ? 'Hide' : 'Show'}</span>
              <img
                className="info"
                src={contentPaneVisible ? '/images-new/info.svg' : '/images-new/info-white.svg'}
                alt=""
              />
            </div>
          </S.VisibilityToggle>
        )}
        {frontendEnabledTabs.internalPois && (
          <S.Item onClick={() => handleChangeTab('internalPois')}>
            <S.Icon active={activeTab === 'internalPois'} background="internalPois">
              <div className="_w-logo">{project.frontendTabInitial}</div>
            </S.Icon>
            <S.Label active={activeTab === 'internalPois'}>{project.frontendTabName}</S.Label>
          </S.Item>
        )}

        {frontendEnabledTabs.nearby && (
          <S.Item onClick={() => handleChangeTab('nearby')}>
            <S.Icon active={activeTab === 'nearby'} background="nearby">
              <img src="/images-new/nearby.svg" alt="" />
            </S.Icon>
            <S.Label active={activeTab === 'nearby'}>Nearby</S.Label>
          </S.Item>
        )}

        {frontendEnabledTabs.land && (
          <S.Item onClick={() => handleChangeTab('land')}>
            <S.Icon active={activeTab === 'land'} background="land">
              <img
                src="/images-new/land-icon.png"
                style={{ width: '1.5rem', height: 'auto' }}
                alt=""
              />
            </S.Icon>
            <S.Label active={activeTab === 'land'}>Land</S.Label>
          </S.Item>
        )}

        {frontendEnabledTabs.homes && project.id !== '602cd0ae1861960017ce4319' && (
          <S.Item onClick={() => handleChangeTab('homes')}>
            <S.Icon active={activeTab === 'homes'} background="homes">
              <img src="/images-new/property.svg" alt="" />
            </S.Icon>
            <S.Label active={activeTab === 'homes'}>Homes</S.Label>
          </S.Item>
        )}

        {frontendEnabledTabs.favourites && (
          <S.Item onClick={() => handleChangeTab('favourites')}>
            <S.Icon active={activeTab === 'favourites'} background="favourites">
              <img src="/images-new/favourites.svg" alt="" />
            </S.Icon>
            <S.Label active={activeTab === 'favourites'}>Favourites</S.Label>
          </S.Item>
        )}        
      </S.Navigation>
      {isSalesMode && (
        <S.RightButtonWrap>
          <a
            href="#"
            class="enquireButton"
            onClick={(e) => {
              e.preventDefault()
              handleChangeTab('enquire')
            }}
          >
            Enquire
          </a>
          <a
            href="#"
            class="intrapacButton"
            onClick={(e) => {
              e.preventDefault()
              handleChangeTab('intrapac')
            }}
          >
            <img
              src="images-new/intrapac-logo-abbreviated.svg"
              loading="lazy"
              alt=""
              class="intrapacLogoButton"
            />
          </a>
        </S.RightButtonWrap>
      )}
    </>
  )
}

export default Navigation
