/* 
  INTERNAL POIs - layers, source, setting visibility
*/

import { showPointPopup, setSelectedPoi, changeTab } from '../redux/actions'
import { scaleToMapIcon } from '../utils/functions'
const DEFAULT_ICON = '/images-new/mapicon-placeholder.png'

export const attachOnsitePointsToTheMap = async (map, points, project, dispatch) => {
  if (!points.length) return
  function loadImage(value) {
    return new Promise((resolve, reject) => {
      map.loadImage(value, (err, img) => {
        if (err)
          return reject(err)
        return resolve(img)
      })
    });
  };
  map.addLayer({
    id: 'poiRef',
    type: 'symbol',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          { 
            "type": "Feature", 
            "properties": null, 
            "geometry": {
              "type":"Point",
              "coordinates":[]
            } 
          }
        ],
      },
    },
    layout: {
      visibility: 'none',
    },    
  });

  const promises = points.map(async (cat) => {
    const pointCategory = { ...cat }
    const name = `onsite-${pointCategory.name}`
    const customIcons = []
    pointCategory.items = pointCategory.items.map((poi) => {
      let imageName = `${name}-image`
      if (poi.properties.mapIcon) {
        imageName = `poi-${poi.id}-icon`
        customIcons.push({imageName, iconURL: poi.properties.mapIcon})
      }
      return {
        ...poi,
        properties: {
          ...poi.properties,
          imageName
        }
      }
    })
    try {
      const r = await Promise.all(customIcons.map(async ({imageName, iconURL}) => {
        const image = await loadImage(scaleToMapIcon(iconURL))
        map.addImage(imageName, image)
      }))
    } catch (e) {
      console.log("*** error loading custom map icons", e)
    }

    const image = await loadImage(
      scaleToMapIcon(pointCategory.imageSelected?.url || DEFAULT_ICON)
    );
    const clusterImage = await loadImage(
      scaleToMapIcon(pointCategory.imageCluster?.url || pointCategory.clusterImageUrl || DEFAULT_ICON, null)
    )

    map.addImage(`cluster-${name}`, clusterImage)
    map.addImage(`${name}-image`, image)
    addLayers(
      map,
      pointCategory.items,
      name,
      pointCategory.clusterTextColor || "#ffffff",
      project,
      dispatch,
    )
  })
  await Promise.all(promises)
}

export const showOnsiteAll = (map, categorizedPoints, minZoom = 1, maxZoom = 24) => {
  categorizedPoints.forEach(({ name }) => {
    if (map.getLayer(`onsite-${name}-count`)) {
      map.setLayoutProperty(`onsite-${name}-count`, 'visibility', 'visible')
      map.setLayerZoomRange(`onsite-${name}-count`, minZoom, maxZoom)
    }

    if (map.getLayer(`onsite-${name}-clusters`)) {
      map.setLayoutProperty(`onsite-${name}-clusters`, 'visibility', 'visible')
      map.setLayerZoomRange(`onsite-${name}-clusters`, minZoom, maxZoom)
    }

    if (map.getLayer(`onsite-${name}-single`)) {
      map.setLayoutProperty(`onsite-${name}-single`, 'visibility', 'visible')
      map.setLayerZoomRange(`onsite-${name}-single`, minZoom, maxZoom)
    }

    if (map.getLayer(`onsite-${name}-polygons-glow`)) {
      map.setLayoutProperty(`onsite-${name}-polygons-glow`, 'visibility', 'visible')
      map.setLayerZoomRange(`onsite-${name}-polygons-glow`, minZoom, maxZoom)
    }

    if (map.getLayer(`onsite-${name}-polygons-outline`)) {
      map.setLayoutProperty(`onsite-${name}-polygons-outline`, 'visibility', 'visible')
      map.setLayerZoomRange(`onsite-${name}-polygons-outline`, minZoom, maxZoom)
    }
  })
}

export const hideOnsite = (map, name) => {
  if (map.getLayer('route-onsite')) {
    map.setLayoutProperty('route-onsite', 'visibility', 'none')
  }

  if (map.getLayer(`onsite-${name}-count`)) {
    map.setLayoutProperty(`onsite-${name}-count`, 'visibility', 'none')
  }

  if (map.getLayer(`onsite-${name}-clusters`)) {
    map.setLayoutProperty(`onsite-${name}-clusters`, 'visibility', 'none')
  }

  if (map.getLayer(`onsite-${name}-single`)) {
    map.setLayoutProperty(`onsite-${name}-single`, 'visibility', 'none')
  }

  if (map.getLayer(`onsite-${name}-polygons-glow`)) {
    map.setLayoutProperty(`onsite-${name}-polygons-glow`, 'visibility', 'none')
  }

  if (map.getLayer(`onsite-${name}-polygons-outline`)) {
    map.setLayoutProperty(`onsite-${name}-polygons-outline`, 'visibility', 'none')
  }
}

export const hideRouteDirections = (map) => {
  if (map.getLayer('route-onsite')) {
    map.setLayoutProperty('route-onsite', 'visibility', 'none')
  }
}

export const hideOnsiteAll = (map, points) => {
  if (map.getLayer('route-onsite')) {
    map.setLayoutProperty('route-onsite', 'visibility', 'none')
  }

  return points.forEach(({ name }) => hideOnsite(map, name))
}

function addLayers(map, points = [], name, clusterTextColor, project, dispatch) {
  const mapStyle = project?.mapboxStyleUrl || undefined;
  map.addSource(`${name}-source`, {
    type: 'geojson',
    generateId: true,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
    data: {
      type: 'FeatureCollection',
      features: points.filter((point) => point.geometry.type === 'Point'),
    },
  })

  const polygons = points
    .filter((point) => point.boundries)
    .map((point) => ({
      ...point.boundries,
    }))

  map.addLayer({
    id: `${name}-polygons-outline`,
    type: 'line',
    minZoom: 17,
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: polygons,
      },
    },
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 100%)',
      'line-width': 2,
    },
  })

  map.addLayer({
    id: `${name}-polygons-glow`,
    type: 'line',
    minZoom: 17,
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: polygons,
      },
    },
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 100%)',
      'line-blur': 10,
      'line-opacity': 0.38,
      'line-width': 10,
    },
  })

  map.addLayer({
    id: `${name}-single`,
    type: 'symbol',
    source: `${name}-source`,
    filter: ['!', ['has', 'point_count']],
    minZoom: 17,
    layout: {
      'text-field': ['get', 'name'],
      'text-variable-anchor': ['bottom', 'top', 'left', 'right'],
      'text-radial-offset': 1.5,
      'text-justify': 'auto',
      visibility: 'none',
      'icon-image': ['get', 'imageName'],
      'icon-allow-overlap': true,
      // 'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.1, 13, 0.25, 22, 0.35],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 12, 0.6, 18, 1],
      'text-anchor': 'bottom',
      ...(mapStyle ? {'text-font': ['Sofia Pro Regular', 'Arial Unicode MS Regular']} : undefined),
      'text-letter-spacing': 0.04,
      'text-line-height': 1.1,
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        ['literal', [0, -1.1]],
        18,
        ['literal', [0, -1.75]],
      ],
      'text-pitch-alignment': 'viewport',
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 14, 18, 16],
    },
    paint: {
      'text-color': 'hsl(0, 0%, 26%)',
      'text-halo-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
  })

  map.addLayer({
    id: `${name}-count`,
    type: 'symbol',
    source: `${name}-source`,
    filter: ['has', 'point_count'],
    minZoom: 17,
    layout: {
      visibility: 'none',
      'icon-image': `cluster-${name}`,
      'text-field': '{point_count_abbreviated}',
      ...(mapStyle ? {'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold']} : undefined),      
      'icon-size': ['interpolate', ['linear'], ['zoom'], 12, 0.6, 18, 1],
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 18, 42],
      'text-offset': [0, 0.1],
    },
    paint: {
      'text-color': clusterTextColor,
      'text-halo-color': 'hsla(0, 0%, 0%, 0)',
    },
  })

  map.on('click', `${name}-single`, async function (e) {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [`${name}-single`],
    })    
    dispatch(changeTab('internalPois'))
    dispatch(setSelectedPoi(features[0]))
    dispatch(showPointPopup(features[0].properties))
  })

  map.on('mouseenter', `${name}-single`, function () {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', `${name}-single`, function () {
    map.getCanvas().style.cursor = ''
  })

  map.on('click', `${name}-count`, function (e) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: [`${name}-count`],
    })
    var clusterId = features[0].properties.cluster_id
    map.getSource(`${name}-source`).getClusterExpansionZoom(clusterId, function (err, zoom) {
      if (err) return

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom,
      })
    })
  })

  map.on('mouseenter', `${name}-count`, function () {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', `${name}-count`, function () {
    map.getCanvas().style.cursor = ''
  })
}
