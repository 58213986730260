import React, { useRef, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated, config } from 'react-spring'
import styled, { css } from 'styled-components'
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons'
import './Nearby.css'
import {
  getGeoPoints,
  getAllOnsitePoints,
  getCategorizedOnsitePoints,
  getMap,
  getMapPadding,
  getSelectedPoi,
  isSalesVersion,
  getProject,
} from '../../redux/selectors'
import Poi from './Poi'

import {
  showOnsiteAll
} from '../../mapLayers/pointsInternal'
import {
  showPointPopup,
  setOnsitePointsInitialized,
  setSelectedPoi,
  setMapPaddingRefs,
  setToggleButtonVisibility,
} from '../../redux/actions'
import { vh, isMobile, renderAuxNavigation, renderStandaloneCard, rsz } from '../../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'

function easing(t) {
  return 1 - Math.pow(1 - t, 5)
}

const S = {
  Wrapper: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      padding: 5.2vw;
      background: #f1f5fa;
      height: calc(100vh - 80px);
      height: calc(var(--vh, 1vh) * 100 - 80px);
      overflow-y: scroll;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 0;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }        
      `}
  `,
  ExpandMarker: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
      display &&
      css`
          display: flex;
          position: absolute;
          top: -12px;
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          div {
            display: flex;
            color: #333;
            box-shadow: 0 12px 33px -8px #2b2b2b;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            background: #fff;
            justify-content: center;
            align-items: center;
          }
        `}

      ${({ expanded }) =>
      expanded &&
      css`
          position: fixed;
          top: 0px;
          width: 100vw;
          z-index: 100;
          padding-bottom: 40px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          div {
            margin-top: 0.9rem;
          }
        `}
    }
  `,
  PoiCard: styled(animated.div)`
    width: 100vw;
    position: absolute;
    left: 0;
    background: #fff;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  PoiWrapper: styled(animated.div)`
    min-height: calc(50vh - 88px);
    min-height: calc(var(--vh, 1vh) * 50 - 88px);
    ${({ expanded }) =>
      expanded &&
      css`
        height: calc(100vh - 80px);
        height: calc(var(--vh, 1vh) * 100 - 8px);
        overflow-y: scroll;
      `}
  `,
  Container: styled.div`
    position: relative;
    z-index: 0;
    height: 100%;
    padding-top: 1rem;
    height: auto;
    //margin-top: -1rem;
    //padding-bottom: 2.5rem;
    h1 {
      margin-top: 1rem;
      margin-bottom: 2.5rem;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.88);
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        height: initial;
        //margin-top: initial;
        padding-top: 0;
        align-items: center;
        justify-content: center;
        min-width: fit-content;
        h1 {
          text-align: center !important;
        }
      `}
  `,
  SecondaryNav: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      justify-content: flex-end;
      align-items: flex-end;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        justify-content: center;
      `}
  `,
  BackButton: styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0.2rem 1.5rem 0.2rem 0.7rem;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #5ea5ca;
    color: #fff;
    text-decoration: none;
    img {
      width: 0.7rem;
      height: auto;
      margin-right: 0.3rem;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      align-self: flex-start;
      flex: initial;
      margin-right: 5.2vw;
      width: max-content;
    }
  `,
  BlurredBackground: styled.div`
    position: absolute;
    left: 0%;
    top: 0;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    overflow: hidden;
    width: 30rem;
    height: 38vh;

    max-width: 30rem;
    margin-top: 7.3rem;
    ${({ theme }) =>
      theme.isEmbeddedMode &&
      css`
        @media screen and (max-width: 991px) {
          margin-top: 0rem;
        }
      `}
    margin-left: 0rem;
    padding-top: 0rem;
    -o-object-fit: fill;
    object-fit: fill;
    img {
      position: relative;
      overflow: hidden;
      width: 32rem;
      height: 100%;
      max-width: none;
      margin-left: -2rem;
      -o-object-fit: cover;
      object-fit: cover;
    }
    div {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(69%, hsla(0, 0%, 100%, 0)),
          to(#f3f6fb)
        ),
        -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.66)), color-stop(35%, hsla(0, 0%, 100%, 0)));
      background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 69%, #f3f6fb),
        linear-gradient(180deg, rgba(0, 0, 0, 0.66), hsla(0, 0%, 100%, 0) 35%);
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 0;
      margin-left: 0;
      width: 100vw;
      max-width: initial;
      img {
        width: 100vw;
        max-width: initial;
        margin-left: 0;
      }
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        height: 100%;
        width: 100% !important;
        max-width: initial !important;
        margin-top: 0;
        margin-left: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;

        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(25%, rgba(0, 0, 0, 0.81)),
            to(rgba(0, 0, 0, 0.64))
          ),
          url('/images-new/internal-bg.jpg');
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.81) 25%, rgba(0, 0, 0, 0.64)),
          url('/images-new/internal-bg.jpg');
        background-position: 0px 0px, 50% 50%;
        background-size: auto, cover;
        opacity: 0.72;

        img {
          display: none !important;
        }
        div {
          display: none !important;
        }
      `}
  `,
  CategoryListWrapper: styled.div`
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-wrap: nowrap;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 1rem;
        padding-bottom: 2rem;
        overflow-x: scroll;
        align-items: center;
        justify-content: center;
        align-self: center;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }

        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
        }
      `}
  `,
  CategoryWrapper: styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: 300px;
    height: auto;
    margin-bottom: 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    margin: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-shadow: 0 13px 38px -16px rgba(0, 0, 0, 0.44);
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: 10vw;
        margin-right: 2vw;
        min-width: 22rem;
        min-height: 13rem;
        display: initial;
        margin-bottom: 0;
        background-color: rgba(23, 24, 36, 0.35);
        &:hover {
          box-shadow: 0 0 20px -3px #7bc4eb;
        }
      `}
  `,
  CategoryHeader: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      width: 1.8rem;
      height: auto;
      margin-right: 0.5rem;
      margin-left: -0.4rem;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 0.95rem;
      font-weight: 700;
      ${({ theme }) =>
      theme.isSalesMode &&
      css`
          color: white;
        `}
    }
  `,
  Poi: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
    cursor: pointer;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .checkbox {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 1rem;
      margin-bottom: 0.3rem;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: #ccc;
      border-radius: 200px;
    }
    .poiTitle {
      padding: 0;
      margin: 0;
      h2 {
        padding: 0;
        padding-bottom: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Karla, sans-serif;
        color: #757575;
        font-size: 0.9rem;
        line-height: 1.5;
        font-weight: 400;
        ${({ theme }) =>
      theme.isSalesMode &&
      css`
            color: white;
          `}
      }
    }
  `,
}

const BlurredBackground = ({ src }) => (
  <S.BlurredBackground>
    <img src={src} />
    <div />
  </S.BlurredBackground>
)
const BackButton = ({ onClick }) => (
  <S.BackButton onClick={onClick}>
    <img src="/images/back-arrow.svg" alt="" />
    <div>Back</div>
  </S.BackButton>
)
const InternalPois = () => {
  const dispatch = useDispatch()
  const project = useSelector(getProject)
  const map = useSelector(getMap)
  const { lngLatBounds, zoomLevel: maxZoom } = useSelector(getProject) || {}
  const destinationPopupData = useSelector((state) => state.pointPopup)
  const geoPoints = useSelector(getGeoPoints)
  const categorizedPoints = useSelector(getCategorizedOnsitePoints)
  const onsitePoints = useSelector(getAllOnsitePoints)
  const pointsOnsiteInitialized = useSelector((state) => state.pointsOnsiteInitialized)
  const selectedPoi = useSelector(getSelectedPoi)
  const mapPadding = useSelector(getMapPadding)
  const poicardRef = useRef()
  const setPoi = (poi) => dispatch(setSelectedPoi(poi))
  const isSalesMode = useSelector(isSalesVersion)
  const [expanded, setExpanded] = useState(false)
  const padding = useMemo(
    () => ({
      top: (mapPadding?.top || 0) + 50,
      bottom: (mapPadding?.bottom || 0) + 50,
      left: (mapPadding?.left || 0) + 50,
      right: (mapPadding?.right || 0) + 50,
    }),
    [mapPadding],
  )
  useEffect(() => {
    setPoi(null)
  }, [])
  
  useEffect(() => {
    if (categorizedPoints?.length && pointsOnsiteInitialized) {
      showOnsiteAll(map, categorizedPoints)
    }
  }, [categorizedPoints, pointsOnsiteInitialized, dispatch])    

  const expandable = isMobile() && !!selectedPoi
  const bottomOffset = 88
  const height = window.innerHeight / 2 - bottomOffset
  const [{ y }, set, stop] = useSpring(() => ({ y: 0 }))
  const open = () => {
    set({
      y: 0 - window.innerHeight + bottomOffset + height,
      immediate: false,
      config: config.stiff,
    })
  }
  const close = () => {
    set({ y: 0, immediate: false, config: config.stiff })
  }
  useEffect(() => {
    if (isMobile()) {
      dispatch(setMapPaddingRefs({ bottom: poicardRef }))
      return function cleanup() {
        dispatch(setMapPaddingRefs({ bottom: null }))
      }
    }
  }, [expandable, poicardRef.current?.offsetHeight])

  useEffect(() => {
    setTimeout(() => {
      if (selectedPoi) {
        dispatch(setToggleButtonVisibility(false))
        const coords = selectedPoi.geometry.coordinates
        map &&
          map.fitBounds([coords, coords], {
            pitch: 45,
            bearing: 0,
            zoom: 19,
            maxZoom: 19,
            minZoom: 19,
            easing,
            duration: 1500,
            padding: isMobile() ? { bottom: height } : padding,
          })
      } else {
        dispatch(setToggleButtonVisibility(true))
        close()
        setExpanded(false)
        map &&
          map.fitBounds(lngLatBounds, {
            pitch: 0,
            bearing: 0,
            padding: isSalesMode ? { bottom: vh(16) } : undefined,
            easing,
            linear: true,
            duration: 2500,
            maxZoom,
          })
      }
    }, 200)
  }, [selectedPoi, map])

  useEffect(() => {
    dispatch(setToggleButtonVisibility(true))
    return function () {
      dispatch(setToggleButtonVisibility(false))
    }
  }, [dispatch])

  const onClickLotBackButton = () => {
    dispatch(showPointPopup(null))
    setPoi(null)
  }

  if (selectedPoi && isSalesMode)
    return (
      <>
        <Poi item={selectedPoi} />
        {renderAuxNavigation(
          <S.SecondaryNav>
            <BackButton onClick={onClickLotBackButton} />
          </S.SecondaryNav>,
        )}
      </>
    )
  if (expandable) {
    return renderStandaloneCard(
      <>
        <S.PoiCard
          ref={poicardRef}
          style={{ top: `calc(var(--vh, 1vh) * 100 - 88px - ${height}px)`, y }}
          expanded={expanded}
        >
          <S.PoiWrapper expanded={expanded}>
            <Poi item={selectedPoi} />
          </S.PoiWrapper>
          <S.ExpandMarker
            onClick={() => {
              if (expanded) {
                close()
                setTimeout(() => setExpanded(false), 100)
              } else {
                setExpanded(true)
                setTimeout(open, 100)
              }
            }}
            expanded={expanded}
            display={poicardRef.current?.offsetHeight > height}
          >
            <div>
              {expanded ? <ExpandMoreRounded size="large" /> : <ExpandLessRounded size="large" />}
            </div>
          </S.ExpandMarker>
        </S.PoiCard>
        <S.SecondaryNav style={{ width: 'min-content', marginLeft: '5.2vw' }}>
          <BackButton onClick={onClickLotBackButton} />
        </S.SecondaryNav>
      </>
    )
  }
  const backgroundImage = selectedPoi ? 
    rsz(selectedPoi.properties.facade, {
      width: 400,
      crop: 'fill',
      quality: 'auto',
    }) : '/images-new/internal-bg.jpg'
  return (
    <S.Wrapper className="wrapper2">        
      <BlurredBackground
        src={backgroundImage}
      />
      {selectedPoi ? (
        <>
          <S.SecondaryNav>
            <BackButton onClick={onClickLotBackButton} />
          </S.SecondaryNav>
          <Poi item={selectedPoi} />
        </>
      ) : (
        <S.Container>
          {!isSalesMode && <h1>{project.frontendName} Features</h1>}
          <S.CategoryListWrapper>
            {categorizedPoints.map((category) => (
              <S.CategoryWrapper key={category.name}>
                <S.CategoryHeader>
                  <img src={
                    rsz(category.imageSelected?.url, {
                      width: 400,
                      crop: 'fill',
                      quality: 'auto',
                    })} />
                  <h2>{category.name}</h2>
                </S.CategoryHeader>
                {category.items.map((poi, index) => (
                  <S.Poi
                    key={index}
                    onClick={() => {
                      setPoi(poi)
                    }}
                  >
                    <div className="checkbox" />
                    <div className="poiTitle">
                      <h2>{poi.properties.name}</h2>
                    </div>
                  </S.Poi>
                ))}
              </S.CategoryWrapper>
            ))}
          </S.CategoryListWrapper>
        </S.Container>
      )}
    </S.Wrapper>
  )
}

export default InternalPois
