import React from 'react'
import styled, { css } from 'styled-components'

const S = {
  StatusWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Status: styled.div`
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 10px;
    ${({ status }) =>
      (status === 'Available' &&
        css`
          background-color: #64fe40;
        `) ||
      ((status === 'Sold' || status === 'Settled' || status === 'Unreleased') &&
        css`
          background-color: #fe652b;
        `) ||
      ((status === 'Deposited' || status === 'On Hold') &&
        css`
          background-color: #ffcc31;
        `)}
  `,
  Text: styled.div`
    font-family: 'Sofia pro', Arial, sans-serif;
    color: #4c4949;
    font-size: 0.85rem;
    line-height: 0.85rem;
    font-weight: 400;
  `,
}

const Status = ({ status, text, className }) => (
  <S.StatusWrapper>
    <S.Status status={status} className={className}></S.Status>
    {text && <S.Text status={status}>{text === 'Settled' ? 'Sold' : text}</S.Text>}
  </S.StatusWrapper>
)
export const StatusCircle = S.Status 
export default Status
