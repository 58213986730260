import React from 'react'
import styled, { css } from 'styled-components'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
const S = {
  Container: styled.div`
    align-self: flex-start;
    display: flex;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      position: fixed;
      top: 1rem;
      left: 1rem;
      z-index: 102;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        align-self: center;
      `}
  `,
  BackButton: styled.a`
    cursor: pointer;
    display: flex;
    align-self: flex-start;
    padding: 0.2rem 1.5rem 0.2rem 0.7rem;
    background-color: #5ea5ca;
    color: #fff;
    text-decoration: none;
    border-radius: 100px;
    box-shadow: 0 12px 33px -8px #2b2b2b;
    position: relative;
    width: auto;
    height: auto;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      height: 27px;
    }

    span {
      align-self: flex-start;
    }
    img {
      width: 0.7rem;
      height: auto;
      margin-right: 0.3rem;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
  `,
}
const BackButton = ({ onClickHandler }) => {
  return (
    <S.Container>
      <S.BackButton onClick={onClickHandler}>
        <img src="/images/back-arrow.svg" alt="" />
        <span>Back</span>
      </S.BackButton>
    </S.Container>
  )
}

export default BackButton
