import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setAgreementModalType } from '../redux/actions'
import { getProject } from '../redux/selectors'

const S = {
  AgreementModal: styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 5vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.43);
    @media screen and (min-width: 1920px) {
      padding-bottom: 5vh;
      background-color: rgba(0, 0, 0, 0.65);
    }

    a.closeButton {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      height: auto;
      margin: 1rem 2rem 1.5rem;
      padding: 0.3rem 1.1rem 0.3rem 0.7rem;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
      border-radius: 100px;
      background-color: #53addb;
      color: #fff;
      text-align: center;
      text-decoration: none;
      -o-object-fit: fill;
      object-fit: fill;

      background-color: #53addb;
      margin-bottom: 1vw;
      position: absolute;
      left: 0%;
      top: auto;
      right: 0%;
      bottom: 0%;
      z-index: 6;
      width: 8vw;
      margin-right: auto;
      margin-bottom: 0.5vw;
      margin-left: auto;
      div {
        font-family: Karla, sans-serif;
        font-weight: 700;
        text-align: center;
        font-size: 1rem;
      }
    }

    div.overlay-wrap {
      position: relative;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      overflow: scroll;
      width: 25vw;
      height: 35vw;
      background-color: #fff;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (min-width: 1920px) {
      div.overlay-wrap {
        overflow: scroll;
        width: 15vw;
        height: 20vh;
      }
    }

    h1 {
      margin-bottom: 2rem;
      font-family: alverata, sans-serif;
      font-size: 2rem;
    }
    h3 {
      margin-bottom: 0.5rem;
      font-family: alverata, sans-serif;
      font-size: 1rem;
    }
    p {
      font-family: Karla, sans-serif;
    }
  `,
}
const AgreementModal = () => {
  const agreementModalType = useSelector((state) => state.agreementModalType)
  const project = useSelector(getProject)
  const dispatch = useDispatch()
  const close = (e) => {
    e.preventDefault()
    dispatch(setAgreementModalType())
  }
  return (
    !!agreementModalType && (
      <S.AgreementModal>
        <a href="#" class="closeButton" onClick={close}>
          <div class="text-block-5">Close</div>
        </a>
        <div class="overlay-wrap">
          <iframe src={project.privacyPolicyUrl} />
        </div>
      </S.AgreementModal>
    )
  )
}

export default AgreementModal
