import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Land from './categoryTabs/Land'
import Homes from './categoryTabs/Homes'
import Nearby from './categoryTabs/Nearby'
import InternalPois from './categoryTabs/InternalPois'
import Favourites from './categoryTabs/Favourites'
import Intrapac from './categoryTabs/Intrapac'
import PropertyModalv2 from './PropertyModalv2'
import { MOBILE_WIDTH_THRESHOLD } from '../config'

const S = {
  Content: styled.div`
    padding-top: 1rem;
    height: calc(100vh - 130px + 1rem);
    overflow-y: auto;
    overflow-x: visible;
    padding-right: 2rem;
    padding-left: 2rem;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      position: absolute;
      bottom: 88px;
      left: 0;
      width: 100vw;
      padding: 0 !important;
      height: calc(100vh - 80px);
      height: calc(var(--vh, 1vh) * 100 - 80px);
      z-index: 100;      
      overflow-y: hidden;
      pointer-events: none;
      > * {
        pointer-events: all;
      }
      
      ${({ visible }) =>
      visible
        ? css`
              display: block;
            `
        : css`
              display: none;
            `}}
    }
    @media screen and (max-width: 440px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      height: 56px;
      background: rgb(193, 193, 193, 0.3);
      border-radius: 15px;
    }

    &::-webkit-scrollbar {
      visibility: none;
      width: 5px;
      background: #fff;
    }

    &::-webkit-scrollbar-track {
      visibility: none;
      width: 5px;
      border-radius: 4px;
      background: #fff;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        overflow-y: visible;
        &::-webkit-scrollbar-thumb {
          height: 1rem;
          border-radius: 0.5rem;
          background: rgba(100, 100, 100, 0.8);
        }
    
        &::-webkit-scrollbar {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: transparent;
        }
    
        &::-webkit-scrollbar-track {
          visibility: none;
          height: 1rem;
          width: 1rem;
          background: initial;
        }  
      `}    

    ${({ theme }) =>
      (theme.isEmbeddedMode &&
        css`
          @media screen and (max-width: 991px) {
            height: 100vh;
          }
        `) ||
      (theme.isSalesMode &&
        css`
          height: auto;
          width: 100%;
          padding-right: 0rem;
          padding-left: 0rem;
          padding-top: 0rem;      
        `)}    
  `,
  DetailContainer: styled.div`
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        background-color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0.75)),
          to(rgba(0, 0, 0, 0.49))
        );
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
        padding-bottom: 0;

        .main {
          display: block;
          justify-content: center;
          align-items: center;
          margin-left: 10vw;
          margin-right: 10vw;
        }
        .backContainer {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
          margin-top: -1.5rem;
        }
      `}
  `,  
}

const Content = () => {
  const activeTab = useSelector((state) => state.activeTab)
  const contentPaneVisible = useSelector((state) => state.contentPaneVisible)
  return (
    <S.Content visible={activeTab === 'favourites' ? true : contentPaneVisible} className="content">
      {activeTab === 'land' && <Land />}
      {activeTab === 'homes' && <Homes />}
      {activeTab === 'nearby' && <Nearby />}
      {activeTab === 'internalPois' && <InternalPois />}
      {activeTab === 'favourites' && <Favourites />}
      {activeTab === 'intrapac' && <Intrapac />}
      {activeTab === 'enquire' && (
        <S.DetailContainer className="enquiry">
          <div className="main">
            <PropertyModalv2 hideBackButton={true} />
          </div>
        </S.DetailContainer>
      )}
    </S.Content>
  )
}

export default Content
