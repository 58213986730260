import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import mapboxgl from 'mapbox-gl'
import CustomSlider from '../CustomSlider'
import Api from '../../redux/api'
import {
  filterLots,
  clearFilterLots,
  filterHouses,
  clearFilterHouses,
  sortHouses,
  showLotPopup,
  setToggleButtonVisibility,
  setContentVisibility,
  setSelectedStage,
  showToast,
  setPdfUrl
} from '../../redux/actions'
import PropertyModalv2 from '../PropertyModalv2'
import PackageCard from '../PackageCard'
import {
  getProject,
  getHouses,
  getBuildersSelectData,
  getMap,
  getMapPadding,
  getUnsoldHouses,
  getUnsoldLots,
  isSalesVersion,
  getLotsGroupedByStages,
  getLotsSortBy,
  getAllLots,
  getHousesSortBy,
  getAvailableLotsCoordinates,
  getCategorizedOnsitePoints,
} from '../../redux/selectors'
import { isMobile, formatPrice, getLotsBoundaries, getLotMinZoom, renderAuxNavigation, vh } from '../../utils/functions'
import TuneIcon from '@material-ui/icons/Tune'
import CloseIcon from '@material-ui/icons/Close'
import LotCard from '../LotCard'
import BackButton from '../../reusable/BackButton'
import { MOBILE_WIDTH_THRESHOLD } from '../../config'
import CustomSelect from '../../reusable/CustomSelect'
import { showOnsiteAll } from '../../mapLayers/pointsInternal'

const S = {
  Container: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      .enquireContainer {
        height: calc(100vh - 88px);
        height: calc(var(--vh, 1vh) * 100 - 88px);
      }
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        .enquireContainer {
          background-color: transparent;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0.75)),
            to(rgba(0, 0, 0, 0.49))
          );
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
        }
        .backContainer {
          display: flex;
          flex-direction: row;
          margin-top: -1.5rem;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
        }
      `}
  `,
  LotContainer: styled.div`
    background-color: #f5faff;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        background-color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0.75)),
          to(rgba(0, 0, 0, 0.49))
        );
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.49));
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-bottom: 1rem;
        padding-top: 1rem;
        //height: 22.5vh;

        .backContainer {
          display: flex;
          flex-direction: row;
          margin-top: -1.5rem;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
        }
      `}
  `,
  ButtonSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ButtonsWrapper: styled.div`
    position: relative;
    display: flex;
    width: 75%;
    height: 2.2rem;
    justify-content: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #f5faff;
  `,
  Button: styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  ButtonText: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    color: #443f3f;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  ButtonTextSelected: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  Filters: styled.div`
    display: flex;
    justify-content: space-between;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        //min-width: 500px;  
      //width: 12vw;
        margin-right: 15px;
        margin-bottom: 25px;
        margin-left: 2vh;
      `}
  `,
  CustomSelect: styled(CustomSelect)`
    margin-bottom: 2.5rem;
  `,
  SortClear: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        //width: 14vw;
        justify-content: flex-start;
        margin-left: 13px;
        a {
          background-color: #f2f3f9;
        }
      `}
  `,
  ButtonSelected: styled.div`
    position: absolute;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    ${({ selected }) =>
      selected === 'landForSale' &&
      css`
        left: 0;
      `}
  `,
  FilterButton: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      box-shadow: 0 12px 33px -8px #2b2b2b;
      border-radius: 24px;
      width: 48px;
      height: 48px;
      position: absolute;
      z-index: 102;
      top: 1rem;
      right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ display }) =>
      display === 'true'
        ? css`
              color: white;
              background-color: #5b9abb;
              border-radius: 20px;
              width: 40px;
              height: 40px;
            `
        : css`
              background-color: #6FAED7;
              color: #fff;
              border: 2px solid #fff;
            `}
    }
  `,
  FilterOverlay: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
      display === 'true'
        ? css`
              z-index: 100;
              width: 100vw;
              height: calc(100vh - 88px);
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.6);
            `
        : css`
              display: none;
            `}
    }
  `,
  SliderSection: styled.div`
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
      display === 'true'
        ? css`
              position: absolute;
              background: #fff;
              display: unset;
              border-radius: 15px;
              margin: 0.6rem;
              z-index: 100;
              top: 0;
              left: 0;
              padding: 1rem;
              width: calc(100vw - 1.2rem);
            `
        : css`
              display: none;
            `}
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-direction: row;
        background-color: rgba(16, 18, 32, 0.59);
        width: 100vw;
        justify-content: center;
        align-items: center;
        .MuiInputBase-input {
          background-color: rgba(19, 21, 43, 0.31) !important;
          border: none !important;
          border-radius: 15px !important;
          padding-left: 30px !important;
          padding-right: 40px !important;
        }
        .MuiInputBase-root {
          color: white !important;
        }
        .MuiSelect-icon {
          color: white !important;
        }
        .MuiSlider-root {
          color: #5a99c2 !important;
        }
        .MuiSlider-thumb {
          background-color: #444e50 !important;
          background-image: url('/images-new/thumb-bg.png') !important;
        }
        .MuiFormControl-root {
          margin-bottom: 0 !important;
        }
        h3 {
          color: white !important;
        }
      `}
  `,
  SliderContainer: styled.div`
    display: flex;
  `,
  CategoryContainer: styled.div`
    padding-top: 0rem;
  `,
  CardsContainer: styled.div`
    height: 100%;
    padding-top: 0rem;
    padding-left: 1px;
    padding-right: 1px;

    .package-card:last-child {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      background-color: #fff;
      border-radius: 25px;
      padding-left: 1rem;
      padding-right: 1rem;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 70vh;
      height: calc(var(--vh, 1vh) * 100 - 88px);
      overflow-y: scroll;
      padding-bottom: 30px;
      padding-top: 0px;
      position: relative;
      box-shadow: 0 2px 20px -8px #2b2b2b;
    }
  `,
  CardsContainerHorizontal: styled.div`
    display: flex;
    overflow: scroll;
    height: 20rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: transparent;
    background-image: linear-gradient(180deg, rgba(2, 2, 3, 0.76), rgba(16, 18, 32, 0.59));
    -webkit-box-align: center;
    align-items: center;

    &::-webkit-scrollbar-thumb {
      height: 1rem;
      border-radius: 0.5rem;
      background: rgba(100, 100, 100, 0.8);
    }

    &::-webkit-scrollbar {
      visibility: none;
      height: 1rem;
      width: 1rem;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      visibility: none;
      height: 1rem;
      width: 1rem;
    }

    div.packagesInnerWrapper {
      padding-left: 10vw;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  `,
  OverflowContainer: styled.div`
    overflow: auto;
  `,
  FilterBtn: styled.div`
    flex: 0 auto;
    cursor: pointer;
    max-width: 100%;
    display: inline-block;
    background-color: transparent;

    img {
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.65;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
  `,
  FilterResults: styled.div`
    font-family: Karla, sans-serif;
    font-weight: 400;
    font-size: 110%;
    color: #89b085;
    margin-top: 0.4rem;
    margin-left: 20px;
    padding: 10px 0 10px 0;
    align-self: center;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 0;
        color: #fff;
      `}
  `,
  ClearFilterBtn: styled.div`
    font-family: Karla, sans-serif;
    font-weight: 400;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 31%;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      width: unset
    }
    cursor: pointer;
    align-items: center;
    height: 66px;
    margin-left: 15px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1.5,
      height: 2.6rem;
      color: rgb(51,51,51);
      margin: 0;
      border: 1px solid #bae1f5;      
      border-radius: 100px;
      padding: 10px 12px 10px 12px;
      align-self: flex-end;
      width: 100%;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: initial;
        justify-content: flex-start;
        margin-bottom: 25px;
        margin-left: 20px;
        a {
          border-radius: 15px;
          white-space: nowrap;
          padding-left: 25px;
          padding-right: 25px;
          font-size: 0.85rem;
          border: 2px solid #7a9196;
          background-color: rgba(19, 21, 43, 0.31) !important;
          color: white;
        }
      `}
  `,
  StagesWrapper: styled.div`
    height: auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 9px 50px -33px #000;

    a.register {
      border-style: none;
      border-width: 1px;
      border-color: #f0f1f2;
      border-radius: 10px;
      background-color: #fff;
      text-decoration: none;
      cursor: pointer;
      line-height: 1px;
      div {
        margin-right: 0.5rem;
        padding: 0rem 1rem 0.1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #bbe1f5;
        border-radius: 20px;
        box-shadow: none;
        font-family: Karla, sans-serif;
        color: #353535;
        font-size: 0.85rem;
        line-height: 1.6;
        font-weight: 400;
        text-decoration: none;
        width: fit-content;
      }
    }

    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      background-color: unset;
      box-shadow: unset;
      border-radius: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
    ${({ selected }) =>
      selected &&
      css`
        border-style: solid;
        border-width: 1px;
        border-color: #cdeeff;
        box-shadow: 0 0 39px -13px #7bc4eb;
      `};
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-left: 1.5vw;
        margin-bottom: initial;
        margin-top: initial;
        margin-right: initial;
        background-color: rgba(19, 21, 43, 0.31);
        box-shadow: 0 9px 70px -33px #000;
        height: 18rem;
        border-radius: 15px;
        &:first-child {
          margin-left: 10vw;
        }
        &:last-child {
          margin-right: 10vw;
        }
        a.register {
          display: block;
          margin-left: 1.5rem;
          font-size: 1rem;
          text-align: center;
          text-decoration: none;
          background-color: transparent;
          div {
            color: #fff;
            font-size: 0.9rem;
            border-color: #fff;
            width: fit-content;
          }
        }
      `}

    h2.comingSoon {
      padding-left: 0.25rem;
      font-family: Karla, sans-serif;
      color: #bdbdbd;
      font-size: 1.4rem;
      line-height: 0.9;
      font-weight: 400;
      margin-left: 0.3rem;
      ${({ theme }) =>
        theme.isSalesMode &&
        css`
          color: #ddd;
          font-size: 1.3rem;
          margin-left: 1.75rem;
        `}
    }
  `,
  ExpandStage: styled.div`
    ${({ theme }) =>
      theme.isSalesMode
        ? css`
            position: relative;
            padding: 0.5rem 0.5rem 0rem;
            border-style: none;
            border-width: 1px;
            border-color: #f0f0f0;
            border-radius: 18px;
            background-color: transparent;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
            margin-left: 1.5rem;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
          `
        : css`
            position: relative;
            display: block !important;
            margin-bottom: 0rem;
            padding: 0.5rem 0.5rem 0rem;
            @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
              padding-left: 0;
            }
            justify-content: space-between;
            align-items: center;
            border-style: none;
            border-width: 1px;
            border-color: #f0f0f0;
            border-radius: 18px;
            background-color: transparent;
          `}
  `,
  ExpandStageItem: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.9rem;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-right: 1rem;
        margin-bottom: 0rem;
        line-height: initial;
        align-items: center;
        white-space: nowrap;
      `}

    h2 {
      margin-top: 0px;
      margin-right: 1rem;
      margin-bottom: 0px;
      font-family: alverata, sans-serif;
      color: #413d3d;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
      ${({ theme }) =>
        theme.isSalesMode &&
        css`
          color: #fff;
        `}

      &.thin {
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #bdbdbd;
        font-size: 1.4rem;
        line-height: 0.9;
        font-weight: 400;
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
            color: #fff;
            font-size: 1.5rem;
          `}
      }
    }
    ${({ theme }) =>
      theme.isSalesMode
        ? css`
            a {
              display: block;
              margin-top: 0.3rem;
              margin-left: 1rem;
              padding: 4px 1.5rem;
              height: 100%;
              -webkit-box-pack: justify;
              -webkit-justify-content: space-between;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-flex: 0;
              -webkit-flex: 0 auto;
              -ms-flex: 0 auto;
              flex: 0 auto;
              border-style: solid;
              border-width: 1px;
              border-color: #fff;
              border-radius: 100px;
              background-color: transparent;
              color: #fff;
              font-size: 1rem;
              text-align: center;
              text-decoration: none;
              max-width: 100%;
              div {
                padding-left: 0rem;
                font-size: 0.9rem;
              }
            }
          `
        : css`
            a {
              border-style: none;
              border-width: 1px;
              border-color: #f0f1f2;
              border-radius: 10px;
              background-color: #fff;
              text-decoration: none;
              cursor: pointer;

              &.brochure {
                margin-right: 0.5rem;
                line-height: 1px;
                div {
                  margin-right: 0rem;
                  padding: 0rem 1rem 0.1rem;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #bbe1f5;
                  border-radius: 20px;
                  box-shadow: none;
                  font-family: Karla, sans-serif;
                  color: #353535;
                  font-size: 0.85rem;
                  line-height: 1.6;
                  font-weight: 400;
                  text-decoration: none;
                }
              }
              &.view {
                line-height: 1px;
                div {
                  margin-right: 0.5rem;
                  padding: 0rem 1rem 0.1rem;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #bbe1f5;
                  border-radius: 20px;
                  box-shadow: none;
                  font-family: Karla, sans-serif;
                  color: #353535;
                  font-size: 0.85rem;
                  line-height: 1.6;
                  font-weight: 400;
                  text-decoration: none;
                }
              }
            }
          `}}
`,
}
const Homes = () => {
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState(false)
  const [lotDetails, setLotDetails] = useState()
  const [showEnquireForm, setShowEnquireForm] = useState()
  const [selectedPackage, setSelectedPackage] = useState()
  const houses = useSelector(getHouses)
  const isSales = useSelector(isSalesVersion)
  const lots = useSelector(getAllLots)
  const lotsGroupedByStages = useSelector(getLotsGroupedByStages)
  const housesSortBy = useSelector(getHousesSortBy)
  const houseFilters = useSelector((state) => state.houses.filters)
  const availableLotsCoordinates = useSelector(getAvailableLotsCoordinates)
  const map = useSelector(getMap)
  const project = useSelector(getProject)
  const pointsOnsiteInitialized = useSelector((state) => state.pointsOnsiteInitialized)
  const lotPopup = useSelector((state) => state.lotPopup)
  const buildersSelectData = useSelector(getBuildersSelectData)
  const categorizedPoints = useSelector(getCategorizedOnsitePoints)
  const unsoldHouses = useSelector(getUnsoldHouses)
  const unsoldLots = useSelector(getUnsoldLots)
  const [calculated, setCalculated] = useState(false)
  const [packageData, setPackageData] = useState({})
  const padding = useSelector(getMapPadding)
  useEffect(() => {
    dispatch(clearFilterHouses())
    dispatch(clearFilterLots())
    dispatch(setToggleButtonVisibility(true))
    return function () {
      dispatch(clearFilterHouses())
      dispatch(setToggleButtonVisibility(false))
    }
  }, [])
  useEffect(() => {
    if (categorizedPoints?.length && pointsOnsiteInitialized) {
      showOnsiteAll(map, categorizedPoints, 14, 24)
    }
  }, [categorizedPoints, pointsOnsiteInitialized, dispatch])  
  useEffect(() => {
    if (lotPopup) {
      setLotDetails(lots.find((lot) => lot.id === lotPopup.id))
    }
  }, [lotPopup, lots])
  const resetView = useCallback(() => {
    if (!map || (!availableLotsCoordinates?.length && !lots?.length)) return
    const bounds = new mapboxgl.LngLatBounds()
    try {
      if (availableLotsCoordinates?.length)
        availableLotsCoordinates.forEach((coord) => bounds.extend(coord))
      else {
        const lotsBoundaries = getLotsBoundaries(lots)
        lotsBoundaries.forEach((coord) => bounds.extend(coord))
      }        
    } catch (e) {
      console.log('**** err extending bounds', e)
    }
    if (map?.isStyleLoaded() && map?.loaded()) {
      const opts = {
        pitch: 0,
        padding: isSales ? { bottom: vh(35) } : undefined,
      }
      if (availableLotsCoordinates?.length)
        opts.zoom = getLotMinZoom() + 1
      map.fitBounds(bounds, opts)
    }
  }, [map, availableLotsCoordinates?.length, lots?.length])
  useEffect(() => {
    const ref = setInterval(() => {
      if (map?.isStyleLoaded() && map?.loaded()) {
        clearInterval(ref)
        resetView();
      }
    }, 500)
    return function () {
      clearInterval(ref)
    }
  }, [map, availableLotsCoordinates?.length, lots?.length])
  const lotsById = useMemo(() => {
    const lotMap = {}
    lots.forEach((lot) => (lotMap[lot.id] = lot))
    return lotMap
  }, [lots])
  const zoomToStage = (stage) => {
    if (!stage.lots) return
    const bounds = new mapboxgl.LngLatBounds()
    let hasCoords = false
    stage.lots.forEach((lotId) => {
      const lot = lotsById[lotId]
      if (lot.geo?.geometry?.coordinates[0]?.length) {
        hasCoords = true
        lot.geo.geometry.coordinates[0].forEach((coord) => bounds.extend(coord))
      }
    })
    if (hasCoords) map.fitBounds(bounds, { maxZoom: getLotMinZoom() + 1, padding })
    else
      dispatch(
        showToast({
          type: 'warning',
          body: `Stage boundaries are not defined on map`,
        }),
      )
  }  
  const onViewClick = (event, stage) => {
    event.stopPropagation()
    zoomToStage(stage)
    if (isMobile()) {
      dispatch(setContentVisibility(false))
    }
    dispatch(setSelectedStage(stage))
  }
  const onBrochureClick = (e, stage) => {
    if (!isSales)
      return;
    e.preventDefault()
    if (!stage.brochure) return
    dispatch(setPdfUrl(stage.brochure.url))
    dispatch(
      Api.broadcastAction({
        actionType: 'viewBrochure',
        entityType: 'stage',
        entityId: stage.id,
      }),
    )
  }  

  const resultNumber = houses?.length === 1 ? `1 result` : `${houses?.length} results`
  const maxBeds = unsoldHouses
    .map((house) => house.houseType.bed)
    .reduce((max, next) => (next > max ? next : max), 0)
  const maxBaths = unsoldHouses
    .map((house) => house.houseType.bath)
    .reduce((max, next) => (next > max ? next : max), 0)

  if (!calculated && unsoldHouses.length && unsoldLots.length) {
    const areaMinPackageRaw =
      unsoldHouses
        .map((house) => house.houseType.area)
        .reduce((min, next) => (next < min ? next : min), 99999999) || 0
    const areaMinPackage = Math.round(Math.floor(areaMinPackageRaw / 1) * 1)

    const areaMaxPackageRaw = unsoldHouses
      .map((house) => house.houseType.area)
      .reduce((max, next) => (next > max ? next : max), 0)
    const areaMaxPackage = Math.round(Math.ceil(areaMaxPackageRaw / 1) * 1)

    // package price MIN MAX
    const priceMinPackageRaw = Math.min(...unsoldHouses.map((house) => house.price))
    const priceMinPackage = Math.floor(priceMinPackageRaw / 10000) * 10000
    const priceMaxPackageRaw = Math.max(...unsoldHouses.map((house) => house.price))
    const priceMaxPackage = Math.ceil(priceMaxPackageRaw / 10000) * 10000

    setPackageData({
      areaMin: areaMinPackage,
      areaMax: areaMaxPackage,
      priceMin: priceMinPackage,
      priceMax: priceMaxPackage,
    })
    setCalculated(true)
  }

  const handleHouseView = (house) => {
    setShowEnquireForm(true)
    setSelectedPackage(house)
    dispatch(showLotPopup(house.lot))
  }

  const valuetext = (value) => {
    return `${value}m`
  }

  const onViewLotLinkClickHandler = (lot) => {
    dispatch(showLotPopup(lot))
  }
  const selectedStage = {}
  const cards = calculated && (
    <>
      {
        lotsGroupedByStages.filter(s => s.packageOnly && s.releaseSatus === 'COMING_SOON').map(stage => {
          return (
            <S.StagesWrapper key={stage.id} selected={selectedStage?.id === stage.id}>
              <S.ExpandStage>
                <S.ExpandStageItem className="ExpandStageItem">
                  <h2>Stage {stage.number}</h2>
                  <h2 className="thin">{stage.name}</h2>
                </S.ExpandStageItem>
                <S.ExpandStageItem>
                  <a href="#" className="view" onClick={(event) => onViewClick(event, stage)}>
                    <div>View Stage</div>
                  </a>
                  {stage.brochure && (
                    <a
                      href={stage.brochure.url}
                      target="_blank"
                      className="brochure"
                      onClick={(event) => onBrochureClick(event, stage)}
                    >
                      <div>Brochure</div>
                    </a>
                  )}
                </S.ExpandStageItem>
              </S.ExpandStage>                
              <h2 className="comingSoon">Coming Soon</h2>
              <a href="#" className="register" 
                onClick={() => {
                  setShowEnquireForm(true)
                }}
              >
                <div>Register your Interest</div>
              </a>
            </S.StagesWrapper>
          )
        })
      }
      {houses.map((house) => (
        <PackageCard
          key={house.id}
          className="package-card"
          house={house}
          title={house.houseType.name}
          subtitle={house.houseType.builder}
          handleView={() => handleHouseView(house)}
          builderName={house.houseType.builder ? house.houseType.builder.name : '-'}
          builderLogo={house.houseType?.builder?.thumbnailUrl}
          bed={house.houseType.bed}
          bath={house.houseType.bath}
          car={house.houseType.car}
          area={house.houseType.area}
          land={(house.lot || {}).sqm}
          frontage={(house.lot || {}).dimensions?.width}
          price={house.price}
          image={house?.facade?.url}
          pdf={house?.pdf?.url}
          status={(house.lot || {}).status}
          lotNumber={(house.lot || {}).humanId}
          showViewLotLink
          showLotInfo={true}
          onViewLotLinkClickHandler={onViewLotLinkClickHandler}
          mediaType="package"
          mediaId={house.id}
        />
      ))}
    </>
  )

  const Back = () => (
    <div class="backContainer">
      <BackButton onClickHandler={() => {
        dispatch(showLotPopup()); 
        setLotDetails(false); 
        resetView()
      }}></BackButton>
    </div>
  )

  return lotDetails && !showEnquireForm ? (
    <S.LotContainer>
      {isSales ? renderAuxNavigation(<Back />) : <Back />}
      <LotCard lot={lotDetails} handleView={() => setShowEnquireForm(true)} />
    </S.LotContainer>
  ) : (
    <S.Container>
      {showEnquireForm ? (
        <div class="enquireContainer">
          <PropertyModalv2
            lotId={lotDetails?.id}
            packageId={selectedPackage?.id}
            handleClose={() => setShowEnquireForm(false)}
          />
        </div>
      ) : (
        calculated ? (
          <S.CategoryContainer>
            {isSales && (
              <S.CardsContainerHorizontal>
                <div className="packagesInnerWrapper">{cards}</div>
              </S.CardsContainerHorizontal>
            )}
            <S.FilterOverlay display={`${showFilters}`} />
            <S.SliderSection display={`${showFilters}`}>
              <CustomSlider
                label="Price"
                marks={[
                  { value: packageData.priceMin, label: formatPrice(packageData.priceMin) },
                  { value: packageData.priceMax, label: formatPrice(packageData.priceMax) },
                ]}
                valueLabelFormat={formatPrice}
                valuetext={valuetext}
                value={
                  houseFilters.price.min === undefined
                    ? [packageData.priceMin, packageData.priceMax]
                    : [houseFilters.price.min, houseFilters.price.max]
                }
                min={packageData.priceMin}
                max={packageData.priceMax}
                step={10000}
                handleValueChange={(e, value) => dispatch(filterHouses(value, 'price'))}
              />
              <CustomSlider
                label="House Size (sq)"
                marks={[
                  { value: packageData.areaMin, label: packageData.areaMin + 'sq' },
                  { value: packageData.areaMax, label: packageData.areaMax + 'sq' },
                ]}
                valueLabelFormat={(value) => value + 'sq'}
                valuetext={valuetext}
                value={
                  houseFilters.area.min === undefined
                    ? [packageData.areaMin, packageData.areaMax]
                    : [houseFilters.area.min, houseFilters.area.max]
                }
                min={packageData.areaMin}
                max={packageData.areaMax}
                step={1}
                handleValueChange={(e, value) => dispatch(filterHouses(value, 'area'))}
              />
              <S.Filters>
                <CustomSelect
                  dataGenerate={maxBeds}
                  multiple={true}
                  onAction={(value) => dispatch(filterHouses(value, 'bedrooms'))}
                  value={houseFilters.bedrooms}
                  emptyLabel="Bedrooms"
                ></CustomSelect>
                <CustomSelect
                  dataGenerate={maxBaths}
                  dataGenerateStep={0.5}
                  multiple={true}
                  onAction={(value) => dispatch(filterHouses(value, 'bathrooms'))}
                  value={houseFilters.bathrooms}
                  emptyLabel="Bathrooms"
                ></CustomSelect>
                <CustomSelect
                  data={buildersSelectData}
                  multiple={true}
                  onAction={(value) => dispatch(filterHouses(value, 'builders'))}
                  value={houseFilters.builders}
                  emptyLabel="Builders"
                ></CustomSelect>
              </S.Filters>
              <S.SortClear>
                <S.CustomSelect
                  data={[
                    {
                      label: 'Price: Low to High',
                      value: 'price-asc',
                    },
                    {
                      label: 'Price: High to Low',
                      value: 'price-desc',
                    },
                    {
                      label: 'House Size: Low to High',
                      value: 'area-asc',
                    },
                    {
                      label: 'House Size: High to Low',
                      value: 'area-desc',
                    },
                    {
                      label: 'Lot Area: Low to High',
                      value: 'lot-area-asc',
                    },
                    {
                      label: 'Lot Area: High to Low',
                      value: 'lot-area-desc',
                    },
                  ]}
                  emptyLabel="Sort By"
                  value={housesSortBy}
                  onAction={(value) => dispatch(sortHouses(value))}
                ></S.CustomSelect>
                <S.ClearFilterBtn>
                  <a onClick={() => dispatch(clearFilterHouses())}>Clear Filters</a>
                </S.ClearFilterBtn>
                {isSales && !!resultNumber && <S.FilterResults>{resultNumber}</S.FilterResults>}
              </S.SortClear>
            </S.SliderSection>
            {!isSales && <S.CardsContainer>{cards}</S.CardsContainer>}
            <S.FilterButton
              display={`${showFilters}`}
              onClick={() => {
                setShowFilters((f) => {
                  dispatch(setToggleButtonVisibility(f))
                  return !f
                })
              }}
            >
              {showFilters ? <CloseIcon fontSize="small" /> : <TuneIcon />}
            </S.FilterButton>
          </S.CategoryContainer>
        ) : (
          <div class="enquireContainer">
            <PropertyModalv2
              hideBackButton={true}
              title={`${project.frontendName} is currently sold out of house & land packages.`}
              subtitle="New house & land options are expected to be released shortly. Please register your details below so we can keep you informed."
            />
          </div>          
        )
      )}
    </S.Container>
  )
}

export default Homes
