import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { getProject } from '../../redux/selectors'
import { rsz } from '../../utils/functions'

const S = {
  Container: styled.div`
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        left: 0%;
        top: auto;
        right: 0%;
        bottom: 0%;
        overflow: hidden;
        width: 100%;
        height: auto;
        max-width: 100%;
        padding-right: 0rem;
        padding-bottom: 0rem;
        padding-left: 0rem;
        background-color: transparent;
        box-shadow: 0 0 71px -7px rgb(0 0 0 / 24%);
        font-family: Karla, sans-serif;
        font-weight: 400;

        .sales-centre-inner-panel-wrap {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          height: auto;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          overflow: scroll;
          height: calc(17vw);
          padding-top: 2vw;
          padding-bottom: 2vw;
          padding-left: 15vw;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: start;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          background-image: -webkit-gradient(
              linear,
              left top,
              left bottom,
              from(rgba(2, 2, 3, 0.76)),
              to(rgba(16, 18, 32, 0.59))
            ),
            url('/images-new/topo-lines.svg');
          background-image: linear-gradient(180deg, rgba(2, 2, 3, 0.76), rgba(16, 18, 32, 0.59)),
            url('/images-new/topo-lines.svg');
          background-position: 0px 0px, 0px 0px;
          background-size: auto, cover;
          background-repeat: repeat, no-repeat;
          background-color: #4c4949;

          &::-webkit-scrollbar-thumb {
            height: 1rem;
            border-radius: 0.5rem;
            background: rgba(100, 100, 100, 0.8);
          }
  
          &::-webkit-scrollbar {
            visibility: none;
            height: 1rem;
            width: 1rem;
            background: transparent;
          }
  
          &::-webkit-scrollbar-track {
            visibility: none;
            height: 1rem;
            width: 1rem;
          }      
              
        }
        .intra-content-wrap {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-right: 10vw;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;

          img.intra-logo {
            width: 14rem;
            max-width: none;
            margin-top: 0.3rem;
            margin-right: 5rem;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            vertical-align: middle;
            display: inline-block;
          }
          .drop-quote {
            display: block;
            margin-top: -15px;
            margin-right: 4.5rem;
            margin-bottom: 0rem;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            font-size: 3rem;
            line-height: 0.8;
            font-family: alverata, sans-serif;
            color: #fff;
            font-weight: 700;
            .underline.yellow {
              border-bottom: 4px solid #62bf82;
              display: inline;
              border-bottom-width: 3px;
              font-size: 1.45rem;
              line-height: 1;
              border-bottom-color: #f2e65d;
            }
          }

          .paragraph {
            width: 52ch;
            margin-top: 0rem;
            margin-right: 1rem;
            margin-bottom: 0px;
            color: #fff;
            font-size: 0.9rem;
            line-height: 1.5;
          }
        }
        .nearby-side-scroll-gallery-wrap {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          height: 100%;
          max-height: 100%;
          margin-top: 0rem;
          margin-bottom: 0rem;
          margin-left: 2rem;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
        }
        .gradient-overlay {
          position: fixed;
          left: auto;
          top: auto;
          right: 0%;
          bottom: 0%;
          z-index: 10;
          width: 12.5vw;
          height: 100%;
          background-image: -webkit-gradient(
            linear,
            right top,
            left top,
            from(rgba(0, 0, 0, 0.7)),
            to(transparent)
          );
          background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.7), transparent);
        }

        .gradient-overlay.left {
          left: 0%;
          top: auto;
          right: auto;
          bottom: 0%;
          z-index: 10;
          background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(0, 0, 0, 0.7)),
            to(transparent)
          );
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7), transparent);
        }
      `}
  `,
  ProjectCard: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-right: 10vw;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    img.project-img {
      width: 25vw;
      height: 100%;
      margin-right: 2rem;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .project-text-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .project-heading {
      margin-top: 0px;
      margin-bottom: 0px;
      color: #fff;
      font-size: 1.75rem;
    }
    .paragraph {
      width: 52ch;
      margin-top: 0rem;
      margin-right: 1rem;
      margin-bottom: 0px;
      color: #fff;
      font-size: 1rem;
      line-height: 1.5;
    }

    .paragraph.project {
      width: 38ch;
    }

    .paragraph.project.location {
      margin-bottom: 1rem;
      color: #c0bdbd;
    }
  `,
}

const ProjectCard = ({ project: { image, title, location, description }, last }) => (
  <>
    <S.ProjectCard>
      <img
        src={rsz(image.url, {
          width: Math.ceil(window.innerWidth / 4),
          crop: 'fill',
          quality: 'auto',
        })}
        alt="Project image"
        class="project-img"
      />
      <div class="project-text-wrap">
        <h1 class="project-heading">{title}</h1>
        <p class="paragraph project location">
          <strong>{location}</strong>
        </p>
        <p class="paragraph project">{description}</p>
      </div>
    </S.ProjectCard>
    {last && (
      <S.ProjectCard>
        <div class="project-text-wrap">&nbsp;&nbsp;&nbsp;</div>
      </S.ProjectCard>
    )}
  </>
)

const Intrapac = () => {
  const project = useSelector(getProject)
  const { showcase = {} } = project || {}
  return (
    <S.Container>
      <div class="sales-centre-inner-panel-wrap intrapac-panel">
        <div class="gradient-overlay left"></div>
        <div class="gradient-overlay"></div>
        <div class="nearby-single-content-wrap">
          <div class="intra-content-wrap">
            <img src="images-new/logo-intrapac.svg" loading="lazy" alt="" class="intra-logo" />
            <h2 class="drop-quote">
              <span class="underline yellow">
                Quality Developments.
                <br />
                Inspiring Environments.
                <br />
                Unparalleled Expertise.
              </span>
            </h2>
            <p class="paragraph">{showcase.description}</p>
          </div>
        </div>
        <div class="nearby-side-scroll-gallery-wrap">
          {showcase.projects &&
            showcase.projects.sort((a, b) => a.position - b.position).map((p, index) => (
              <ProjectCard project={p} key={index} last={showcase.projects.length - 1 === index} />
            ))}
        </div>
      </div>
    </S.Container>
  )
}

export default Intrapac
