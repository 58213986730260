import React, { useRef, useEffect, useState, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import HeightIcon from '@material-ui/icons/Height'
import Api from '../redux/api'
import { useSpring, animated, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import Status from './Status'
import PackageCard from './PackageCard'
import { showToast, addLotToFavourites, setMapPaddingRefs } from '../redux/actions'
import {
  getFavouriteLots,
  getFavouritePackages,
  getStages,
  getCachedPackages,
  getProject,
  isSalesVersion,
  getAllHouses
} from '../redux/selectors'
import { getCenter, formatPrice, isMobile } from '../utils/functions'
import { MOBILE_WIDTH_THRESHOLD } from '../config'
import useViewport from '../hooks/useViewport'
import { mapColors } from './Map'
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons'
const S = {
  Container: styled(animated.div)`
    width: 411px;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      background-color: #fff;
      box-shadow: 0 23px 49px -9px rgba(0, 0, 0, 0.16);
      border-radius: 15px;
      width: 89.6vw;
      margin-bottom: 1.5rem;
      ${({ expandable }) =>
        expandable &&
        css`
          width: 100vw;
          position: absolute;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: 0;
        `}
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        width: initial;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
      `}
    ${({ favouriteMode }) =>
      favouriteMode &&
      css`
        width: initial;
        display: flex;
        flex-direction: row;
        margin-left: initial;
        margin-right: 8rem;
        border-right: 1px solid white;
        padding-right: 5rem;
        justify-content: initial;
        align-items: initial;

        :last-child {
          border-right: none;
          padding-right: 0;
          margin-right: 2rem;
        }
      `}
  `,
  CardWrapper: styled.div`
    ${({ expanded }) =>
      expanded &&
      css`
        height: calc(var(--vh, 1vh) * 100 - 80px);
        overflow-y: scroll;
      `}
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      `}
  `,
  Card: styled.div`
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;

    background-color: #fff;
    box-shadow: 0 23px 49px -9px rgba(0, 0, 0, 0.16);
    height: auto;
    border-radius: 15px;
    font-family: Karla, sans-serif;
    position: relative;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 0;
      margin-bottom: 0 !important;
      box-shadow: unset;
      border-radius: unset;
      background: unset;
      overflow: initial;
      ${({ expanded }) =>
        expanded &&
        css`
          margin-top: calc(1rem + 50px);
        `}
    }
  `,
  ExpandMarker: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      ${({ display }) =>
        display &&
        css`
          display: flex;
          position: absolute;
          top: -12px;
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          div {
            display: flex;
            color: #333;
            box-shadow: 0 12px 33px -8px #2b2b2b;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            background: #fff;
            justify-content: center;
            align-items: center;
          }
        `}

      ${({ expanded }) =>
        expanded
          ? css`
              position: fixed;
              top: 0px;
              width: 100vw;
              background: #fff;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 60%,
                rgba(255, 255, 255, 0) 100%
              );
              z-index: 100;
              padding-bottom: 40px;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              div {
                margin-top: 1rem;
              }
            `
          : css`
              top: 0px;
              width: 100vw;
              background: #fff;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 15%,
                rgba(255, 255, 255, 0) 100%
              );
              z-index: 100;
              padding-bottom: 30px;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              div {
                top: -12px;
                left: 0;
                right: 0;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
              }
            `}
    }
  `,
  LotContainer: styled.div`
    width: 12.5vw;
    min-width: 30rem;
    height: 18rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 15px;
    background-color: rgba(16, 19, 43, 0.26);
    box-shadow: 2px 18px 100px -19px rgba(0, 0, 0, 0.49);
  `,
  LotHeader: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 1.5rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.68);
    h1.lot-heading {
      margin-top: 0px;
      margin-bottom: 0px;
      color: #fff;
      font-size: 2rem;
      line-height: 1.5rem;
    }
    div.lot-price {
      margin-top: 0rem;
      margin-bottom: 1.6rem;
      font-family: Karla, sans-serif;
      font-size: 1.25rem;
      line-height: 1;
      font-weight: 400;
      text-align: right;
      color: #fff;
      @media screen and (min-width: 1440px) {
        font-family: Karla, sans-serif;
        font-weight: 400;
      }
      @media screen and (min-width: 1920px) {
        margin-bottom: 0rem;
        font-family: Karla, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  `,
  LotMidRow: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.65);
    div.address {
      color: #fff;
      font-size: 1rem;
      max-width: 50%;
    }
    div.lot-status-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      line-height: 1;
      span {
        padding-bottom: 0.1rem;
        padding-left: 0.5rem;
        color: #fff;
        font-size: 1rem;
      }
    }
  `,
  LotMeasurements: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 0rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px none #fff;
    div.measurement-outer-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
    }
    img.unit-img {
      width: 1.1rem;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    img.unit-img.land {
      width: 0.9rem;
    }
    img.unit-img.depth {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    div.measurement-inner-wrap {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 0.4rem;
      padding-left: 0rem;
      h3.measurement-title {
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Karla, sans-serif;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        font-weight: 700;
        display: inline-block;
      }
      h3.measurement-unit {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0.25rem;
        font-family: Karla, sans-serif;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        font-weight: 400;
        display: inline-block;
      }
    }
  `,
  LotButton: styled.a`
    &.favourite {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 1rem;
      padding: 4px 1.5rem;
      height: 1.7rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      border-radius: 100px;
      background-color: transparent;
      color: #fff;
      font-size: 1rem;
      text-decoration: none;
      cursor: pointer;
      div {
        padding-left: 0.5rem;
      }
      img {
        width: 0.6rem;
        padding-top: 1px;
        font-size: 0.6rem;
      }
    }

    &.enquire {
      display: inline-block;
      padding: 9px 15px;
      background-color: #3898ec;
      color: white;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer;
      border-radius: 0;

      width: auto;
      padding: 5px 2rem;
      border-radius: 100px;
      font-size: 1rem;
      text-align: center;
    }
    img {
      width: 1rem;
      margin-right: 0.5rem;
    }
  `,
  LotButtons: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 0rem;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px none #fff;
  `,
  CardContent: styled(CardContent)`
    position: relative;
  `,

  DetailsHeader: styled.h2`
    font-weight: 500;
    font-size: 20px;
  `,
  ViewBtn: styled.input`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 30px;
    color: #c7c7c7;
    border-radius: 30px;
    border: 1px solid #e9e9e9;
    background: white;
    margin: 10px 0px;
    cursor: pointer;
    font-size: 12px;
  `,
  IconsContainer: styled.div`
    display: flex;

    div {
      display: flex;
      align-items: center;
      svg {
        height: 17px;
      }
    }
  `,
  Typography: styled(Typography)`
    display: flex;
    justify-content: space-between;

    sup {
      vertical-align: super;
      font-size: xx-small;
    }
  `,
  PdfFile: styled.div`
    min-height: 24px;
    max-height: 24px;
    a {
      display: flex;
      align-items: center;
      svg {
        font-size: 1rem;
        margin-right: 3px;
      }
      &:hover {
        font-weight: 800;
      }
    }
  `,
  CardMedia: styled(CardMedia)`
    width: 350px;
    ${({ height }) => css`
      height: ${height};
    `}
  `,
  CloseButton: styled(CloseRoundedIcon)`
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    border-radius: 30px;
    background-color: white;
    && {
      width: 18px;
      height: 18px;
      margin-top: 8px;
      margin-right: 4px;
    }
  `,
  FavoriteIcon: styled(FavoriteIcon)`
    color: rgb(243, 104, 39);
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 7px;

    && {
      width: 30px;
      height: 30px;
    }
  `,
  FavoriteBorderIcon: styled(FavoriteBorderIcon)`
    color: rgb(243, 104, 39);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 50%;
    padding: 7px;
    && {
      width: 30px;
      height: 30px;
    }
  `,
  DetailsSection: styled.div`
    display: flex;
    justify-content: space-between;
    color: darkgrey;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    ${({ reduceMargin }) =>
      reduceMargin &&
      css`
        margin-bottom: 0px;
      `}
    ${({ fontColor }) =>
      fontColor === 'black' &&
      css`
        color: black;
      `}
      span {
      margin-right: 10px;
    }
  `,
  Title: styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
  `,
  Price: styled.div`
    font-size: 14px;
    font-weight: 500;
    color: black;
  `,
  HeightIcon: styled(HeightIcon)`
    transform: rotate(45deg);
    margin-bottom: -5px;
    && {
      font-size: 1.2rem;
    }
  `,
  Status: styled(Status)``,
  LotStatusSubtitle: styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: flex;
      margin-top: -0.2rem;
      margin-bottom: 0.7rem;
      padding-top: 0px;
      flex-direction: row;
      align-items: baseline;
      color: #a2e686;
      line-height: 1;
    }
  `,
  LotStatus: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0rem;
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    color: #a2e686;
    line-height: 1;
  `,
  Enquire: styled.a`
    position: relative;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    margin: 1rem 2rem 1.5rem;
    padding: 0.3rem 1.1rem 0.3rem 0.7rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #53addb;
    color: #fff;
    text-align: center;
    text-decoration: none;
    -o-object-fit: fill;
    object-fit: fill;

    div {
      font-family: Karla, sans-serif;
      font-weight: 700;
      text-align: center;
    }
  `,
  DetailsRow: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    font-weight: bold;
    padding-bottom: 25px;
  `,
  DetailsColumn: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,

  CardMainContent: styled.div`
    display: flex;
    height: auto;
    margin: 1.5rem 2rem 0rem;
    justify-content: space-between;
  `,
  CardMainContentLeft: styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        margin-top: 0;
        margin-bottom: 1rem;
        height: initial;
        &.buttons {
          margin-top: 1.5rem !important;
        }
      `}
    h2 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
      font-family: alverata, sans-serif;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
      text-align: left;
    }

    h3 {
      margin-top: 0rem;
      margin-bottom: 1.5rem;
      font-family: Karla, sans-serif;
      color: #a5a2a2;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      text-align: left;
    }

    .price {
      margin-top: 0.3rem;
      margin-bottom: 0.6rem;
      font-family: Karla, sans-serif;
      font-size: 1.15rem;
      line-height: 1;
      font-weight: 400;
      text-align: right;
    }

    .size {
      display: flex;
      margin-bottom: 1rem;
      justify-content: flex-start;
      align-items: center;
      font-family: Karla, sans-serif;
      ${({ theme }) =>
        theme.isSalesMode &&
        css`
          margin-top: 1.4rem;
          margin-bottom: 0;
          color: white;
        `}

      .img-wrapper {
        margin-right: 0.5rem;
        padding: 0.25rem 0.4rem;
        border-radius: 100px;
        background-color: rgba(233, 241, 252, 0.6);

        img {
          width: 0.9rem;
          height: 1rem;
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
          border: 0;

          &.frontage-arrow {
            width: 0.7rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
            transform: rotate(45deg);
          }
          &.depth-arrow {
            width: 0.7rem;
            height: 0.8rem;
            margin-right: 0rem;
            opacity: 1;
            transform: rotate(-45deg);
          }
          &.areai-icon {
            width: 0.6rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
          }
        }
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
            background-color: transparent;
            img {
              width: 1.7rem;
              margin-right: 0.5rem;
              background-color: transparent;
    
              &.frontage-arrow {
                height: initial;
                width: 1.7rem;
                margin-right: 0.5rem;
                opacity: 1;
                transform: initial;
              }
              &.depth-arrow {
                width: 1.7rem;
                margin-right: 0.5rem;
                transform: rotate(90deg);
              }
              &.areai-icon {
                width: 1rem;
                margin-right: 0.8rem;
                margin-left: 6px;
              }

          `}
      }

      div {
        font-family: Karla, sans-serif;
        color: #4c4949;
        font-size: 14px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        span {
          color: #a5a2a2;
        }
        ${({ theme }) =>
          theme.isSalesMode &&
          css`
            color: red;
            font-size: 1.4rem;
            font-weight: 700;
            span {
              color: white;
              font-weight: 400;
            }
          `}
      }
    }
  `,
  CardMainContentRight: styled.div`
    position: static;
    display: block;
    height: 100%;
    margin-top: 0rem;
    padding: 0.4rem 0.75rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-style: solid;
    border-width: 1px;
    border-color: #c9dce6;
    border-radius: 100px;
    text-align: right;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: none;
    }
  `,
  LotText: styled.div`
    opacity: 1;
    font-family: Karla, sans-serif;
    color: #4c4949;
    font-size: 14px;
    line-height: 0.85rem;
    font-weight: 400;
  `,
  FavouriteBtn: styled.div`
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 1rem;
    margin-right: 1.25rem;
    padding-top: 1px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      border: 1px solid #ededed;
      margin-top: 0;
    }
    img {
      width: 1rem;
      height: 1rem;
    }
  `,
  MapContainer: styled.div`
    width: 100%;
    height: 13.5rem;
    border-radius: 10px 10px 0px 0px;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      display: none;
    }
    .mapboxgl-ctrl {
      display: none !important;
    }
  `,
  Packages: styled.div`
    .package-card:last-child {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-left: 2rem;
      margin-right: 2rem;

      .package-card {
        margin-top: 1rem !important;
      }

      .package-card:last-child {
        margin-bottom: 0;
      }
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        position: relative;
      `}
  `,
  PackagesTitle: styled.div`
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid #ccc;
    font-size: 0.85rem;
    font-weight: 400;
    @media screen and (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
      margin-top: 0rem;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      font-size: 1rem;
    }
    ${({ theme }) =>
      theme.isSalesMode &&
      css`
        position: absolute;
        top: 15px;
        left: 0;
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        font-weight: 700;
      `}
  `,
}

function convertLotToGeoJson(lot) {
  const { id, status, sqm, price, geo, humanId, dimensions, stage } = lot
  return {
    type: 'Feature',
    id,
    properties: {
      id,
      status,
      stageId: stage.id,
      sqm: sqm ? sqm : 0,
      price: price ? price : 0,
      humanId,
      width: (dimensions || {}).width || 0,
      depth: (dimensions || {}).depth || 0,
    },
    geometry: geo.geometry,
  }
}

export default function LotCard({
  lot,
  handleView,
  className,
  hidePackages,
  favouriteMode,
  showcasePackages,
  expandable = isMobile(),
}) {
  const dispatch = useDispatch()
  const mapContainer = useRef(null)
  const favouriteLots = useSelector(getFavouriteLots)
  const allHouses = useSelector(getAllHouses)
  const favouritePackages = useSelector(getFavouritePackages)
  const { width } = useViewport()
  const displayMap = width > MOBILE_WIDTH_THRESHOLD
  const lotcardRef = useRef()
  const [expanded, setExpanded] = useState(false)
  const cachedPackages = useSelector(getCachedPackages)
  let lotPackages = showcasePackages ? showcasePackages : cachedPackages[lot?.id]
  if (favouriteMode && lotPackages) {
    lotPackages = lotPackages.filter((p) => favouritePackages?.find((f) => f.id === p.id))
  }
  const isSalesMode = useSelector(isSalesVersion)
  const stages = useSelector(getStages)
  const project = useSelector(getProject)
  const lots = useSelector((state) => state.lots.data)
  const id = lot?.stage?.id ? lot.stage.id : lot?.stage
  const stage = stages.filter((stage) => stage.id === id)[0]
  const isDwellingPackage = useMemo(() => (
    allHouses || []).filter(p => p.dwellingContract).some(p => p.lot?.id === lot?.id
  ), [lot, allHouses])

  if (lot && !lotPackages) {
    dispatch(Api.fetchLotPackages(lot.id))
  }

  const height = lotPackages?.length ? 380 : 316
  const bottomOffset = 88
  const [{ y }, set, stop] = useSpring(() => ({ y: 0 }))
  const open = () => {
    set({
      y: 0 - window.innerHeight + bottomOffset + height,
      immediate: false,
      config: config.stiff,
    })
  }
  const close = () => {
    set({ y: 0, immediate: false, config: config.stiff })
  }
  useEffect(() => {
    if (isMobile()) {
      dispatch(setMapPaddingRefs({ bottom: lotcardRef }))
      return function cleanup() {
        dispatch(setMapPaddingRefs({ bottom: null }))
      }
    }
  }, [])

  useEffect(() => {
    if (isSalesMode || !mapContainer.current || !displayMap) {
      return
    }
    const center = lot?.geo?.geometry?.coordinates ? getCenter(lot.geo.geometry.coordinates) : undefined
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: project.mapboxStyleUrl || process.env.REACT_APP_MAPBOX_STYLE_URL,
      center,
      zoom: 18.5,
      interactive: false,
    })
    map.on('load', () => {
      const geoJson = {
        type: 'FeatureCollection',
        features: [],
      }
      if (lot && lot.geo) {
        geoJson.features.push(convertLotToGeoJson(lot))
      }
      const unselectedSource = lots
        .filter((lot) => lot.geo && lot.stage?.enabled)
        .map(convertLotToGeoJson)
      map.addSource('lots-unselected-source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: unselectedSource,
        },
        generateId: true,
      })
      map.addSource('lots-source', {
        type: 'geojson',
        data: geoJson,
        generateId: true,
      })
      map.addLayer({
        id: 'lots',
        type: 'fill',
        source: 'lots-unselected-source',
        minzoom: 17,
        layout: {},
        paint: { 'fill-color': mapColors.default.fill },
      }, project.overlayLayerId)
      map.addLayer({
        id: 'lots-lines-outline',
        type: 'line',
        source: 'lots-unselected-source',
        minzoom: 18,
        layout: {},
        paint: {
          'line-color': mapColors.default.outline,
          'line-width': 0.5,
        },
      }, project.overlayLayerId)
      map.addLayer({
        id: 'lots-fill',
        type: 'fill',
        source: 'lots-source',
        minzoom: 18,
        layout: {},
        paint: {
          'fill-color': mapColors.selected.fillExtrusion,
        },
      }, project.overlayLayerId)
      map.addLayer({
        id: 'lots-lines-outline-filtered-in',
        type: 'line',
        source: 'lots-source',
        minzoom: 17,
        layout: {},
        paint: {
          'line-color': mapColors.highlighted.outline,
          'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 10, 15],
          'line-blur': ['case', ['boolean', ['feature-state', 'hover'], false], 8.2, 15],
          'line-opacity': 0.4,
        },
      }, project.overlayLayerId)
      map.addLayer({
        id: 'lots-lines-outline-filtered-in-highlight',
        type: 'line',
        source: 'lots-source',
        minzoom: 17,
        layout: {},
        paint: {
          'line-color': 'hsl(0, 0%, 100%)',
          'line-opacity': 1,
        },
      }, project.overlayLayerId)
    })
    return function cleanup() {
      map.remove()
    }
  }, [lot, displayMap])

  const displayToast = (body, type) => {
    dispatch(
      showToast({
        body,
        type,
      }),
    )
  }
  const isInFavourite = (lot) => favouriteLots.findIndex((l) => l.id === lot.id) >= 0

  const addToFavourite = (lot) => {
    !isInFavourite(lot)
      ? displayToast('Lot ' + lot.humanId + ' was added to your Favourites', 'success')
      : displayToast('Lot ' + lot.humanId + ' was removed from your Favourites', 'success')

    dispatch(addLotToFavourites(lot.id))
  }
  const cardWrapperStyle =
    isMobile() && !expanded ? { height, overflowY: 'scroll', paddingBottom: 20 } : undefined
  const vertical =
    favouriteMode ||
    (!!lotPackages?.length &&
      lot?.status !== 'Sold' &&
      lot?.status !== 'Settled' &&
      lot?.status !== 'Unreleased')
  return (
    <S.Container
      favouriteMode={favouriteMode}
      ref={lotcardRef}
      expandable={expandable}
      expanded={expanded}
      style={expandable ? { top: `calc(var(--vh, 1vh) * 100 - 80px - ${height}px)`, y } : null}
    >
      {expandable && (
        <S.ExpandMarker
          display={!!lotPackages?.length}
          onClick={() => {
            setExpanded((expanded) => {
              if (expanded) close()
              else open()
              return !expanded
            })
          }}
          expanded={expanded}
        >
          <div>
            {expanded ? <ExpandMoreRounded size="large" /> : <ExpandLessRounded size="large" />}
          </div>
        </S.ExpandMarker>
      )}
      <S.CardWrapper expanded={expanded} style={cardWrapperStyle}>
        {!isDwellingPackage && lot && (isSalesMode ? (
          <S.LotContainer vertical={vertical} favouriteMode={favouriteMode}>
            <S.LotHeader>
              <h1 className="lot-heading">Lot {lot.humanId}</h1>
              <div className="lot-price">{lot.price && !['Settled', 'Sold', 'Unreleased'].some(s => s === lot.status) ? formatPrice(lot.price) : ' '}</div>
            </S.LotHeader>
            <S.LotMidRow>
              {lot.streetAddress && <div className="address">{lot.streetAddress}</div>}
              <div class="lot-status-wrap">
                <Status status={lot.status} />
                <span>{lot.status === 'Settled' ? 'Sold' : lot.status}</span>
              </div>
            </S.LotMidRow>
            <S.LotMeasurements>
              <div className="measurement-outer-wrap">
                <img src="/images-new/size-white.svg" className="unit-img" />
                <div className="measurement-inner-wrap">
                  <h3 className="measurement-title">Frontage</h3>
                  <h3 className="measurement-unit">
                    {lot.dimensions ? lot.dimensions.width : 0}m
                  </h3>
                </div>
              </div>
              <div className="measurement-outer-wrap">
                <img src="/images-new/size-white.svg" className="unit-img depth" />
                <div className="measurement-inner-wrap">
                  <h3 className="measurement-title">Depth</h3>
                  <h3 className="measurement-unit">
                    {lot.dimensions ? lot.dimensions.depth : 0}m
                  </h3>
                </div>
              </div>
              <div className="measurement-outer-wrap">
                <img src="/images-new/area-icon-white.png" className="unit-img land" />
                <div className="measurement-inner-wrap">
                  <h3 className="measurement-title">Land</h3>
                  <h3 className="measurement-unit">
                    {lot.sqm}m<sup>2</sup>
                  </h3>
                </div>
              </div>
            </S.LotMeasurements>
            <S.LotButtons>
              <S.LotButton className="enquire" onClick={() => handleView(lot)}>
                Enquire
              </S.LotButton>
              <S.LotButton className="favourite" onClick={() => addToFavourite(lot)}>
                <img
                  src={
                    isInFavourite(lot)
                      ? '/images-new/favourites.svg'
                      : '/images-new/favourites-outline.svg'
                  }
                  width="79.5"
                />
                <div>Favourites</div>
              </S.LotButton>
            </S.LotButtons>
          </S.LotContainer>
        ) : (
          <>
            <S.Card className={className} expanded={expanded}>
              <S.MapContainer ref={mapContainer} />
              <S.FavouriteBtn>
                {isInFavourite(lot) ? (
                  <img
                    onClick={() => addToFavourite(lot)}
                    src="/images-new/favourites.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => addToFavourite(lot)}
                    src="/images-new/favourites-outline.svg"
                    alt=""
                  />
                )}
              </S.FavouriteBtn>
              <S.CardMainContent>
                <S.CardMainContentLeft>
                  <h2>Lot {lot.humanId}</h2>
                  <S.LotStatusSubtitle>
                    <S.Status status={lot.status} />
                    <S.LotText>{lot.status === 'Settled' ? 'Sold' : lot.status}</S.LotText>
                  </S.LotStatusSubtitle>
                  <h3>
                    <span>Stage {stage.number}</span> {stage.name}
                  </h3>
                  <div className="size">
                    <div className="img-wrapper">
                      <img src="/images-new/size.svg" className="frontage-arrow" />
                    </div>

                    <div>
                      Frontage <span>{lot.dimensions ? lot.dimensions.width : 0}m</span>
                    </div>
                  </div>
                  <div className="size">
                    <div className="img-wrapper">
                      <img src="/images-new/size.svg" className="depth-arrow" />
                    </div>

                    <div>
                      Depth <span>{lot.dimensions ? lot.dimensions.depth : 0}m</span>
                    </div>
                  </div>
                  <div className="size">
                    <div className="img-wrapper">
                      <img src="/images-new/area-icon.png" className="areai-icon" />
                    </div>

                    <div>
                      Land Size{' '}
                      <span>
                        {lot.sqm}m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                </S.CardMainContentLeft>
                <S.CardMainContentRight>
                  <S.LotStatus status={lot.status}>
                    <S.Status status={lot.status} />
                    <S.LotText>{lot.status === 'Settled' ? 'Sold' : lot.status}</S.LotText>
                  </S.LotStatus>
                </S.CardMainContentRight>
              </S.CardMainContent>

              <S.Enquire onClick={() => handleView(lot)}>
                <div>Enquire</div>
              </S.Enquire>
            </S.Card>
          </>
        ))}

        {!hidePackages &&
        lotPackages?.length > 0 &&
        lot?.status !== 'Sold' &&
        lot?.status !== 'Settled' ? (
          <S.Packages>
            {!isSalesMode && <S.PackagesTitle>Available House & Land Packages</S.PackagesTitle>}

            {lotPackages.map((packageItem, index) => (
              <PackageCard
                key={index}
                className="package-card"
                house={packageItem}
                title={packageItem.houseType.name}
                subtitle={packageItem.houseType.builder}
                builderName={
                  packageItem.houseType.builder ? packageItem.houseType.builder.name : '-'
                }
                builderLogo={packageItem.houseType?.builder?.thumbnailUrl}
                handleView={() => handleView(lot)}
                bed={packageItem.houseType.bed}
                bath={packageItem.houseType.bath}
                car={packageItem.houseType.car}
                area={packageItem.houseType.area}
                land={lot?.sqm}
                frontage={lot?.dimensions?.width}
                price={packageItem.price}
                image={packageItem.facade?.url}
                pdf={packageItem.pdf?.url}
                mediaType="package"
                mediaId={packageItem.id}
                lotNumber={lot?.humanId}
                showLotInfo={!!showcasePackages}
              />
            ))}
          </S.Packages>
        ) : null}
      </S.CardWrapper>
    </S.Container>
  )
}
